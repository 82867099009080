@import 'styles/variables';

.button {
  align-items: center !important;

  @include mobile-tablet {
    display: flex;
    grid-column-end: span 2;
    width: 100%;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.icon {
  fill: var(--surface);
  width: 2.5rem;
  height: 2.5rem;
  transition: transform 0.3s;
  margin-left: 0.5rem;
}

.rotated {
  transform: rotate(180deg);
}
