@mixin absolute-full-size {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.root {
  position: relative;
}

.error {
  @include absolute-full-size;

  background: var(--gray-10);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :global(.MuiTypography-root) {
    max-width: 90%;
    margin-top: 0.5rem;
  }

  svg {
    width: 3rem;
    height: 3rem;
    fill: var(--gray-50);
  }
}

.media {
  position: relative;

  .image {
    @include absolute-full-size;

    width: 100%;
    height: 100%;
    object-fit: contain;

    &BG {
      filter: brightness(0.9);
      object-fit: cover;
    }
  }

  .play {
    @include absolute-full-size;

    z-index: 50;
    background-color: var(--gray-20);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 3rem;
      height: 3rem;
      fill: var(--white);
    }
  }
}

.container {
  position: relative;
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 5fr 3fr;
  aspect-ratio: 1.2;
  border-radius: var(--gallery-border-radius, 4rem);
  overflow: hidden;
  isolation: isolate;

  &[data-gallery-count='1'] {
    grid-template-areas: 'one one' 'one one';
  }

  &[data-gallery-count='2'] {
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 'one one' 'two two';
  }

  &[data-gallery-count='3'] {
    grid-template-areas: 'one one' 'two three';
  }

  &[data-gallery-count='4'] {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 3fr 1fr;
    grid-template-areas: 'one one one' 'two three four';
  }

  .media {
    &:nth-child(1) {
      grid-area: one;
    }

    &:nth-child(2) {
      grid-area: two;
    }

    &:nth-child(3) {
      grid-area: three;
    }

    &:nth-child(4) {
      grid-area: four;
    }
  }
}

.more {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gray-20);
  backdrop-filter: blur(4px);
  border-bottom-right-radius: 4rem;
  overflow: hidden;
}
