@import 'styles/fonts';

.tooltip {
  @include paragraph-12;

  position: relative;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background);
  backdrop-filter: blur(14px);
  padding: 1rem;
  border-radius: 2.5rem;
}

.arrow {
  position: absolute;
  left: 50%;
  width: 3.75rem;
  top: 100%;
  transform: translateX(-50%);
  margin: 0;
  height: 1.125rem;
  fill: var(--tooltip-background);
}

.popper {
  &[data-popper-placement='bottom'] {
    .arrow {
      top: -0.3px;
      transform: translateX(-50%) rotate(180deg);
    }
  }
}
