@import 'styles/variables';

.root {
  grid-area: info;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 4rem 0;
}

.infoBlock {
  margin-bottom: 3rem;
}

.title {
  display: block;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
}

.socialButton {
  padding: 0.75rem !important;

  &:not(:hover) svg path {
    fill: var(--button-text-foreground);

    &:global(.to-remove) {
      display: none;
    }

    &:global(.white) {
      fill: none;
    }
  }

  &:not(:last-of-type) {
    margin-right: 0.5rem;
  }
}

@include tablet {
  .root {
    display: grid;
    grid-column-gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'description description'
      'managers socials'
      'info info'
      'actions actions';
  }

  .description {
    grid-area: description;
  }

  .managers {
    grid-area: managers;
  }

  .socials {
    grid-area: socials;
  }
}

@include mobile {
  .infoBlock {
    margin-bottom: 2rem;
  }
}
