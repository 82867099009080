@import 'styles/variables';

.root {
  @include max-content-width;

  position: relative;
  margin-top: 2rem;
}

.list {
  padding: 0 3.5rem 3rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  grid-column-gap: 3rem;
  justify-content: center;
}

.slider {
  visibility: hidden;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 3.5rem 3rem;
  width: 100%;

  :global {
    .slick-slider {
      overflow: hidden;
    }

    .slick-list {
      margin: 0 -1.5rem;

      .slick-slide {
        display: flex;
        width: 100%;
        height: auto;

        > div {
          margin: 0 1.5rem;
          width: 100%;
          display: flex;
        }
      }
    }

    .slick-track {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: center;
    }
  }
}

.visible {
  position: initial !important;
  visibility: visible;
  display: flex;
}

.hidden {
  display: none;
}

@include mobile-tablet {
  .list {
    grid-column-gap: 0;
  }

  .slider {
    padding: 0 0 3rem;

    :global {
      .slick-slide {
        max-width: 56rem;

        > div {
          margin: 0;
        }
      }

      .slick-track {
        flex-direction: unset;
        flex-wrap: unset;
        align-items: unset;
        justify-content: unset;
      }
    }
  }
}

@include mobile {
  .list {
    padding: 0 1.5rem 2.5rem;
    grid-template-columns: 1fr;

    > div {
      display: none;
    }

    > div:nth-child(1) {
      display: flex;
    }
  }

  .slider {
    padding: 0 1.5rem 2.5rem;

    :global {
      .slick-slide {
        max-width: unset;
      }

      .slick-list {
        margin: 0;
      }
    }
  }
}
