@import 'styles/variables';

.root {
  grid-area: members;
  padding: 3.5rem 4rem 4rem;
}

.title {
  margin-bottom: 2.5rem;
}

.xAxis {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: calc(100% - 7rem);

  .tick {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.description {
  @include text-ellipsis;

  max-width: 100%;
}

.bar {
  fill: var(--base-5);
  transition: fill 0.3s ease-in-out;

  &:hover {
    fill: var(--brand);
  }
}
