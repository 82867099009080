@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.reels {
  display: flex;
  flex-direction: column;
  margin-bottom: 12rem;
  gap: 10rem;

  @include mobile {
    margin-bottom: 7rem;
    gap: 6rem;
  }
}

.mainCard {
  width: 100%;
}

.promoted {
  margin-top: 3.75rem;
  width: 100%;
}
