$desktop: 1024px;
$tablet: 767px;
$mobile: 360px;
$max-content-width: 1440px;
$animation-function: cubic-bezier(0.22, 1, 0.36, 1);

@mixin max-content-width {
  max-width: $max-content-width;
  width: 100%;
  align-self: center;
  margin-inline: auto;

  @include mobile-tablet {
    overflow-x: hidden;
  }
}

@mixin content-indents {
  padding-inline: 3.5rem;

  @include mobile {
    padding-inline: 2rem;
  }
}

@mixin sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

@mixin skeleton {
  border-radius: 2rem;
  background-color: var(--skeleton-animated-block-background);
  position: relative;
  overflow: hidden;
  will-change: background;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgb(0 0 0 / 0%),
      var(--skeleton-animated-block-foreground),
      rgb(0 0 0 / 0%)
    );
    animation: progress 2s ease-in-out infinite;
    will-change: transform;
  }
}

@mixin horizontal-gradient {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%), var(--paper-background));
  }
}

@mixin vertical-gradient {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(100% - 4rem);
    width: 4rem;
    height: 100%;
    background: linear-gradient(90deg, rgb(0 0 0 / 0%), var(--paper-background));
  }
}

@mixin tooltip-styles {
  border: 1px solid var(--growth-tooltip-border);
  background: var(--growth-tooltip-background);
  padding: 2rem 3rem 3rem;
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
  backdrop-filter: blur(2.5rem);
}

@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin absolute-full-size {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* stylelint-disable */
@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop - 1px}) {
    @content;
  }
}
/* stylelint-enabled */

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}
