@import 'styles/variables';

.carouselContainer {
  @include max-content-width;

  padding: 0 3.5rem;
  position: relative;
  width: 100%;
  height: fit-content;
  margin: auto;
  overflow: hidden;
  background-color: transparent;
}

.carouselItem {
  width: 100%;
  height: 100%;
  position: relative;
}

.controlsOverlay {
  position: absolute;
  bottom: 3rem;
  left: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  @include mobile-tablet {
    display: none;
  }
}

.carouselDots {
  position: absolute;
  bottom: 3rem;
  right: 3rem;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  opacity: 1;
  background-color: var(--surface);
  padding: 1rem;
  border-radius: 2rem;
  z-index: 10;

  &::before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 2rem;
    content: '';
    background-color: var(--base-30);
  }

  @include mobile-tablet {
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    max-height: 2rem;
    gap: 0.5rem;
    justify-content: center;
    width: fit-content;
  }
}

.dot {
  border: none;
  z-index: 15;
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  aspect-ratio: 1;
  background-color: var(--base-10);
  border-radius: 50%;
  cursor: pointer;

  @include mobile-tablet {
    width: 1rem;
    height: 1rem;
  }
}

.activeDot {
  background-color: var(--base-50);
}
