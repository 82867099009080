@import 'styles/variables';

.root {
  background: var(--base-5);
  border-radius: 4rem;
  display: grid;
  padding: 2rem 1.5rem 2rem 1rem;
  grid-template-columns: 9rem 31rem repeat(4, 1fr);
  grid-template-areas: 'rating channel metric1 metric2 metric3 metric4';
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.rating {
  @include skeleton;

  grid-area: rating;
  width: 7rem;
  height: 8rem;
}

.channel {
  grid-area: channel;
  display: grid;
  grid-template-columns: 8rem 1fr;
  grid-template-rows: repeat(2, 3rem);
  grid-template-areas:
    'avatar title'
    'avatar category';
  align-items: center;

  .avatar {
    @include skeleton;

    grid-area: avatar;
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    margin-right: 2rem;
  }

  .title {
    @include skeleton;

    grid-area: title;
    height: 2rem;
    width: 80%;
  }

  .category {
    @include skeleton;

    grid-area: category;
    height: 2rem;
    width: 15rem;
  }
}

.metric {
  @include skeleton;

  width: 17rem;
  height: 8rem;

  &:nth-child(1) {
    grid-area: metric1;
  }

  &:nth-child(2) {
    grid-area: metric2;
  }

  &:nth-child(3) {
    grid-area: metric3;
  }

  &:nth-child(4) {
    grid-area: metric4;
  }
}

@include mobile-tablet {
  .root {
    grid-template-rows: 12rem 12rem;
    grid-template-columns: repeat(5, calc(100% / 5));
    grid-template-areas:
      'channel channel channel channel channel'
      'rating metric1 metric2 metric3 metric4';
  }

  .metric {
    width: 75%;
  }

  .channel {
    position: relative;
    padding-bottom: 3rem;

    .title {
      width: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0;
      border-top: 1px solid var(--base-10);
      border-bottom: 1px solid white;
    }
  }

  .dividerHorizontal {
    display: block;
  }

  .views {
    margin-left: 4rem;
  }
}
