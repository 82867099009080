@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: minmax(100%, 64rem);
  grid-template-rows: repeat(3, max-content);
  grid-row-gap: 1rem;
}

@include mobile {
  .root {
    grid-template-columns: 1fr;
    flex: 1;
  }
}
