.root {
  border-radius: 3rem;
  background: var(--growth-tooltip-background);
  border: 1px solid var(--growth-tooltip-border);
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
  backdrop-filter: blur(20px);
}

.date {
  padding: 2rem 3rem;
  border-bottom: 1px solid var(--paper-inner-border);
}

.metrics {
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  grid-template-rows: repeat(6, auto);
  grid-row-gap: 1.5rem;
  grid-column-gap: 1rem;
  align-items: center;
  padding: 3rem;
}

.title {
  margin-right: 1.5rem;
  text-wrap: nowrap;
}

.value {
  display: flex;
  justify-content: flex-end;
}
