.root {
  display: flex;
  flex-direction: column;
}

.row {
  display: grid;
  grid-template-columns: auto minmax(auto, 100%);
  grid-column-gap: 1rem;
  align-items: center;
}

.wave {
  width: 100%;
  flex: 1;
}

.timings {
  margin-inline: auto;
  margin-top: 3rem;
  width: max-content;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  background-color: var(--button-secondary-background);
}
