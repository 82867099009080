@import 'styles/variables';

.root {
  @include max-content-width;

  padding: 3rem 3.5rem 0;
}

.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 6rem;
  border-radius: 5rem;
  background:
    url('/assets/gradient-login.png') 100% / cover no-repeat,
    linear-gradient(0deg, #0273ea 0%, #0273ea 100%);
  position: relative;
}

:global(.MuiButtonBase-root) {
  &.button {
    background-color: var(--white);
    color: var(--new);

    &:hover {
      background-color: var(--white);
    }
  }
}

@include mobile {
  .root {
    padding: 3rem 1.5rem 0;
  }

  .box {
    flex-direction: column;
    padding: 3.5rem 3rem 3rem;
    row-gap: 3.5rem;
    align-items: flex-start;
  }
}
