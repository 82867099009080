@import 'styles/variables';

.grid {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  width: 112rem;
  grid-template-areas:
    'message qr'
    'button qr';
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  grid-gap: 1rem;
  margin-inline: auto;
}

.message {
  grid-area: message;
  display: flex;
  flex-direction: column;
  padding: 6rem;
  row-gap: 2rem;
}

.button {
  grid-area: button;
  padding: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .telegram {
    padding-left: 5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .divider {
      height: 13rem;
      width: 0;
      border-left: 1px solid var(--base-10);
      border-right: 1px solid var(--white-10);
    }

    .splitter {
      display: flex;
      align-items: center;

      svg {
        width: 3rem;
        height: 3rem;
        margin-inline: 3.5rem;
      }
    }
  }
}

.qr {
  grid-area: qr;
  padding: 1rem;
  max-width: min-content;
}

.code {
  background: var(--base-5);
  padding: 4rem;
  width: max-content;
  height: max-content;
  border-radius: 4rem;

  svg {
    width: 32rem;
    height: 32rem;
  }
}

.anotation {
  margin-top: 2rem;
  padding-inline: 3rem;
}

.close {
  position: absolute;
  left: calc(100% + 1rem);
  top: 0;
}

@include mobile-tablet {
  .grid {
    margin: 20.25rem 16rem;
    width: auto;
    top: 0;
    left: 0;
    transform: none;
  }

  .qr {
    display: none;
  }
}

@include mobile {
  .grid {
    margin: 9rem 0;
    width: 100%;
    grid-column-gap: 0;
  }

  .close {
    left: auto;
    right: 2rem;
    top: auto;
    bottom: calc(100% + 1rem);
  }

  .divider {
    height: 12.5rem;
  }
}
