@import 'styles/variables';

.overview {
  @include max-content-width;

  margin: 2rem auto 3rem;
  display: grid;
  grid-template-columns: 41rem 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'countries generalInfo' 'categories generalInfo';
  grid-gap: 3rem;
  padding-inline: 3.5rem;
  max-height: 85rem;
}

.countries {
  grid-area: countries;
}

.categories {
  grid-area: categories;
}

.generalInfo {
  grid-area: generalInfo;
}

.promotions {
  @include max-content-width;

  margin: 3rem auto;
}

.topChannels {
  margin-block: 13rem;
}

.randomCategory {
  margin-top: 12rem;
}

@include desktop {
  .ellipsis {
    @include text-ellipsis;
  }
}

@include tablet {
  .overview {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr auto;
    grid-template-areas: 'generalInfo generalInfo' 'categories countries';
    grid-gap: 3rem;
  }
}

@include mobile {
  .topChannels {
    margin-block: 8rem;
  }

  .overview {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 'countries' 'generalInfo' 'categories';
    grid-gap: 1rem;
  }
}
