@import 'styles/variables';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 3rem 3rem 4rem;
}

.title {
  @include skeleton;

  width: 20rem;
  height: 3rem;
}

.action {
  @include skeleton;

  width: 14.375rem;
  height: 4rem;
}

.posts {
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;

  &:not(.full-scrolled) {
    @include horizontal-gradient;
  }
}

.scrollabel {
  height: 87rem;
  overflow-y: scroll;
  border-radius: 4rem;
}

.post {
  margin-bottom: 1rem;
}

@include mobile-tablet {
  .root {
    overflow: auto;
  }

  .scrollabel {
    display: flex;
    flex-direction: row;
    height: auto;
    overflow: hidden;
    column-gap: 1rem;
  }
}

@include mobile-tablet {
  .posts {
    &:not(.full-scrolled) {
      @include vertical-gradient;
    }
  }

  .post {
    flex-basis: 50rem;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 0;
  }
}

@include mobile {
  .post {
    flex-basis: 36rem;
  }
}
