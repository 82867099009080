.root {
  > svg {
    overflow: visible;
  }
}

.emoji {
  filter: blur(1.5rem);
}

.group {
  position: relative;
}

.round {
  filter: blur(16px);
  transform: scale(24.5);
  opacity: 0.5;
}
