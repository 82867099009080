@import 'styles/variables';

.root {
  min-width: 35rem;
  border-radius: 4rem;
  padding: 2rem 3rem 3rem;
  background-color: var(--gray-80);
  border: 1px solid var(--base-10);
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
  backdrop-filter: blur(20px);
}

.divider {
  margin: 3rem -3rem;
  width: calc(100% + 6rem);
  border-top: 1px solid var(--base-10);

  &:first-of-type {
    margin-top: 2rem;
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    display: flex;
  }
}

.change {
  margin-left: 1rem;
}

.info {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-top: 3rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  fill: var(--font-secondary-color);
}

@include mobile-tablet {
  .info {
    display: none;
  }
}
