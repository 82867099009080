.root {
  padding: 3rem;
  height: 56rem;
}

.chart {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &SVG {
    width: 100%;
    height: auto;
    aspect-ratio: 2.33;
    margin-top: 14rem;
  }
}
