@import 'styles/variables';

.root {
  /* stylelint-disable */
  :global(.Collapsible__contentOuter) {
    transition-timing-function: $animation-function !important;
  }
  /* stylelint-enable */
}

.list {
  padding-block: 2rem;

  > * {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

.daily {
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.loadMore {
  width: 100%;
  margin-top: 3rem;
}

.placeholder {
  margin-top: 1rem;
  margin-bottom: 0;
}

.scrollable {
  overflow-y: auto;
}
