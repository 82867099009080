@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3rem;
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50rem;
}

.row {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'graph graph' 'title value';

  &:not(:last-of-type) {
    margin-bottom: 4rem;
  }

  .graph {
    grid-area: graph;
    height: 2rem;
    border-radius: 1.5rem;
    background: var(--base-5);
    margin-bottom: 2rem;

    .graphValue {
      display: block;
      height: 100%;
      border-radius: 1.5rem;
      background: var(--brand);
      min-width: 2rem;
    }
  }

  .key {
    grid-area: title;
  }

  .value {
    grid-area: value;
  }
}

@include mobile {
  .empty {
    min-height: 30rem;
  }
}
