@import 'styles/variables';

.post {
  cursor: pointer;
  min-height: 0;
  min-width: 0;
  border-radius: 4rem;
  width: calc(100% - 2rem);
  margin: 1rem auto 0;
  background: var(--secondary-background);

  &:hover {
    position: relative;
    background: var(--secondary-background-hover);
    box-shadow:
      0 0 0 0 rgb(35 41 59 / 8%),
      0 4px 9px 0 rgb(35 41 59 / 8%),
      0 17px 17px 0 rgb(35 41 59 / 7%),
      0 39px 23px 0 rgb(35 41 59 / 4%),
      0 69px 28px 0 rgb(35 41 59 / 1%),
      0 108px 30px 0 rgb(35 41 59 / 0%);
  }
}

.content {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.general {
  display: grid;
  grid-template-areas: 'icon title growth' 'icon top growth';
  grid-template-rows: repeat(2, auto);
  grid-template-columns: auto 1fr auto;
  grid-row-gap: 0.75rem;
  width: 100%;

  .postIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: icon;
    padding: 1rem;
    border-radius: 100%;
    background: var(--button-secondary-background);
    margin-right: 1.5rem;
    flex-shrink: 0;

    svg {
      width: 2rem;
      height: 2rem;
      fill: var(--brand);
    }
  }

  .title {
    grid-area: title;
  }

  .top {
    grid-area: top;
  }

  .growth {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: growth;

    :global(.MuiTypograpy-root) {
      line-height: 1;
    }
  }

  .icon {
    display: flex;
    align-self: flex-start;
    margin: 0.5rem 1rem 0 0;
    width: 2rem;
    height: 2rem;
    fill: var(--font-primary-color);

    &.positive {
      fill: var(--font-positive-color);
    }

    &.negative {
      fill: var(--font-negative-color);
    }
  }
}

.avatar {
  margin: 2rem auto;
}

.channelInfo {
  display: flex;
  flex-direction: column;
  justify-self: center;
  text-align: center;
  align-items: center;

  & > *:not(:first-child) {
    margin-top: 0.625rem;
  }
}

.stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 0.25rem;

  .members {
    display: flex;
    flex-direction: row;
    column-gap: 0.725rem;
    padding: 0.625rem 1rem;
    align-items: center;
    line-height: 1;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--font-secondary-color);
  }
}

.metrics {
  padding: 2rem 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

@include mobile-tablet {
  .post {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 1rem;
  }

  .content {
    flex: 1;
  }
}
