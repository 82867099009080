@import 'styles/variables';

.root {
  padding: 3.5rem 4rem;
}

.title {
  @include skeleton;

  width: 18rem;
  height: 3rem;
}

.total {
  @include skeleton;

  width: 21rem;
  height: 5rem;
  margin-block: 1rem 4.5rem;
}

.chart {
  aspect-ratio: 1.8;

  svg {
    width: 100%;
    height: 100%;
  }
}

.emojiList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 3.25rem;
}

.emoji {
  @include skeleton;

  width: 8.5rem;
  height: 11rem;
}
