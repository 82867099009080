.wrapper {
  width: max-content;
}

.counter {
  padding: 0.375rem 1rem;
  border-radius: 3rem;
  background: var(--base-50);
  margin-left: 0.75rem;
  line-height: 1;
}

.root {
  grid-area: open;

  &.opened {
    .counter {
      display: none;
    }
  }

  &.opened,
  &.opened:hover {
    background: var(--base-100);
    color: var(--surface);

    svg {
      transform: rotateY(180deg);
      fill: var(--surface);
    }
  }
}

.hotkey {
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  background: rgb(255 255 255 / 10%);
  color: rgb(255 255 255 / 60%);
  margin-left: 1rem;
}

.tooltip {
  border-radius: 1rem;
}
