@import 'styles/variables';

.root {
  grid-area: info;
}

.divider {
  width: calc(100% + 8rem);
  height: 1px;
  background-color: var(--gray-10);
  margin: 0 -4rem;
}

.expand {
  margin: 1rem 0;
  width: 100%;
}

.gridInfo {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(7, 3rem);
  align-items: center;
  grid-row-gap: 1rem;
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 3rem;
  align-items: center;

  .rowValue {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }
}

.infoBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  overflow: hidden;
  max-height: 500px;
  transition: all 0.3s;

  &.collapsed {
    max-height: 0;
    margin-bottom: 0;
  }
}

.value {
  display: flex;
  align-items: center;
  text-wrap: nowrap;

  .badge {
    margin-left: 0.5rem;
    fill: var(--new);

    &.verified {
      margin-right: -0.25rem;
    }
  }
}

.actions {
  *:not(:last-child) {
    margin: 0 0.5rem 0.5rem 0;
  }
}

.arrow {
  &.expanded {
    transform: rotate(180deg);
  }
}

.newLineDivider {
  width: 100%;
}

@include mobile-tablet {
  .newLineDivider {
    display: none;
  }
}
