@import 'styles/variables';

.root {
  max-height: 30.75rem;
  height: 100%;
  width: 100%;
  padding: 3rem;
  border-radius: 5rem;
  background: var(--surface);
  position: relative;
  display: grid;
  place-content: stretch;
  grid-template-columns: 1fr 5fr;
  gap: 5rem;

  @include mobile {
    background-color: transparent;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
    padding: 2rem;
    height: auto;
    max-height: unset;
  }
}

.button {
  @include mobile {
    display: none;
  }
}

.imageBox {
  flex-shrink: 1;
  border-radius: 100%;
  background-color: var(--base-5);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 25rem;
  max-height: 25rem;
  width: 100%;
  height: 100%;
}

.image {
  aspect-ratio: 1;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.content {
  max-height: 100%;
  height: 100%;
  position: relative;
  flex-grow: 2;
  display: grid;
  place-content: stretch;
}

.heading {
  flex: 3;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include mobile {
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    height: 100%;
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  height: 100%;

  @include mobile {
    gap: 0.5rem;
  }
}

.bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 1px solid var(--base-10);
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: space-between;
  padding: 2rem 0;

  @include mobile {
    position: unset;
    padding: 0;
  }
}

.download {
  display: none;

  @include mobile {
    display: flex;
    grid-column-end: span 2 !important;
    width: 100%;
  }
}

.link {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
  width: 2.5rem !important;
  height: 2.5rem !important;
}
