@import 'styles/variables';

.scrollContainer {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 1rem;
  background-color: var(--surface);
  border-radius: 10rem;
  width: 100%;

  @include mobile-tablet {
    background-color: transparent;
  }
}

.scrollWrapper {
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  gap: 1rem;
  overflow-x: auto;
  background-color: transparent;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.scrollItem {
  flex: 0 0 auto;
  cursor: pointer;
  align-items: center;
  padding: 0.75rem 1rem !important;

  &:hover {
    transform: none !important;
    box-shadow: none !important;
  }
}

.arrowButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  background-color: var(--surface);
  padding: 1rem;
  border-radius: 50%;
  z-index: 10;

  @include mobile-tablet {
    display: none;
  }
}

.left {
  left: 0;
}

.right {
  right: 0;
}
