@import 'styles/fonts';

.input {
  border-radius: 2rem;
  background: var(--base-5);
  padding: 1.25rem 3rem;

  &.error {
    box-shadow: 0 0 0 1px var(--error);
  }

  :global(.MuiInput-input) {
    padding: 0 !important;

    @include paragraph-10bk;

    color: var(--font-primary-color);
  }

  :global(.MuiInput-root) {
    &::before,
    &::after {
      display: none;
    }
  }
}

.errorAdornment {
  position: absolute;
  top: calc(50% - 1.5rem);
  right: 4.5rem;
}

.errorIcon {
  background: rgb(226 84 63 / 10%);

  svg {
    fill: var(--error);
  }

  &:hover {
    background: rgb(226 84 63 / 10%);
    box-shadow: 0 0 0 0.5rem var(rgb(226 84 63 / 10%));
  }
}

.acinput {
  margin-top: 3rem !important;
}

.tag {
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  border-radius: 3rem;
  background: var(--base-50);
  height: auto;
  margin: 0 0.25rem 0.25rem 0 !important;

  &Label {
    padding: 0;
    padding-right: 0.25rem;
  }

  .deleteIcon {
    fill: var(--white);
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
  }
}

.dropdown {
  top: calc(50% - 2.5rem);
}

.arrowDown {
  width: 2.5rem;
  height: 2.5rem;
  fill: var(--base-50);
}

.label {
  @include paragraph-12;

  transform: scale(1) translate(3rem, 2.75rem);
  color: var(--base-50);

  &Filled {
    transform: scale(1) translate(3rem, 1.25rem);
  }

  &Focused {
    transform: scale(1) translate(3rem, 1.25rem);
    color: var(--base-50) !important;
  }

  &Error {
    color: var(--error);
  }

  &[data-shrink='true'] {
    transform: translate(3rem, 1.25rem);
  }
}

.popper {
  box-shadow: none;
  backdrop-filter: blur(20px);
  background: var(--growth-tooltip-background);
  border: 1px solid var(--growth-border-color) !important;
  border-radius: 2rem;
  min-width: 28rem;
  padding: 1rem;
}

.paper {
  background: none;
  box-shadow: none;
}

.option {
  @include headline-10bk;

  padding: 0.5rem 2rem 0.5rem 1rem;
  border-radius: 1rem;
  color: var(--font-secondary-color);
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: var(--base-5);
  }
}
