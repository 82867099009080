@import 'styles/variables';

.root {
  z-index: 1;
  border-radius: 5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  isolation: isolate;
}

.background {
  object-fit: cover;
  z-index: -1;
}

.title {
  margin: 12rem auto 3rem;
  width: 85rem;
  display: block;
}

.description {
  margin-inline: auto;
  margin-bottom: 4rem;
  width: 63rem;
  color: var(--white-60) !important;
}

.highlight {
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    height: 0.375rem;
    width: calc(100% + 2rem);
    left: -1rem;
    top: -1rem;
    border-radius: 2px;
    background: white;
    box-shadow:
      0 0 1px 0 #fff,
      0 0 1px 0 #fff,
      0 0 2px 0 #fff,
      0 0 3px 0 #fff,
      0 0 5px 0 #fff,
      0 0 8px 0 #fff,
      0 0 13px 0 #fff,
      0 0 21px 0 #fff;
  }

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 2rem);
    top: 0;
    left: -1rem;
    height: calc(100%);
    border-radius: 1rem;
    opacity: 0.6;
    background: linear-gradient(
      180deg,
      rgb(255 255 255 / 0%) 0%,
      rgb(0 95 254 / 0%) 0.75%,
      rgb(255 255 255 / 0%) 2.92%,
      rgb(255 255 255 / 1%) 6.37%,
      rgb(255 255 255 / 2%) 10.97%,
      rgb(255 255 255 / 4%) 16.59%,
      rgb(255 255 255 / 6%) 23.1%,
      rgb(255 255 255 / 10%) 30.37%,
      rgb(255 255 255 / 15%) 38.27%,
      rgb(255 255 255 / 22%) 46.66%,
      rgb(255 255 255 / 30%) 55.41%,
      rgb(255 255 255 / 39%) 64.39%,
      rgb(255 255 255 / 51%) 73.47%,
      rgb(255 255 255 / 65%) 82.52%,
      rgb(255 255 255 / 81%) 91.41%,
      #fff 100%
    );
    transform: rotateX(180deg);
    z-index: -1;
  }
}

.imageWrapper {
  padding: 1rem;
  margin-top: 8rem;
  border-radius: 5rem;
  border: 1px solid rgb(255 255 255 / 40%);
  background: rgb(16 41 96 / 10%);
  backdrop-filter: blur(20px);
  aspect-ratio: 2.147;
  width: clamp(67rem, 80%, 90rem);

  img {
    border-radius: 4rem;
    width: 100%;
    height: auto;
  }
}

@include tablet {
  .title {
    width: 100%;
    max-width: 66.25rem;
  }
}

@include mobile {
  .root {
    padding: 8rem 3rem 30.5rem;
  }

  .title {
    width: 100%;
    max-width: unset;
    margin-block: 0 2rem;
  }

  .description {
    width: 100%;
    margin-block: 0 3rem;
  }

  .imageWrapper {
    margin-top: 0;
    position: absolute;
    top: 40.5rem;
    left: 6rem;
    width: 70rem;
  }
}
