@import 'styles/variables';

.root {
  grid-area: chat;
  padding: 3rem 1rem 1rem;
  margin-top: 3rem;
  width: 100%;
}

.avatar {
  align-self: center;
  flex-shrink: 0;
}

.title {
  margin: 0 0 3rem 3rem;
}

.button {
  display: flex;
  flex-direction: row;
  border-radius: 4rem;
  background-color: var(--button-secondary-background);
  padding: 3rem;
  margin-top: 3rem;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 2rem;
}

.details {
  display: flex;
  flex-direction: row;
}

.members {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  margin-right: 1.25rem;

  svg {
    fill: var(--font-secondary-color);
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
  }
}

.placeholder {
  @include skeleton;

  width: 5rem;
  height: 1.166rem;
}

@include mobile-tablet {
  .root {
    grid-area: unset;
    position: absolute;
    width: auto;
    top: 2rem;
    left: 0;
    background: none !important;
    padding: 0;
    margin: 0;
    overflow: visible !important;
  }

  .link {
    background: var(--paper-background);
    border-radius: 5rem;
    padding: 1rem;
  }

  .button {
    margin: 0;
    padding: 1rem;
    max-width: 23.5rem;
  }

  .name {
    width: 13rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tags {
    display: none;
  }

  .title {
    margin: 0 0 1.5rem;
    display: block;
  }
}

@include mobile {
  .info {
    padding-left: 0;
  }

  .details {
    position: absolute;
    left: 40%;
    top: 2.5rem;
    border-radius: 24px;
    border: 0.375rem solid var(--primary-background);
    background: linear-gradient(0deg, rgb(16 41 96 / 50%) 0%, rgb(16 41 96 / 50%) 100%), #f3f4f9;
    padding: 0.375rem 0.75rem;

    :global(.MuiTypography-root) {
      color: var(--white);
    }

    svg {
      fill: var(--white);
    }
  }

  .link {
    width: 8rem;
  }

  .button {
    padding: 0;
  }

  .name {
    display: none;
  }
}
