@import 'styles/variables';

.root {
  z-index: 1;
  position: relative;
  margin-top: 3rem;
  border-radius: 5rem;
  padding-block: 12rem 53.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  isolation: isolate;
}

.background {
  object-fit: cover;
  z-index: -1;
  border-radius: 5rem;
}

.title {
  max-width: 85rem;
  text-align: center;
}

.botInfo {
  color: var(--white-60) !important;
  margin-block: 2rem 4rem;
}

.connect {
  color: #5c64df !important;
}

.charts {
  width: 126rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 8rem);
}

@include tablet {
  .root {
    padding-bottom: 43.25rem;
  }

  .charts {
    width: 67.875rem;
  }
}

@include mobile {
  .root {
    padding-block: 7rem 30.625rem;
    padding-inline: 2rem;
  }

  .title {
    width: 100%;
  }

  .charts {
    width: 30rem;
  }
}
