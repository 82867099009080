@import 'styles/variables';

.root {
  width: 50rem;
  display: flex;
  flex-direction: column;
  border-radius: 3rem;
  border: 1px solid var(--growth-tooltip-border);
  background: var(--growth-tooltip-background);
  padding: 2rem 3rem 3rem;
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
  backdrop-filter: blur(2.5rem);
}

.row {
  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.label {
  color: var(--data-color) !important;
}

.divider {
  margin-block: 2rem;
  border-top: 1px solid var(--dropdown-border);
  width: calc(100% + 6rem);
  margin-left: -3rem;
}

@include mobile {
  .root {
    width: calc(100% - 3rem);
  }
}
