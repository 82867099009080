@import 'styles/variables';

// stylelint-disable

.root {
  @include max-content-width;

  margin-inline: auto;
  height: 43rem;
  position: relative;
  display: flex;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  overflow: hidden;
  isolation: isolate;

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgb(33 42 59 / 60%) 0%, rgb(33 42 59 / 0%) 100%);

    &Image {
      object-fit: cover;
      object-position: center;
    }
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 3rem 3.5rem;
  z-index: 2;

  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;

    .item {
      padding: 0.625rem 1.5rem;
      border-radius: 4rem;
      background: var(--white-20);
      backdrop-filter: blur(20px);
      display: flex;
      border: none;
      color: white;

      &.rounded {
        padding: 0.75rem !important;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: white;
      }

      :global(.MuiButton-startIcon) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
  }
}

.wrapper {
  position: absolute;
  max-width: 64rem;
  width: 100%;
  top: 13rem;
  left: 50%;
  transform: translateX(-50%);
}

.paper {
  padding: 6rem;
}

.close {
  position: absolute;
  left: calc(100% + 1rem);
  top: 0;
}

.title {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
  margin-bottom: 3rem;
}

.icon {
  border-radius: 1rem;
  padding: 1rem;
  background: var(--brand);
  display: inline-flex;

  svg {
    width: 2rem;
    height: 2rem;
    fill: var(--white);
  }
}

@include mobile {
  .info {
    display: inline-flex;
    flex-wrap: wrap;
    row-gap: 0.25rem;

    .left {
      flex-wrap: wrap;
      row-gap: 0.25rem;
    }
  }
}
