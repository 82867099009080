@mixin base-icon-props {
  width: 0;
  height: 0;
  margin-right: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
}

.root {
  display: flex;
  flex-direction: row;
}

.icon {
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;

  &Positive {
    @include base-icon-props;

    border-bottom: 0.5rem solid var(--additional-success);
  }

  &Negative {
    @include base-icon-props;

    border-top: 0.5rem solid var(--additional-error);
  }
}
