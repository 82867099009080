.root {
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 1rem;
  align-items: center;
  max-width: 100%;
  padding: 1.5rem 3rem;
}

.text {
  white-space: nowrap;
}

.button {
  width: fit-content;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
