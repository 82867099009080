@import 'styles/variables';

.root {
  margin-top: 2rem;
  padding: 0 3rem;
  display: none;
  flex-direction: column;
}

@include mobile {
  .root {
    display: flex;
  }
}
