@import 'styles/variables';

.post {
  position: relative;
  display: block;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  background: var(--secondary-background);
  border-radius: 4rem;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  isolation: isolate;

  &:focus-visible,
  &:hover {
    position: relative;
    background: var(--secondary-background-hover);
    box-shadow:
      0 0 0 0 rgb(35 41 59 / 8%),
      0 4px 9px 0 rgb(35 41 59 / 8%),
      0 17px 17px 0 rgb(35 41 59 / 7%),
      0 39px 23px 0 rgb(35 41 59 / 4%),
      0 69px 28px 0 rgb(35 41 59 / 1%),
      0 108px 30px 0 rgb(35 41 59 / 0%);
  }

  &.restricted {
    height: 36rem;
  }
}

.text {
  text-align: start;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  padding: 3.5rem 4rem 3rem;

  &.hasGallery {
    grid-template-columns: 5fr 2fr;
    grid-column-gap: 4rem;
  }
}

.gallery {
  margin-top: 0.5rem;

  &Content {
    border-radius: 2rem;

    &More {
      border-bottom-right-radius: 2rem;
    }
  }
}

.metrics {
  padding: 2rem 3rem;
  display: flex;
  justify-content: flex-start;
}

.dateTime {
  margin-bottom: 1.5rem;
}

.leftSideContent {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}

.dateTimeInGallery {
  z-index: 100;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  padding: 0;

  :global(.MuiTypography-root) {
    border-radius: 120px;
    padding: 0.5rem 1rem;
    backdrop-filter: blur(20px);
    background-color: var(--gray-20);
    color: var(--white);

    span {
      background: rgb(255 255 255 / 20%);
    }
  }

  :global(.MuiButton-root) {
    margin-left: 0.25rem;
    backdrop-filter: blur(20px);
    background-color: var(--gray-20);
    color: var(--white);

    svg {
      fill: var(--white);
    }
  }
}

.singleGallery {
  aspect-ratio: 2.5;

  &[data-gallery-count='2'] {
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 'one two' 'one two';
  }
}

.sticker:not(:empty) {
  width: 22rem;
  height: 22rem;
  margin-inline: auto;
}

.restrict {
  z-index: 1;
}

.circle:not(:empty) {
  width: 30rem;
  height: 30rem;
  margin-inline: auto;
}

@include mobile-tablet {
  .post {
    display: flex;
    flex-direction: column;
  }

  .gallery {
    margin-top: 2rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

@include tablet {
  .galleryContent {
    aspect-ratio: 1.95;
  }
}

@include mobile {
  .galleryContent {
    aspect-ratio: 2.5;

    &[data-gallery-count='3'] {
      grid-template-rows: repeat(3, 1fr);
      grid-template-areas:
        'one one two'
        'one one three';
    }

    &[data-gallery-count='4'] {
      grid-template-rows: repeat(3, 1fr);
      grid-template-areas:
        'one one two'
        'one one three'
        'one one four';
    }
  }

  .metrics {
    display: flex;
    justify-content: space-around;
  }

  .grow {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
