.root {
  margin-top: 1.5rem;
  padding: 3rem;
  border-radius: 3rem;
  border: 1px solid var(--menu-dropdown-border);
  background: var(--menu-dropdown-background);
  max-width: 36rem;
  backdrop-filter: blur(20px);
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
}

.link {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  column-gap: 0.5rem;
  align-items: center;
}

.form {
  display: flex;
  flex: 1;
}

.input {
  &:disabled {
    color: var(--font-primary-color);
    -webkit-text-fill-color: var(--font-primary-color);
  }
}
