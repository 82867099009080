.root {
  margin-inline: -4rem;
}

.row {
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr repeat(2, auto);
  grid-row-gap: 3rem;
  align-items: center;
  padding: 1rem 4rem;
}

.space {
  border-bottom: 1px solid var(--paper-inner-border);
  height: 1px;
  margin-inline: 2rem;
}

.views {
  align-self: end;
}

.change {
  margin-left: 1rem;
  align-self: end;
}

.publish {
  grid-template-columns: repeat(3, auto) 1fr;
  grid-template-areas: 'date dot after line';

  .space {
    margin-right: 0;
  }
}

.dot {
  margin-inline: 1rem;
  align-self: center;
  justify-self: center;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: var(--date-time-divider-color);
}

.dayOverview {
  padding: 2rem 4rem;
  margin-block: 3rem;
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr repeat(2, auto);
  background: var(--button-secondary-background);

  .change,
  .dot {
    grid-area: auto;
  }
}
