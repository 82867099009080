@import 'styles/variables';

.header {
  display: grid;
  grid-template-columns: minmax(auto, 160px) 1fr auto auto;

  //flex-direction: row;
  //justify-content: space-between;
  align-items: center;
  padding: 3rem 3rem 3rem 4rem;
}

.title {
  @include skeleton;

  height: 3rem;
  margin-right: 4rem;
}

.download {
  @include skeleton;

  margin-right: 0.5rem;
  width: 4rem;
  height: 4rem;
  aspect-ratio: 1;
}

.action {
  @include skeleton;

  width: 14.375rem;
  height: 4rem;
}
