@import 'styles/fonts';
@import 'styles/variables';

/* stylelint-disable */
.root {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.primary {
  justify-content: flex-start;

  .link {
    border-radius: 16px;
    padding: 1rem 2rem;

    :global(.MuiTypography-root) {
      color: var(--base-20) !important;
    }

    &:hover {
      background-color: var(--base-5);

      :global(.MuiTypography-root) {
        color: var(--base-50) !important;
      }
    }
  }
  .active {
    background-color: var(--base-5);

    :global(.MuiTypography-root) {
      color: var(--base-50) !important;
    }

    &:hover {
      background-color: var(--base-5);

      :global(.MuiTypography-root) {
        color: var(--base-50) !important;
      }
    }
  }
}

.secondary {
  width: max-content;
  border-radius: 3rem;
  padding: 0.25rem;
  background: var(--gray-5);

  .link {
    margin: 0;
    padding: 0.375rem 1.5rem;
    z-index: 5;
  }

  .indicator {
    position: absolute;
    left: 0;
    top: 0.25rem;
    height: calc(100% - 0.5rem);
    background: var(--paper-background);
    border-radius: 2.5rem;
    z-index: 4;
    transition: left 0.2s ease-in-out;
  }

  &.overflow {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .link {
      :global(.MuiTypography-root) {
        white-space: nowrap;
      }
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--font-secondary-color);
      margin-left: 0.25rem;
    }
  }
}

.tertiary {
  .link {
    transition: color 0.2s ease-in-out;
    padding-bottom: 2rem;

    &:not(:last-of-type) {
      margin-right: 3rem;
    }

    &.active {
      :global(.MuiTypography-root) {
        color: var(--menu-active-item-color);
      }
    }
  }

  .indicator {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    background: var(--menu-active-item-color);
    transition: left 0.2s ease-in-out;

    &.loaded {
      opacity: 1;
    }
  }
}

.quaternary {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2rem;
  opacity: 1;
  overflow-x: auto;
  overflow-y: hidden;

  .link {
    position: relative;
    padding: 4rem 1rem;
    display: block;
    width: auto;
    height: 100%;
    color: var(--font-secondary-color);

    &:hover {
      background: none;
    }

    &.active span {
      color: var(--menu-active-item-color);

      &::after {
        content: '';
        background: var(--menu-active-item-color);
        position: absolute;
        bottom: -1px;
        width: 3rem;
        height: 0.75rem;
        border-radius: 0.75rem 0.75rem 0 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.link {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

@include mobile {
  .primary {
    .link {
      padding: 1rem;
    }
  }

  .secondary {
    &.overflow {
      max-width: unset;
    }

    .more {
      white-space: nowrap;
      display: flex;
    }
  }
}
