@import 'styles/fonts';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 2rem 6rem 6rem;
}

.checkbox {
  display: inline-flex;
  column-gap: 1rem;
  border-radius: 4rem;
  background-color: var(--base-5);
  margin: 0;
  padding: 0.5rem 2rem 0.5rem 1rem;

  &:global(.Mui-disabled) {
    cursor: not-allowed;
  }
}

.label {
  @include headline-10bk;

  color: var(--base-50);
}
