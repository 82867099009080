@import 'styles/variables';

.content {
  @include max-content-width;
  @include content-indents;

  display: grid;
  grid-template-columns: minmax(auto, 41rem) 1fr;
  grid-column-gap: 3rem;

  @include mobile-tablet {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: unset;
  }

  .slickFix {
    min-width: 0;
    min-height: 0;
  }

  .channelContent {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 3rem;

    @include mobile-tablet {
      grid-template-columns: 1fr;
      grid-row-gap: 3rem;
    }
  }

  .metricsOverview {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 3rem;

    @include mobile-tablet {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 3rem;
      margin-bottom: 3rem;
    }
  }
}
