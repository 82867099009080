@import 'styles/variables';

.grid {
  display: flex;
  margin-left: -1rem;
  width: auto;
}

.gridColumn {
  padding-left: 1rem;
  background-clip: padding-box;

  > div {
    margin-bottom: 1rem;
  }
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  &.loading {
    display: block;
    column-count: 4;
    column-gap: 1rem;

    &.filtersOpen {
      column-count: 3;
    }

    & > div {
      margin-bottom: 1rem;
    }
  }

  &.empty {
    display: grid;
    justify-content: unset;
  }
}

.pagination {
  margin: 4rem 3rem;
}

.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4rem;
  align-self: center;
  justify-self: center;
}

@include tablet {
  .root {
    &.loading {
      column-count: 2;
    }
  }
}

@include mobile {
  .root {
    &.loading {
      column-count: 1;
    }
  }
}
