@import 'styles/variables';

.modal {
  overflow: auto;
}

.root {
  width: calc(100% - 33rem);
  max-width: 129rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'channel advantages'
    'verify advantages';
  grid-gap: 1rem;
  position: relative;
  margin: 13rem auto;

  &:focus-visible {
    outline: 0;
  }
}

.channel {
  grid-area: channel;
}

.backdrop {
  background: var(--modal-backdrop);
}

.paper {
  grid-area: verify;
  padding: 1rem;
}

.content {
  padding: 5rem 5rem 6rem;

  &Description {
    display: block;
    margin-block: 1.5rem 4rem;
  }
}

.step {
  display: grid;
  grid-template-columns: 12rem 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'stepNumber stepTitle' '. stepContent';
  grid-row-gap: 1rem;
  padding-block: 3rem;
  border-top: 1px solid var(--base-10);

  &Number {
    grid-area: stepNumber;
  }

  &Title {
    grid-area: stepTitle;
    display: flex;
    align-items: center;
    column-gap: 1.5rem;

    .icon {
      width: 2.5rem;
      height: 2.5rem;
      fill: var(--font-secondary-color);
    }
  }

  &Content {
    grid-area: stepContent;
  }
}

.link {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  column-gap: 0.5rem;
  align-items: center;
}

.form {
  display: flex;
  flex: 1;
}

.input {
  &:disabled {
    border: none;
    background: var(--base-5);
    color: var(--font-primary-color);
    -webkit-text-fill-color: var(--font-primary-color);
  }
}

.permissions {
  list-style: circle;
  padding-left: 2rem;

  li::marker {
    font-size: 2rem;
    color: var(--font-secondary-color);
  }
}

.advantages {
  grid-area: advantages;
  position: sticky;
  top: 13rem;
  min-height: 74rem;
  height: max-content;

  &Container {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5rem;
    overflow: hidden;
    isolation: isolate;
  }

  .background {
    z-index: -2;
  }
}

.charts {
  top: 30rem !important;
  left: 5rem !important;
  transform: scale(1.3);
  width: 120% !important;
  z-index: -1;
}

.close {
  position: absolute;
  left: calc(100% + 1rem);
  top: 0;
}

.submit {
  width: 100%;
}

.tooltip {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;

  .description {
    color: var(--white-60);
  }
}

.slider {
  margin-block: 6rem 4rem;
}

.slide {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: 1rem;
  justify-content: center;
  text-align: center;
  padding-inline: 12rem;

  .description {
    color: var(--white-40);
  }
}

.controls {
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  width: max-content;
  column-gap: 2rem;
  align-items: center;
}

@include mobile-tablet {
  .root {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas: 'advantages' 'channel' 'verify';
  }

  .advantages {
    top: 0;
    position: relative;
  }
}

@include tablet {
  .root {
    margin-top: 20.25rem;
    width: calc(100% - 32rem);
    max-width: 64rem;
  }
}

@include mobile {
  .root {
    margin-top: 9rem;
    width: 100%;
  }

  .advantages {
    .close {
      top: unset;
      bottom: calc(100% + 1rem);
      right: 2rem;
      left: auto;
    }
  }

  .charts {
    top: auto !important;
    left: auto !important;
    width: 100% !important;
    object-fit: contain;
    transform: translateY(30rem);
  }
}
