@import 'styles/fonts';
@import 'styles/variables';

.root {
  height: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.scrollabel {
  padding: 1rem;

  .item {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

.label {
  @include text-ellipsis;

  color: var(--font-secondary-color);
  transition: all 0.1s ease-in-out;
}

.channel {
  display: flex;
  align-items: center;
}

.members {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  background: var(--base-50);
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  margin-left: 1rem;

  svg {
    fill: var(--white);
    width: 1.5rem;
    height: 1.5rem;
  }
}

.iconWithInput {
  position: relative;
  margin-right: 1rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  .avatar {
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
}

.checkbox {
  width: 100%;
  margin-inline: 0;

  &.checked {
    .label {
      color: var(--brand);
    }

    .avatar {
      opacity: 0;
    }

    .input {
      opacity: 1;
    }
  }

  &:hover {
    .label {
      color: var(--font-primary-color);
    }

    .avatar {
      opacity: 0;
    }

    .input {
      opacity: 1;
    }
  }
}

.placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;

  &Avatar {
    @include skeleton;

    width: 4rem;
    height: 4rem;
  }

  &Title {
    @include skeleton;

    width: 30rem;
    height: 2rem;
    margin-left: 1rem;
  }
}
