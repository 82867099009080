@import 'styles/variables';

.root {
  flex: 1;
  display: grid;
  grid-template-columns: minmax(auto, 64rem);
  grid-template-rows: 1fr auto;
  grid-gap: 1rem;
  grid-template-areas:
    'content'
    'metrics';
}

.content {
  position: relative;
  grid-area: content;
  padding: 6rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--primary-background);

  .text {
    @include skeleton;

    height: 1.5rem;
    width: 90%;
    margin-bottom: 1rem;

    &:nth-child(2) {
      width: 80%;
    }

    &:nth-child(3) {
      width: 75%;
    }

    &:nth-child(4) {
      width: 85%;
    }

    &:nth-child(5) {
      width: 70%;
    }

    &:nth-child(6) {
      width: 90%;
    }

    &:nth-child(7) {
      width: 80%;
    }
  }
}

.contentWrapper {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  justify-items: center;
  overflow: auto;
  padding: 0 3rem 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.date {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;

  .actions {
    @include skeleton;

    width: 3rem;
    height: 3rem;
    border-radius: 100%;

    &:first-of-type {
      margin-left: 1rem;
    }
  }
}

.metrics {
  grid-area: metrics;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: space-around;

  .metric {
    @include skeleton;

    width: 10rem;
    height: 4rem;
  }
}
