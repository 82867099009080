@import 'styles/variables';
@import 'styles/fonts';

.list {
  max-height: 60rem;
  overflow-y: auto;

  @include mobile {
    max-height: 30rem;
  }
}

.checkbox {
  width: 100%;
  margin-inline: 0;

  &.checked {
    .label {
      color: var(--brand);
    }

    .icon {
      opacity: 0;
    }

    .input {
      opacity: 1;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.label {
  @include headline-10bk;

  color: var(--font-secondary-color);
  transition: all 0.1s ease-in-out;
}

.iconWithInput {
  position: relative;
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  .icon {
    width: 2rem;
    height: 2rem;
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
}

.search {
  margin-bottom: 2rem;

  &Input {
    padding-left: 4.5rem !important;
  }
}

.adornment {
  svg {
    fill: var(--font-secondary-color);
  }
}

.fallback {
  fill: var(--font-secondary-color);
}

@include desktop {
  .checkbox {
    &:hover {
      .label {
        color: var(--font-primary-color);
      }

      .icon {
        opacity: 0;
      }

      .input {
        opacity: 1;
      }
    }
  }
}
