@import 'styles/variables';

.root {
  &.errored {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .image {
    @include absolute-full-size;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play {
    @include absolute-full-size;

    z-index: 50;
    background-color: var(--gray-20);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      fill: var(--white);
    }
  }
}

.error {
  @include absolute-full-size;

  background: var(--base-10);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :global(.MuiTypography-root) {
    max-width: 90%;
    margin-top: 0.5rem;
  }

  svg {
    width: 3rem;
    height: 3rem;
    fill: var(--base-50);
  }
}

.more {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--base-20);
  backdrop-filter: blur(4px);
  border-bottom-right-radius: var(--gallery-border-radius, 4rem);
  overflow: hidden;
}
