.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4rem;
  justify-content: space-between;
  margin-bottom: 2rem;

  &.withTitles {
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 1rem;
    margin-top: 1rem;
  }

  &.singleInput {
    grid-template-columns: 1fr;
  }
}

.chart {
  width: calc(100% - 2rem);
  height: 5rem;
  margin: 0 auto 1rem;
}

.slider {
  display: flex;
  margin-inline: auto;
}

.disabled {
  opacity: 0.5;
}
