@import 'styles/variables';

.root {
  margin-top: 3rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--base-10);
    padding-bottom: 3rem;
  }
}

.row {
  @include skeleton;

  margin-top: 1rem;
  width: 70%;
  height: 2rem;

  &:nth-child(2) {
    width: 85%;
  }

  &:nth-child(3) {
    width: 60%;
  }
}
