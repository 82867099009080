@import 'styles/variables';
@import 'styles/fonts';

.root {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: stretch;
  position: relative;
  padding: 2rem;
  border-radius: 5rem;
  background-color: var(--surface);
}

.textarea {
  @include suisse-font;

  font-size: 1.75rem;
  line-height: 2rem;
  font-weight: 450;
  letter-spacing: -0.168px;
  color: var(--base-50);
  border: none;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 3rem 4rem;
  border-radius: 5rem;
  background-color: var(--base-5);
}

.textarea:focus {
  outline: none;
}

.textarea:disabled {
  background-color: var(--surface);
  color: var(--base-20);
  border: none;
}

.textarea::placeholder {
  display: none;
}
