@import 'styles/variables';

.root {
  @include max-content-width;

  padding: 3rem 3.5rem 3.5rem;
}

.footer {
  width: 100%;
  border-radius: 5rem;
  padding: 4rem 0 4rem 4rem;
  background: var(--footer-background);
}

.logo {
  width: 13rem;
  height: 5rem;
  fill: #fff;
}

.links {
  display: grid;
  grid-template-areas: 'logo catalog channels bots';
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 3rem;
}

.headline {
  color: var(--footer-headline-color);
  margin-bottom: 2rem;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: flex-start;
}

.info {
  margin-top: 13.5rem;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;

  .privacyAndTerms {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
  }

  a span {
    text-decoration: underline;
  }
}

.designedBy {
  padding-left: 2.5rem;
}

.logoContainer {
  grid-area: logo;
}

.catalog {
  grid-area: catalog;
}

.channels {
  grid-area: channels;
}

.bots {
  grid-area: bots;
}

@include tablet {
  .root {
    padding: 3rem 3.5rem;
  }

  .links {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 'logo catalog channels' '. . bots';
  }

  .info {
    margin-top: 16rem;
    grid-template-columns: repeat(3, 1fr);
    align-items: end;

    .privacyAndTerms {
      flex-direction: column;
      row-gap: 1rem;
    }
  }
}

@include mobile {
  .root {
    padding: 3rem 1.5rem 3.5rem;
  }

  .footer {
    padding: 3rem;
  }

  .links {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 'logo catalog' 'channels bots';
  }

  .logoContainer {
    margin-bottom: 4rem;
  }

  .catalog {
    margin-bottom: 5rem;
  }

  .info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 4rem;
    grid-template-areas:
      'privacy privacy'
      'copy design';
  }

  .privacyAndTerms {
    grid-area: privacy;
  }

  .designedBy {
    grid-area: design;
    justify-self: end;
  }

  .copy {
    grid-area: copy;
  }
}
