@import 'styles/fonts';

.root {
  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }

  :global(.MuiInputBase-root) {
    display: flex;
  }
}

.select {
  @include headline-10bk;

  padding: 1rem 1rem 1rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid var(--gray-10, rgb(16 41 96 / 10%));
  line-height: 1;
  display: flex;
  align-items: center;
  color: var(--base-50);
}

.icon {
  width: 2rem;
  height: 2rem;
  fill: var(--base-50);
  top: calc(50% - 1rem);

  &Open {
    transform: rotate(180deg);
  }
}

.paper {
  backdrop-filter: blur(20px);
  background: var(--growth-tooltip-background);
  border: 1px solid var(--growth-border-color) !important;
  border-radius: 2rem;
  min-width: 28rem;
  padding: 1rem;
  max-height: 42rem;
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
}

.menuItem {
  @include headline-10bk;

  padding: 1rem 2rem 1rem 1rem;
  border-radius: 1rem;
  color: var(--font-secondary-color);
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: var(--base-5);
  }
}
