@import 'styles/variables';

.root {
  margin-top: 12rem;
}

@include mobile {
  .root {
    margin-top: 8rem;
  }
}
