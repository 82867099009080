@import 'styles/variables';

.root {
  margin-top: 3rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--base-10);
    padding-bottom: 3rem;
  }
}

.avatar {
  @include skeleton;

  aspect-ratio: 1;
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
  margin-top: 2rem;
}
