@import 'styles/variables';

.channelInfo {
  @include max-content-width;
  @include content-indents;

  margin: 3rem auto;
}

.content {
  @include max-content-width;
  @include content-indents;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'filters content' 'filters chart';
}

.filters {
  grid-area: filters;
}

.sticky {
  height: max-content;
}

@include mobile {
  .content {
    display: block;
  }
}
