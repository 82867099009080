@import 'styles/variables';

.root {
  padding: 1rem;
  height: max-content;
}

.sorting {
  padding: 3rem;

  &Wrapper {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  @for $i from 1 through 5 {
    &[data-metrics-count='#{$i}'] {
      grid-template-columns: 5rem 31rem repeat(#{$i}, 1fr) !important;
    }
  }
}

.pagination {
  margin: 4rem 3rem;
}

.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4rem;
  align-self: center;
  justify-self: center;
  min-height: 50rem;
}

@include mobile-tablet {
  .sorting {
    width: max-content;

    &Title {
      width: 0;
      overflow: hidden;
    }

    @for $i from 1 through 5 {
      &[data-metrics-count='#{$i}'] {
        grid-template-columns: 5rem auto repeat(#{$i}, 17rem) !important;
      }
    }
  }
}
