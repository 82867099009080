/* stylelint-disable no-descending-specificity */

.root {
  display: grid;
  grid-column-gap: 2rem;
  align-items: center;
}

.button {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;

  &.active {
    .icon {
      path {
        fill: var(--base-20);
      }
    }

    &.desc {
      .icon {
        path:nth-child(2) {
          fill: var(--base);
        }
      }
    }

    &.asc {
      .icon {
        path:nth-child(1) {
          fill: var(--base);
        }
      }
    }
  }
}

.title {
  text-align: left;
  transition: color 0.3s ease-in-out;
}

.icon {
  path {
    transition: fill 0.3s ease-in-out;
    fill: var(--base-50);
  }
}
