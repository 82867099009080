@import 'styles/variables';

.root {
  @include max-content-width;

  padding: 0 3.5rem;
}

.channel {
  margin-block: 3rem;
}

.posts {
  display: grid;
  grid-template-columns: auto 1fr;
}

@include mobile-tablet {
  .posts {
    grid-template-columns: 1fr;
  }
}

@include mobile {
  .root {
    padding: 0 1.5rem;
  }
}
