@import 'styles/variables';

.root {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  flex-basis: 50%;
  max-width: 50%;
}

.splitted {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3rem;
}

@include mobile-tablet {
  .root {
    flex-direction: column;
    row-gap: 3rem;
  }

  .column {
    flex-basis: 100%;
    max-width: unset;
  }
}

@include mobile {
  .joined {
    grid-area: joined;
  }

  .language {
    grid-area: language;
  }

  .online {
    grid-area: online;
  }

  .subscription {
    grid-area: subscription;
  }

  .splitted {
    grid-template-columns: 1fr;
    grid-row-gap: 3rem;
    grid-template-areas: 'joined' 'language' 'online' 'subscription';
  }
}
