.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

.icon {
  margin-left: 0.5rem;
}
