.root {
  display: flex;
  flex-direction: row;
  background: var(--paper-background);
  padding: 0.5rem;
  width: max-content;
  border-radius: 1.5rem;
}

.link {
  padding: 0.875rem 1.5rem;
  border-radius: 1rem;

  :global {
    color: var(--button-text-foreground);
  }

  &.active {
    background: var(--button-primary-background);

    :global(.MuiTypography-root) {
      color: var(--button-primary-foreground);
    }
  }
}
