@import 'styles/variables';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}

.date {
  @include skeleton;

  width: 9rem;
  height: 2rem;
}

.dateTimeDivider {
  @include skeleton;

  display: inline-flex;
  align-self: center;
  width: 0.375rem;
  height: 0.375rem;
  aspect-ratio: 1;
}

.time {
  @include skeleton;

  width: 4.5rem;
  height: 2rem;
  margin-right: 1rem;
}
