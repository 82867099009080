@import 'styles/variables';

.root {
  padding: 10rem 0;
  margin-right: auto;
  margin-left: auto;

  @include mobile-tablet {
    padding: 6rem 0;
  }
}
