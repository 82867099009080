@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
}

.paper {
  padding: 3rem;
}

.metric {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);
  grid-template-areas: 'title title' 'value change' 'value percentageChange';
  grid-column-gap: 2rem;

  .title {
    grid-area: title;
    margin-bottom: 1rem;
  }

  .value {
    grid-area: value;
    align-self: center;
  }

  .change {
    grid-area: change;
    align-self: center;
  }

  .percentageChange {
    grid-area: percentageChange;
    align-self: center;
  }
}

@include mobile {
  .root {
    grid-template-columns: repeat(1, 1fr);
  }

  .metric {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'title title title' 'value . change' 'value . percentageChange';
  }
}
