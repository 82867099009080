.root {
  min-height: 30rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}
