@import 'styles/variables';

.root {
  padding: 1rem;
  display: flex;
  column-gap: 1rem;
}

.empty {
  height: 20rem;
  width: 100%;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart {
  position: relative;
  background: var(--base-5);
  flex-basis: 50%;
  height: 28.5rem;
  border-radius: 4rem;
  isolation: isolate;
  overflow: hidden;

  .text {
    width: max-content;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .title {
      display: flex;
      column-gap: 1rem;
    }

    .icon {
      width: 2rem;
      height: 2rem;
    }
  }

  .wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .value {
    position: absolute;
    overflow: hidden;
    width: var(--width);
    height: 100%;
    border-radius: 1.5rem;

    svg {
      fill: var(--white);
    }

    :global(.MuiTypography-root) {
      color: var(--white);
    }
  }
}

.male {
  .wrapper {
    svg {
      fill: var(--blue);
    }

    :global(.MuiTypography-root) {
      color: var(--blue);
    }
  }

  .value {
    background: var(--blue);
  }
}

.female {
  .wrapper {
    display: flex;
    justify-content: flex-end;

    svg {
      fill: var(--purple);
    }

    :global(.MuiTypography-root) {
      color: var(--purple);
    }
  }

  .value {
    left: auto;
    right: 0;
    display: flex;
    justify-content: flex-end;
    background: var(--purple);
  }
}

@include tablet {
  .chart {
    height: 20rem;
  }
}

@include mobile {
  .root {
    border-radius: 4rem;
  }

  .empty {
    height: 9rem;
  }

  .chart {
    height: 9rem;
    border-radius: 3rem;

    .text {
      padding: 1rem 3rem;
    }

    .value {
      border-radius: 3rem;
    }
  }
}
