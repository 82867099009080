.root {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-inline: -3rem;
  margin-block: 4rem 3rem;
}

.button {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    'avatar title views'
    'avatar members .';
  padding: 3rem;
}

.avatar {
  grid-area: avatar;
  margin-right: 2rem;
}

.title {
  grid-area: title;
}

.members {
  grid-area: members;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
  }
}

.views {
  grid-area: views;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.75rem;
  }
}

.views,
.members {
  display: flex;
  align-items: center;
  line-height: 1;
}
