@import 'styles/variables';

.root {
  @include max-content-width;

  overflow: auto !important;
  padding: 0 3.5rem 3rem;
  margin-top: 3rem;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-areas: 'left right';
}

.language {
  display: flex;
  align-items: center;
  column-gap: 1rem;

  &Dropdown {
    padding: 0.75rem 1rem;
  }

  img {
    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
  }
}

.search {
  grid-area: search;
  display: none;
}

.left {
  grid-area: left;
}

.right {
  grid-area: right;
  justify-self: end;
}

.left,
.right {
  width: max-content;
  display: flex;
  column-gap: 0.5rem;
}

.mobileFilters {
  display: none;
}

@include mobile-tablet {
  .root {
    margin-top: 0;
  }

  .search {
    display: block;
    padding-inline: 2rem;
    margin-block: 3rem 1rem;
  }

  .desktopFilters {
    display: none;
  }

  .mobileFilters {
    display: initial;
  }
}

@include mobile {
  .root {
    grid-template-columns: repeat(2, max-content);
    padding-inline: 2rem;
  }

  .right {
    justify-self: unset;
  }
}
