@import 'styles/fonts';
@import 'styles/variables';

.root {
  a {
    color: var(--font-link-color);

    &:hover {
      text-decoration: underline;
    }
  }
}

.h1 {
  @include headline-1;
}

.h1bk {
  @include headline-1bk;
}

.h2 {
  @include headline-2;
}

.h2sb {
  @include headline-2sb;
}

.h2bk {
  @include headline-2bk;
}

.h3 {
  @include headline-3;
}

.h4 {
  @include headline-4;
}

.h4bk {
  @include headline-4bk;
}

.h5 {
  @include headline-5;
}

.h6 {
  @include headline-6;
}

.h6sb {
  @include headline-6sb;
}

.h7 {
  @include headline-7;
}

.h7sb {
  @include headline-7sb;
}

.h8 {
  @include headline-8;
}

.h8sb {
  @include headline-8sb;
}

.h8bk {
  @include headline-8bk;
}

.h9 {
  @include headline-9;
}

.h9bk {
  @include headline-9bk;
}

.h10 {
  @include headline-10;
}

.h10bk {
  @include headline-10bk;
}

.h10sb {
  @include headline-10sb;
}

.h11 {
  @include headline-11;
}

.h11bk {
  @include headline-11bk;
}

.h12 {
  @include headline-12;
}

.h12bk {
  @include headline-12bk;
}

.label8 {
  @include label-8;
}

.label9 {
  @include label-9;
}

.label10 {
  @include label-10;
}

.label11 {
  @include label-11;
}

.label12 {
  @include label-12;
}

.paragraph7 {
  @include paragraph-7;
}

.paragraph8 {
  @include paragraph-8;
}

.paragraph9 {
  @include paragraph-9;
}

.paragraph10 {
  @include paragraph-10;
}

.paragraph10bk {
  @include paragraph-10bk;
}

.paragraph12 {
  @include paragraph-12;
}

.mono1 {
  @include mono-1;
}

.mono2 {
  @include mono-2;
}

.color {
  &Primary {
    color: var(--font-primary-color);
  }

  &Secondary,
  &Default {
    color: var(--font-secondary-color);
  }

  &White {
    color: #fff;
  }

  &Gray {
    color: var(--font-gray-color);
  }

  &Positive {
    color: var(--font-positive-color);
  }

  &Negative {
    color: var(--font-negative-color);
  }

  &Brand {
    color: var(--brand);
  }
}

@include tablet {
  .root.adaptive {
    &.h1bk {
      @include headline-2bk;
    }
  }
}

@include mobile {
  .root.adaptive {
    &.h1bk {
      @include headline-4bk;
    }

    &.h1 {
      @include headline-3;
    }

    &.h2 {
      @include headline-4;
    }

    &.h2sb {
      @include headline-4sb;
    }

    &.h2bk {
      @include headline-4bk;
    }

    &.h3 {
      @include headline-5;
    }

    &.h4 {
      @include headline-6;
    }

    &.h5 {
      @include headline-7;
    }

    &.h6sb {
      @include headline-8sb;
    }

    &.h7 {
      @include headline-8;
    }

    &.h8 {
      @include headline-9;
    }

    &.h8bk {
      @include headline-9bk;
    }

    &.h9 {
      @include headline-10;
    }

    &.h9bk {
      @include headline-10bk;
    }

    &.h10 {
      @include headline-12;
    }

    &.h10sb {
      @include headline-12sb;
    }

    &.h10bk {
      @include headline-12bk;
    }

    &.paragraph8 {
      @include paragraph-10;
    }

    &.paragraph10 {
      @include paragraph-12;
    }
  }
}
