@import 'styles/variables';

.root {
  display: flex;
  min-height: 100%;
}

.screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding-top: 2rem;
}
