.root {
  &.clamped {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  pre code {
    border-radius: 2rem;
  }
}

.readMore {
  margin-top: 2rem;
  display: flex;
}

.inlineButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 0.5rem;
  margin-top: 3rem;

  :global(.MuiButtonBase-root) {
    display: flex;
    flex-basis: 100%;
  }
}

.highlight {
  position: relative;
  color: var(--white);

  &::after {
    content: '';
    position: absolute;
    width: calc(100% + 0.5rem);
    height: 100%;
    top: 0;
    left: -0.25rem;
    background: var(--base-50);
    border-radius: 0.5rem;
    z-index: -1;
  }
}

.icon {
  margin-left: 0.5rem;
}
