@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
}

.item {
  display: grid;
  border-radius: 4rem;
  background-color: var(--base-5);
  padding: 3rem 1.5rem 3rem 3rem;
  grid-template-columns: 1fr max-content;
}

.channel {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: 'avatar title' 'avatar details';
  align-items: center;
  justify-content: start;
}

.avatar {
  @include skeleton;

  grid-area: avatar;
  margin-right: 2rem;
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
}

.title {
  @include skeleton;

  height: 2.25rem;
  width: 85%;
  grid-area: title;
}

.details {
  grid-area: details;
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.members {
  @include skeleton;

  height: 2.5rem;
  width: 10rem;
}

.category {
  @include skeleton;

  height: 2.5rem;
  width: 8rem;
}

.metric {
  @include skeleton;

  width: 10rem;
  height: 2rem;
  margin: 0.5rem 1.5rem;
}
