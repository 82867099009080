.root {
  margin-top: 3rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--base-10);
    padding-bottom: 3rem;
  }
}

.date {
  margin-bottom: 1rem;
}
