@import 'styles/fonts';

/* stylelint-disable */
header[data-blurred='true']:not([data-is-sticky='true']) {
  .root > li,
  .root > li > a:hover,
  .root > li > a,
  .item {
    color: white !important;

    svg {
      fill: white !important;
    }
  }
}

.icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.hoveredMenu {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  padding-top: 1rem;
  z-index: 10;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    min-width: 27rem;
    border-radius: 2rem;
    border: 1px solid var(--menu-dropdown-border);
    background: var(--menu-dropdown-background);
    box-shadow:
      0 0 0 0 rgb(0 0 0 / 3%),
      0 3px 7px 0 rgb(0 0 0 / 3%),
      0 13px 13px 0 rgb(0 0 0 / 3%),
      0 30px 18px 0 rgb(0 0 0 / 2%),
      0 54px 22px 0 rgb(0 0 0 / 0%),
      0 84px 24px 0 rgb(0 0 0 / 0%);
    backdrop-filter: blur(20px);

    .link {
      @include headline-12;

      background: none;
      text-decoration: underline;

      &:hover {
        color: var(--navigation-link-focus);
        background: none;
      }
    }

    a,
    button {
      @include label-10;

      border-radius: 1rem;
      justify-content: flex-start;
      width: 100%;
      padding: 1rem 2rem;

      &:hover {
        color: var(--menu-dropdown-item-hover-foreground);
        background: var(--menu-dropdown-item-hover-background);
      }
    }

    .divider {
      margin: 1rem -1rem;
      width: calc(100% + 2rem);
      border-bottom: 1px solid var(--divider-color);
    }
  }
}

.root {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  li {
    &:not(:last-of-type) {
      padding-right: 1rem;
    }

    a,
    .item {
      @include label-10;

      position: relative;
      display: flex;
      align-items: center;
      padding: 1rem 1.5rem;
      color: var(--navigation-link);
      transition: color 0.3s ease-in-out;
      cursor: pointer;

      &Icon {
        width: 2rem;
        height: 2rem;
        margin-left: 0.5rem;
      }

      &:hover {
        .itemIcon {
          transform: rotate3d(1, 0, 0, 180deg);
        }

        .hoveredMenu {
          display: block;
        }

        color: var(--navigation-link-focus);

        svg {
          fill: var(--navigation-link-focus);
        }
      }

      &.iconed {
        display: flex;
        line-height: 1;
        padding: 0.75rem 1.5rem;

        svg {
          transition: all 0.3s ease-in-out;
          fill: var(--navigation-link);
        }
      }
    }
  }
}

/* stylelint-enable */
