@import 'styles/variables';

.root {
  grid-area: chart;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: max-content auto;
  grid-template-areas:
    'chart axisY'
    'axisX .';
}

.chart {
  grid-area: chart;
  max-height: calc(7 * calc(2.75rem + 0.55rem));
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &Item {
    background: var(--skeleton-animated-block-background);
    border-radius: 0.275rem;
    width: 2.75rem;
    height: 2.75rem;
    margin: 0.275rem;
  }
}

.axiosX {
  grid-area: axisX;
  padding-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  &Item {
    @include skeleton;

    width: 5rem;
    height: 1.5rem;
  }
}

.axiosY {
  grid-area: axisY;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 2rem;

  &Item {
    @include skeleton;

    width: 12rem;
    height: 1.5rem;
  }
}

@include mobile-tablet {
  .root {
    grid-template-areas:
      'axisY chart'
      '. axisX';
  }

  .chart {
    width: 175rem;
  }

  .axiosY {
    padding-left: 0;
    padding-right: 2rem;
  }
}
