@import 'styles/variables';

.container {
  position: fixed;
  border-radius: 9.5rem;
  width: 100%;
  max-width: 42rem;
  height: 7rem;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background: rgb(231 234 242 / 60%);
  backdrop-filter: blur(20px);
}

.icon {
  width: 2.5rem;
  height: 2.5rem;

  path {
    stroke: var(--base-50);
  }

  margin-bottom: 0.5rem;
}

.activeIcon {
  path {
    stroke: var(--brand);
  }
}
