@import 'styles/fonts';

.root {
  width: 100%;

  :global {
    .MuiInputBase-root {
      padding: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiInputAdornment-root {
      position: absolute;
      column-gap: 0.5rem;

      svg,
      img {
        width: 2rem;
        height: 2rem;
      }
    }

    .MuiInputAdornment-position {
      &Start {
        left: 1.5rem;
      }

      &End {
        right: 3rem;
      }
    }

    .MuiInputBase-inputAdorned {
      &Start {
        padding-left: 6.5rem;
      }

      &End {
        padding-right: 6.625rem;
      }
    }
  }
}

.input {
  @include paragraph-10;

  box-sizing: content-box;

  &.primary {
    background-color: var(--input-background);
    color: var(--input-text);
  }

  &.medium {
    border-radius: 1rem;
    padding: 0.75rem 1.5rem;
  }

  &.large {
    padding: 2.75rem 3rem 2.75rem 4rem;
    border-radius: 5rem;
  }

  &.error {
    box-shadow: 0 0 0 1px var(--error);
  }

  &::placeholder {
    color: var(--input-placeholder);
  }

  &:disabled {
    pointer-events: unset;
    cursor: not-allowed;
    background: none;
    color: var(--base-20);
    border: 1px solid var(--base-10);
  }
}

.label {
  @include paragraph-12;

  transform: scale(1) translate(3rem, 2.75rem);
  color: var(--base-50);

  &Filled {
    transform: scale(1) translate(3rem, 1.25rem);
  }

  &Focused {
    transform: scale(1) translate(3rem, 1.25rem);
    color: var(--base-50) !important;
  }
}

.errorIcon {
  background: rgb(226 84 63 / 10%);

  svg {
    fill: var(--error);
  }

  &:hover {
    background: rgb(226 84 63 / 10%);
    box-shadow: 0 0 0 0.5rem var(rgb(226 84 63 / 10%));
  }
}

.formHelperText {
  display: none;
}
