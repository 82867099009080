.scrollContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.scrollWrapper {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 2rem;

  &::-webkit-scrollbar {
    display: none;
  }
}

.arrowButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
