@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.paper {
  display: flex;
  flex-direction: column;
  padding: 3rem 0 0;
  flex: 1;
  max-height: 89rem;
  overflow-y: hidden;
  position: relative;

  @include horizontal-gradient;
}

.tabs {
  display: inline-grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 1.5rem;
  grid-column-gap: 3rem;
  margin-bottom: 1rem;

  .tab {
    @include skeleton;

    width: 10rem;
    height: 2rem;

    &:nth-child(1) {
      justify-self: flex-end;
    }

    &:nth-child(2) {
      justify-self: flex-start;
    }
  }

  .active {
    @include skeleton;

    justify-self: flex-end;
    margin-right: 3.5rem;
    width: 3rem;
    height: 0.5rem;
  }
}

@include mobile-tablet {
  .paper {
    @include vertical-gradient;
  }

  .posts {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 1rem;
    column-gap: 1rem;
  }
}
