.popover {
  max-width: 328px;
  padding: 24px;
  border-radius: 24px;
  background-color: var(--growth-tooltip-background);
  border: 1px solid var(--growth-tooltip-border);
  box-shadow:
    0 84px 24px 0 rgb(0 0 0 / 0%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 0 0 0 rgb(0 0 0 / 3%);
  backdrop-filter: blur(40px);
}

.MuiTooltip {
  min-width: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &Date {
    color: rgb(255 255 255 / 50%);
    margin-bottom: 0.25rem;
  }
}
