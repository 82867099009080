@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 0.5rem;

  :global {
    .apexcharts-xaxistooltip {
      display: none;
    }

    .apexcharts-xcrosshairs {
      stroke-dasharray: 4 4;
      stroke: var(--gray-10);
      stroke-width: 2;
      stroke-linecap: round;
    }

    .apexcharts-xaxis-tick {
      display: none;
    }

    .apexcharts-grid-borders {
      display: none;
    }
  }
}

.headline {
  padding: 0;
}

.metrics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 4rem;

  &Hidden {
    //visibility: hidden;
  }

  &Values {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
  }
}

.tooltip {
  display: none;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.fix {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@include mobile {
  .metrics {
    padding: 0 3rem;
  }
}
