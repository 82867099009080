@import 'styles/variables';

.root {
  @include max-content-width;

  display: none;
  padding: 3rem 6.5rem;
  position: relative;
  z-index: 1;
}

.open {
  grid-area: open;

  &.opened,
  &.opened:hover {
    background: var(--base-100);
    color: var(--surface);

    svg {
      transform: rotateY(180deg);
      fill: var(--surface);
    }
  }
}

@include mobile-tablet {
  .root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@include mobile {
  .root {
    padding: 2rem 4.5rem;
  }
}
