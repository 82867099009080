@import 'styles/variables';

.layout {
  background-color: var(--surface);
  position: relative;
  display: flex;
}

.screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding-top: 2rem;
}

.main {
  width: 100vw !important;
  margin-top: 2rem;
  flex: 1;
  background-color: var(--background);
  border-top-left-radius: 5rem;
  border-top-right-radius: 5rem;
}

@include tablet {
  .root {
    margin-top: calc((56px + 3rem) * -1);
  }
}

@include mobile {
  .layout {
    padding: 0;
  }
}
