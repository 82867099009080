.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-anchor: none;

  &.vertical {
    flex-direction: column;
    overflow-y: auto;
  }
}
