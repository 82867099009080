@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: repeat(4, auto) 1fr repeat(2, auto);
  justify-items: start;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 4rem;
  grid-column-gap: 1rem;

  &.withChannelInfo {
    grid-template-columns: repeat(5, auto) minmax(2rem, 1fr) repeat(2, auto);
  }
}

.post {
  padding: 0.625rem 1.5rem 0.625rem 1rem !important;
  column-gap: 0.75rem;
  align-items: center;
  line-height: 1 !important;

  svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

.divider {
  display: inline-flex;
  align-self: center;
  width: 3px;
  height: 3px;
  margin: 0 1rem;
  border-radius: 50%;
  background-color: var(--date-time-divider-color);
}

.type {
  display: inline-flex;
  align-items: center;
  padding-right: 1rem;

  svg {
    width: 2rem;
    height: 2rem;
    margin: 0.5rem 1.5rem 0.5rem 0.5rem;
    fill: var(--font-secondary-color);
  }
}

.line {
  height: 0;
  width: calc(100% - 2rem);
  margin-inline: 1rem;
  border-top: 1px solid var(--base-10);
}

.button {
  padding: 0.5rem 1.5rem 0.5rem 0.5rem !important;
  display: grid;
  grid-template-columns: repeat(3, auto);

  .title {
    @include text-ellipsis;

    margin-inline: 0.75rem 1rem;
  }

  .members {
    display: flex;
    align-items: center;
    column-gap: 0.75rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--font-secondary-color);
    }
  }
}
