@import 'styles/variables';

.root {
  grid-area: overview;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@include desktop {
  .item {
    &:not(:last-of-type) {
      border-right: 1px solid var(--base-10);
    }
  }
}

@include tablet {
  .root {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .item {
    &:nth-child(odd) {
      border-right: 1px solid var(--base-10);
    }

    &:nth-child(n + 3) {
      border-top: 1px solid var(--base-10);
    }
  }
}

@include mobile {
  .root {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }

  .item {
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--base-10);
    }
  }
}
