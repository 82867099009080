@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 6rem;
  grid-column-gap: 2rem;
  align-items: center;
  padding: 2rem 3rem;
  border-radius: 5rem;
  background: var(--surface);
}

.icon {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
}

.info {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 3rem);
  align-items: center;
}

.text {
  @include text-ellipsis;
}

.dropdown {
  background: var(--surface);
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
}
