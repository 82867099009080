@import 'styles/variables';
@import 'styles/fonts';

.arrows {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;

  @include mobile-tablet {
    display: none;
  }
}

.pagination {
  @include suisse-font;

  display: block;
  text-align: center;
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-weight: 450;
  letter-spacing: -2.4%;
  color: var(--font-secondary-color);
}
