@import 'styles/variables';

.root {
  background: var(--secondary-background);
  border-radius: 4rem;
  margin: 1rem auto 0;
  width: calc(100% - 2rem);
}

.content {
  display: flex;
  flex-direction: column;
  padding: 3rem;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr 7.5rem;
  grid-template-rows: repeat(2, 2rem);
  grid-column-gap: 1.5rem;
  grid-template-areas: 'icon title growth' 'icon top growth';

  .icon {
    @include skeleton;

    grid-area: icon;
    aspect-ratio: 1;
    width: 4rem;
    height: 4rem;
  }

  .title {
    @include skeleton;

    grid-area: title;
    width: 10rem;
    height: 1.5rem;
    align-self: flex-start;
  }

  .top {
    @include skeleton;

    grid-area: top;
    width: 6.25rem;
    height: 1.5rem;
    align-self: flex-end;
  }

  .growth {
    @include skeleton;

    grid-area: growth;
    width: 100%;
    height: 100%;
  }
}

.avatar {
  @include skeleton;

  display: flex;
  align-self: center;
  width: 8rem;
  height: 8rem;
  aspect-ratio: 1;
  border-radius: 100%;
  margin: 2rem 0 2.5rem;
}

.name {
  @include skeleton;

  align-self: center;
  width: 18.75rem;
  height: 2rem;
  margin-bottom: 0.75rem;
}

.channelInfo {
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;

  .icon {
    @include skeleton;

    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    aspect-ratio: 1;
  }

  .members {
    @include skeleton;

    width: 6.25rem;
    height: 2rem;
    margin-right: 1rem;
  }

  .category {
    @include skeleton;

    width: 5.875rem;
    height: 2.5rem;
  }
}

.metrics {
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  padding: 2rem;

  .metric {
    @include skeleton;

    height: 3rem;
    width: 8.125rem;
  }
}
