.menu {
  background: none;
  top: 1.175rem;
}

.paper {
  background: none;
  border-radius: 3rem;
  padding: 0;
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
}

.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 3rem;
  padding: 0;
}

.button {
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0.75rem 1.5rem;
  background: var(--input-background);
  border-radius: 1rem;
  flex-basis: 100%;
  text-align: left;

  &:last-of-type {
    text-align: right;
  }

  :global(.MuiTypography-root) {
    color: var(--input-text);
  }
}
