@import 'styles/variables';

.grid {
  @include max-content-width;
  @include content-indents;

  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas: 'filters-panel filters-panel' 'filters main' 'filters catalogue';
}

.filters {
  grid-area: filters;
}

.main {
  grid-area: main;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: 3rem;
  grid-template-areas:
    'overview overview overview overview'
    'members members categories rating';
}

.catalogue {
  grid-area: catalogue;
}

.empty {
  height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem;

  &Block {
    @include max-content-width;
    @include content-indents;

    margin-top: 3rem;
  }
}

@include mobile-tablet {
  .grid {
    display: block;
  }

  .main {
    margin-top: 3rem;
  }
}

@include tablet {
  .main {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'overview overview'
      'members members'
      'categories rating';
  }
}

@include mobile {
  .main {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      'overview'
      'members'
      'categories'
      'rating';
  }
}
