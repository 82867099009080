@import 'styles/variables';

.root {
  border-radius: 4rem;
  background-color: var(--base-5);
  padding: 3rem 1.5rem 3rem 3rem;
  display: grid;
  grid-template-columns: 1fr max-content;
  transition: all 0.3s ease-in-out;
}

.info {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: auto auto;
  grid-template-areas: 'avatar title link' 'avatar details details';
  align-items: center;
  justify-content: start;
}

.avatar {
  grid-area: avatar;
  margin-right: 2rem;
}

.title {
  @include text-ellipsis;

  grid-area: title;
}

.details {
  grid-area: details;
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.link {
  grid-area: link;
  padding: 0.5rem !important;
  width: 3rem;
  height: 3rem;
}

.metric {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 1rem;
  column-gap: 0.75rem;

  &.highlight {
    svg {
      color: var(--font-positive-color);
      fill: var(--font-positive-color);
    }

    span {
      color: var(--font-positive-color);
    }
  }

  &Icon {
    width: 2rem;
    height: 2rem;
    fill: var(--font-secondary-color);
  }
}

.members {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  margin-right: 1.25rem;

  &Icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--font-secondary-color);
  }
}

.tag {
  @include text-ellipsis;
}

@include desktop {
  .root {
    &:hover {
      cursor: pointer;
      background: var(--surface);
      box-shadow:
        0 0 0 0 rgb(35 41 59 / 8%),
        0 4px 9px 0 rgb(35 41 59 / 8%),
        0 17px 17px 0 rgb(35 41 59 / 7%),
        0 39px 23px 0 rgb(35 41 59 / 4%),
        0 69px 28px 0 rgb(35 41 59 / 1%),
        0 108px 30px 0 rgb(35 41 59 / 0%);
    }
  }
}

@include tablet {
  .root {
    &:nth-child(n + 6) {
      display: none;
    }
  }
}

@include mobile {
  .root {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    padding: 2rem;

    &:nth-child(n + 4) {
      display: none;
    }
  }

  .metric {
    margin-top: 2rem;
  }
}
