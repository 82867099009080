@import 'styles/variables';
@import 'styles/fonts';

.container {
  @include max-content-width;

  flex-shrink: 1;
  padding: 2rem 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 7rem;
  position: relative;
}

.categoryButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-bottom: 10rem;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  gap: 5rem;
  text-align: center;
  margin-bottom: 10.75rem;

  @include mobile-tablet {
    margin-top: 17rem;
  }
}

.searchBox {
  display: flex;
  width: 100% !important;
  max-width: 52rem !important;
}

.buttonWithText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  font-weight: 450;
  font-size: 1.75rem;
  line-height: 2rem;
}

.icon {
  path {
    fill: var(--base-50) !important;
    color: var(--base-50) !important;
  }
}

.title {
  height: auto;
  line-height: 128% !important;
}

.span {
  color: var(--base-50);
  background-color: var(--base-5);
  padding: 1.8rem 1.5rem 0.8rem;
  margin: 0 1rem;
  border-radius: 2rem;

  @include mobile-tablet {
    padding: 1rem;
  }
}

.categoriesListWrapper {
  display: flex;
  width: 100%;

  @include mobile-tablet {
    display: none;
    width: 100%;
  }
}

.categoriesTopListWrapper {
  position: absolute;
  top: 3rem;
  left: 1rem;
  display: flex;
  width: 100%;
}

.itemButton {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center !important;
  gap: 1rem !important;
  flex-wrap: nowrap !important;
  text-wrap: nowrap !important;
  padding: 0.5rem 1rem 0.5rem 0.5rem !important;
  color: var(--base-50);
  transition: all 0.3s ease-in-out !important;

  path {
    stroke: var(--base-50) !important;
    fill: var(--base-50) !important;
  }
}

.buttonIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 100%;
  background-color: var(--base-5);
  aspect-ratio: 1;
}

.buttonText {
  @include suisse-font;

  color: var(--base-50);
  font-size: 1.75rem;
  font-weight: 450;
  line-height: 2rem;
  letter-spacing: -0.168px;
}
