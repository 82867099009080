.thumb {
  width: 2rem;
  height: 2rem;
  box-shadow: none;
  border-radius: 2.5rem !important;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.track {
  border-radius: 2.5rem;
  background-color: var(--base-10);
  opacity: 1 !important;
}

.switchBase {
  padding: 0;
  margin: 0.25rem;
  color: var(--white);
  transform: translateX(0);

  &.checked {
    transform: translateX(0);
    right: 0;
    left: auto;
    color: var(--white);

    + .track {
      background-color: var(--brand);
    }
  }
}

.root {
  width: 5rem;
  height: 2.5rem;
  padding: 0;

  .switchBase,
  .thumb {
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    .thumb {
      width: 2.5rem;
    }
  }

  &:active {
    .thumb {
      width: 4.5rem;
    }

    .switchBase {
      &.checked {
        transform: translateX(0);
      }
    }
  }
}
