@import 'styles/variables';

.adsType {
  margin-top: 4rem;
  color: var(--brand) !important;
}

/* stylelint-disable */
.root {
  &.animated {
    .svg {
      path:nth-child(2) {
        --start-point: 75px;
        --end-point: calc((var(--length) - 75) * var(--multiplier));
        --multiplier: -1px;
        stroke: var(--brand);
        stroke-linecap: round;
        stroke-width: 2px;
        stroke-dashoffset: var(--start-point);
        stroke-opacity: 0.7;
        stroke-dasharray: 75px var(--length);
        animation: dash 5s cubic-bezier(0, 0.41, 0.99, 0.48) infinite;
      }
    }
  }

  .svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    path:nth-child(2) {
      stroke-opacity: 0;
    }
  }

  &.revert {
    .svg path:nth-child(2) {
      --start-point: calc((var(--length) - 75) * -1px);
      --end-point: 75px;
      --multiplier: 1px;
    }

    .source {
      transform: rotateY(180deg);
      left: calc(50% + 4rem);
    }
    .destination {
      transform: rotateY(180deg);
      right: calc(50% - 4rem);
      left: auto;
    }
  }
}

.channel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 4rem 0 7rem;
}

.source {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: calc(50% - 4rem);
  height: 100%;
  left: 0;
  transition: all 0.5s $animation-function;

  .svg {
    width: 120%;
    height: auto;

    &:nth-child(1) {
      aspect-ratio: 5.3;
      bottom: calc(62% - 4px);
      top: auto;

      path:nth-child(2) {
        animation-delay: 0ms;
        transition: stroke-opacity 100ms;
      }
    }
    &:nth-child(2) {
      aspect-ratio: 11.7;
      top: auto;
      bottom: calc(62% - 12px);

      path:nth-child(2) {
        animation-delay: 3030ms;
        transition: stroke-opacity 100ms;
        transition-delay: 3030ms;
      }
    }
    &:nth-child(3) {
      top: auto;
      bottom: calc(62% - 21px);
      aspect-ratio: 312;

      path:nth-child(2) {
        animation-delay: 5900ms;
        transition: stroke-opacity 100ms;
        transition-delay: 5900ms;
      }
    }
    &:nth-child(4) {
      aspect-ratio: 11.7;
      top: calc(38% + 25px);

      path:nth-child(2) {
        animation-delay: 1600ms;
        transition: stroke-opacity 100ms;
        transition-delay: 1600ms;
      }
    }
    &:nth-child(5) {
      aspect-ratio: 5.3;
      top: calc(38% + 32px);

      path:nth-child(2) {
        animation-delay: 4000ms;
        transition: stroke-opacity 100ms;
        transition-delay: 4000ms;
      }
    }
  }
}

.destination {
  position: absolute;
  width: calc(50% - 7.5rem);
  max-width: calc($max-content-width / 2 - 4rem);
  right: auto;
  left: calc(50% + 4rem);
  height: 100%;
  transition: all 0.5s $animation-function;

  .svg {
    width: 100%;
    height: auto;

    &:nth-child(1) {
      aspect-ratio: 1.2;
      width: calc(100% - 6rem);
      height: auto;
      top: 38%;

      path:nth-child(2) {
        animation-delay: 0ms;
        transition: stroke-opacity 100ms;
      }
    }
    &:nth-child(2) {
      aspect-ratio: 1.12;
      width: calc(89.6% - 6rem);
      height: auto;
      top: calc(38% + 8px);

      path:nth-child(2) {
        animation-delay: 2630ms;
        transition: stroke-opacity 100ms;
        transition-delay: 2630ms;
      }
    }
    &:nth-child(3) {
      aspect-ratio: 1.026;
      width: calc(79.2% - 6rem);
      height: auto;
      top: calc(38% + 16px);

      path:nth-child(2) {
        animation-delay: 5600ms;
        transition: stroke-opacity 100ms;
        transition-delay: 5600ms;
      }
    }
    &:nth-child(4) {
      aspect-ratio: 0.92;
      width: calc(68.8% - 6rem);
      height: auto;
      top: calc(38% + 24px);

      path:nth-child(2) {
        animation-delay: 1300ms;
        transition: stroke-opacity 100ms;
        transition-delay: 1300ms;
      }
    }
    &:nth-child(5) {
      aspect-ratio: 0.817;
      width: calc(58.4% - 6rem);
      height: auto;
      top: calc(38% + 32px);

      path:nth-child(2) {
        animation-delay: 3700ms;
        transition: stroke-opacity 100ms;
        transition-delay: 3700ms;
      }
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@include tablet {
  .root {
    .source {
      transform: rotate(15deg);
      top: -20%;
    }
    .destination {
      transform: rotate(15deg);
      top: 15%;
    }

    &.revert {
      .source {
        transform: rotateY(180deg) rotate(15deg);
        top: -20%;
      }
      .destination {
        transform: rotateY(180deg) rotate(15deg);
        top: 15%;
      }
    }
  }

  .destination {
    .svg {
      &:nth-child(1) {
        width: 120%;
      }

      &:nth-child(2) {
        width: 109.6%;
      }

      &:nth-child(3) {
        width: 99.2%;
      }

      &:nth-child(4) {
        width: 88.8%;
      }

      &:nth-child(5) {
        width: 78.4%;
      }
    }
  }
}

@include mobile {
  .root {
    display: none;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: var(--end-point);
  }
}
