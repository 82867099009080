@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: 1fr 13rem;
  grid-column-gap: 3rem;
  background: var(--base-5);
  border-radius: 4rem;
  padding: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1.5rem 3rem 2rem 1.5rem;
}

.channel {
  @include skeleton;

  height: 4rem;
  width: 15rem;
}

.date {
  @include skeleton;

  height: 2rem;
  width: 20rem;
}

.meta {
  display: inline-flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
}

.text {
  @include skeleton;

  height: 2rem;

  &:last-of-type {
    width: 90%;
  }
}

.gallery {
  @include skeleton;

  aspect-ratio: 1;
  border-radius: 3rem;
}
