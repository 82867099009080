@import 'styles/variables';

/* stylelint-disable */
.root {
  opacity: 1;
  transform-origin: 50% 50%;
  border-radius: 4rem;
  cursor: grab;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  background-color: var(--base-5);

  &:hover {
    background-color: var(--surface);
    box-shadow:
      0px 0px 3px 0px rgba(0, 0, 0, 0.04),
      0px 1px 3px 0px rgba(0, 0, 0, 0.04),
      0px 6px 6px 0px rgba(0, 0, 0, 0.03),
      0px 13px 8px 0px rgba(0, 0, 0, 0.02),
      0px 22px 9px 0px rgba(0, 0, 0, 0.01),
      0px 35px 10px 0px rgba(0, 0, 0, 0);

    .delete {
      z-index: 4;
      opacity: 1;
    }

    .order {
      opacity: 0;
    }
  }

  &.opacity {
    opacity: 0.5;
  }

  &.dragging {
    cursor: grabbing;
    background-color: var(--surface);
    box-shadow:
      0px 0px 3px 0px rgba(0, 0, 0, 0.04),
      0px 1px 3px 0px rgba(0, 0, 0, 0.04),
      0px 6px 6px 0px rgba(0, 0, 0, 0.03),
      0px 13px 8px 0px rgba(0, 0, 0, 0.02),
      0px 22px 9px 0px rgba(0, 0, 0, 0.01),
      0px 35px 10px 0px rgba(0, 0, 0, 0);

    &:hover {
      .delete {
        z-index: 4;
        opacity: 0;
      }

      .order {
        opacity: 1;
      }
    }
  }
}

.order {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 !important;
  z-index: 2;
  width: 3rem;
  height: 3rem;
  transition: opacity 0.1s ease-in-out;
}

.delete {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 !important;
  z-index: 1;
  width: 3rem;
  height: 3rem;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;

  svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

.icon {
  fill: var(--base-50);
  width: 2rem;
  height: 2rem;
}

.controls {
  position: relative;
  width: 3rem;
  height: 3rem;
}

@include mobile-tablet {
  .root {
    &:hover {
      .delete,
      .order {
        opacity: 1;
      }
    }
  }

  .controls {
    display: flex;
    column-gap: 1rem;
    width: max-content;
  }

  .delete,
  .order {
    position: relative;
    opacity: 1;

    &:hover {
      opacity: 1;
    }
  }
}
