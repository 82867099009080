@import 'styles/variables';

.root {
  margin-top: calc((56px + 2.5rem) * -1);
}

.grid {
  @include max-content-width;

  padding: 0 3.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
}

@include tablet {
  .root {
    margin-top: calc((56px + 3rem) * -1);
  }
}

@include mobile {
  .grid {
    padding: 0 2rem;
  }
}
