@import 'styles/variables';

.root {
  position: sticky;
  top: 15.7rem;
  margin-top: 10rem;
}

.tooltip {
  transform: translateY(-100%);
  padding: 3rem;
  min-width: unset;
  width: auto;

  .grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 2rem;
  }
}

@include mobile-tablet {
  .root {
    position: initial;
    top: auto;
  }
}

@include tablet {
  .root {
    margin: 6rem 8rem;
  }
}

@include mobile {
  .root {
    position: relative;
    margin: 6rem 1.5rem;
  }
}
