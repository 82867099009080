@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  padding: 3.5rem 4rem 2rem;

  &.multi {
    position: unset;
  }
}

.headline {
  margin-bottom: 2.5rem;
}

.sizer {
  aspect-ratio: 1;
}

.info {
  position: absolute;
  top: calc(50% + 2rem);
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  z-index: -1;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 1rem;
  justify-content: center;

  .title {
    @include text-ellipsis;

    padding-inline: 3rem;
  }

  .value {
    white-space: nowrap;
  }
}

@include tablet {
  .root {
    width: 100%;
  }

  .sizer {
    margin-inline: auto;
    width: 80%;
  }
}

@include mobile {
  .root {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 'title chart' 'value chart';
    align-items: center;

    &.multi {
      width: 100%;
    }
  }

  .headline {
    @include text-ellipsis;

    grid-area: title;
    margin: 0;
  }

  .sizer {
    grid-area: chart;
    width: 12.5rem;
    height: 12.5rem;
  }

  .info {
    position: unset;
    grid-area: value;
    transform: none;
    width: auto;
    text-align: left;
    justify-content: start;
    grid-row-gap: 0.75rem;

    .title {
      padding-inline: 0;
    }
  }
}
