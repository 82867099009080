@import 'styles/variables';

.root {
  @include max-content-width;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-block: 32rem;
  padding-inline: 3.5rem;
  row-gap: 6rem;
}

.title {
  max-width: 107rem;
}

@include mobile {
  .root {
    padding-inline: 1.5rem;
    margin-block: 16rem;
  }
}
