@import 'styles/fonts';
@import 'styles/variables';

.root {
  text-transform: none;
  border-radius: 4rem;
  min-width: auto;

  &::before {
    border-radius: 4rem;
  }
}

.primary {
  position: relative;
  color: var(--button-primary-foreground);
  background:
    linear-gradient(var(--button-primary-background), var(--button-primary-background)) padding-box,
    linear-gradient(180deg, rgb(255 255 255 / 40%), rgb(255 255 255 / 0%)) border-box;
  background-color: var(--button-primary-background);
  border: 1px solid transparent;

  svg {
    fill: var(--button-primary-foreground);
  }

  &:hover {
    background-color: var(--button-primary-background);
    color: var(--button-primary-foreground);
  }

  &:disabled {
    background: var(--button-primary-background-disabled);
    color: var(--button-primary-foregraund-disabled);
    pointer-events: initial;
    cursor: not-allowed;

    &:hover {
      background: var(--button-primary-background-disabled);
      color: var(--button-primary-foregraund-disabled);
    }
  }
}

.light {
  background-color: var(--button-light-background);
  color: var(--button-light-foreground);
  border: 1px solid transparent;

  svg {
    fill: var(--button-light-foreground);
  }

  &:hover {
    background-color: var(--button-light-background);
    color: var(--button-light-foreground);
  }
}

.secondary {
  background-color: var(--button-secondary-background);
  color: var(--button-secondary-foreground);
  border: 1px solid transparent;

  svg {
    fill: var(--button-secondary-foreground);
  }

  &:hover {
    background-color: var(--button-secondary-background);
    color: var(--button-secondary-foreground);
    box-shadow: 0 0 0 0.5rem var(--button-secondary-background);
  }

  &.small,
  &.medium {
    &:hover {
      box-shadow: 0 0 0 0.25rem var(--button-secondary-background);
    }
  }
}

.text {
  background-color: initial;
  color: var(--button-text-foreground);
  border: 1px solid transparent;

  svg {
    fill: var(--button-text-foreground);
  }

  &:hover {
    color: var(--button-text-foreground);
    background-color: var(--base-5);
  }
}

.startIcon {
  display: inline-flex;
}

.medium {
  padding: 1rem 2rem;

  @include headline-10bk;

  .startIcon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.75rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }
}

.large {
  padding: 2.875rem 4rem;

  @include headline-9bk;

  .startIcon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.small {
  padding: 0.625rem 2.5rem;

  @include headline-12bk;

  .startIcon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.75rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.rounded {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  min-width: auto;
  aspect-ratio: 1;

  &.large {
    padding: 2.75rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &.medium {
    padding: 1rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  &.small {
    padding: 0.75rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.iconned {
  width: auto;
  height: auto;
  padding: 0.5rem;
}

@include mobile {
  .large {
    @include headline-10bk;

    padding: 2rem 3rem;

    &.rounded {
      padding: 2rem;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .medium {
    @include headline-12bk;
  }
}
