@import 'styles/variables';

@mixin clickable {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 15rem;
  padding: 0.5rem;

  @include desktop {
    &:hover {
      background-color: var(--base-5);
    }
  }
}

.root {
  padding: 4rem 1rem 2rem 2rem;
}

.keyword {
  @include clickable;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 1rem;
  margin-top: 1rem;

  .rounded {
    border-radius: 100%;
    background-color: var(--base-5);
    padding: 1rem;
    aspect-ratio: 1;
    width: 4rem;
    height: 4rem;
  }

  svg {
    fill: var(--base-50);
    width: 2rem;
    height: 2rem;
  }
}

.list {
  max-height: 53rem;
  margin-top: 2rem;
  padding-right: 1rem;
}

.item {
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.empty {
  min-height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@include mobile {
  .root {
    padding-inline: 0;
  }

  .list {
    padding-right: 0;
  }
}
