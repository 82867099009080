.tabs {
  display: flex !important;
  width: calc(100% + 2rem) !important;
  padding: 0.5rem !important;
  border-radius: 1.5rem !important;
  margin-inline: -1rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.link {
  padding: 0.875rem 1.5rem !important;
  flex: 1 1 0;
}

.indicator {
  border-radius: 1rem !important;
  height: calc(100% - 1rem) !important;
  top: 0.5rem !important;
}
