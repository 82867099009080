@import 'styles/variables';
@import 'styles/fonts';

.root {
  @include max-content-width;

  padding: 3.5rem 3.5rem 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-height: max-content;
  width: 100%;
}

.heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 4rem;

  @include mobile-tablet {
    margin-bottom: 2rem;
  }
}

.text {
  flex-shrink: 1;
}

.titleBox {
  max-width: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2rem;

  @include mobile-tablet {
    margin-bottom: 1rem;
  }
}

.badge {
  @include suisse-font;

  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 450;
  padding: 0.25rem 1rem;
  color: var(--base-50);
  border: 1px solid var(--base-50);
  border-radius: 3rem;
}

.seeAll {
  margin-top: 2rem;
  align-self: flex-end;
  text-wrap: nowrap !important;

  @include mobile-tablet {
    margin-top: 1rem;
  }
}

.cards {
  background-color: var(--surface);
  border-radius: 5rem;
  padding: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mobile-tablet {
    background-color: transparent;
    padding: 0;
    width: 100%;
  }

  @include mobile {
    grid-template-columns: 1fr;
  }
}

.item {
  position: relative;
  list-style: none;
  text-decoration: none;
}
