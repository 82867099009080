.root {
  display: inline-flex;
  height: fit-content;
  padding: 0.375rem 0;
  column-gap: 0.5rem;
  align-items: center;
}

.tooltip {
  line-height: 0;
  padding: 0.75rem;

  &Medium {
    padding: 0.5rem;
  }
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  fill: var(--font-secondary-color);

  &Medium {
    width: 2rem;
    height: 2rem;
  }
}
