@import 'styles/variables';

.root {
  height: 100%;
  padding: 1rem 0;

  &.gradiented {
    @include horizontal-gradient;
  }

  &:not(.initialized) {
    @include desktop {
      .scroller {
        flex-direction: column;
        overflow-y: auto;
      }
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroller {
  height: 100%;
}

@include mobile-tablet {
  .root {
    padding: 0 0 0 1rem;

    &.gradiented {
      @include vertical-gradient;
    }
  }

  .item {
    display: flex;
    width: 39rem;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .scroller {
    padding: 1rem 0;
  }
}
