@import 'styles/variables';

.root {
  @include max-content-width;

  padding: 0 3.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-block: 3rem;
}

.filters {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.search {
  grid-area: search;
  display: none;
}

.mobileFilters {
  display: none;
}

@include mobile-tablet {
  .search {
    display: block;
    padding-inline: 2rem;
    margin-block: 3rem 1rem;
  }

  .desktopFilters {
    display: none;
  }

  .mobileFilters {
    display: initial;
  }
}

@include mobile {
  .root {
    column-gap: 0.5rem;
  }
}
