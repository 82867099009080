@import 'styles/variables';

.popover {
  max-width: 77rem;
  padding: 3rem;
  border-radius: 3rem;
  background-color: var(--growth-tooltip-background);
  border: 1px solid var(--growth-tooltip-border);
  box-shadow:
    0 84px 24px 0 rgb(0 0 0 / 0%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 0 0 0 rgb(0 0 0 / 3%);
  backdrop-filter: blur(40px);
}

.description {
  margin-block: 2rem;
}

.channels {
  display: flex;
  column-gap: 1rem;
}

.placeholder {
  width: 17rem;
  border-radius: 3rem;
  background-color: var(--base-5);
  flex-basis: calc(25%);
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  align-items: center;
  padding: 2rem;

  .avatar {
    @include skeleton;

    border-radius: 100%;
    width: 6rem;
    height: 6rem;
  }

  .title {
    @include skeleton;

    width: 13rem;
    height: 2rem;
  }

  .members {
    @include skeleton;

    width: 8rem;
    height: 1.75rem;
  }
}

.channel {
  width: 17rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 25%;
  border-radius: 3rem !important;
  padding: 2rem !important;

  .title {
    @include text-ellipsis;

    max-width: 13rem;
    margin-top: 1.5rem;
  }

  .members {
    margin-top: 0.5rem;
    padding: 0.625rem 1rem;
    display: flex;
    align-items: center;
    column-gap: 0.75rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--base-50);
    }

    :global(.MuiTypography-root) {
      line-height: 1;
      padding: 0 !important;
      margin-top: 0;
    }
  }
}
