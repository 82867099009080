.autocomplete {
  padding: 1.25rem 1rem;

  &Input {
    margin-top: 0 !important;
    padding-right: 3rem;
  }

  :global(.MuiAutocomplete-endAdornment) {
    top: calc(50% - 1.5rem);
  }
}
