@import 'styles/variables';

.root {
  max-width: 85rem;
  width: 100%;
}

.main {
  padding: 1rem;
  animation: fade-in-down-100 $animation-function 500ms;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
}

.tabs {
  justify-content: start !important;
  margin: 2rem 0 2rem 3rem;
}

.search {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;

  .wrapper {
    position: relative;

    .countries {
      position: absolute;
      right: 0;
      top: calc(50% - 2.1rem);
    }
  }
}

.buttonMobile {
  display: none;
}

.result {
  margin-top: 1rem;
  animation: fade-in-down-200 $animation-function 500ms;
}

.content {
  padding: 4rem 2rem 2rem;
}

.filters {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-inline: 2rem;
}

.close {
  display: none;
}

@keyframes fade-in-down-100 {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-down-200 {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@include mobile-tablet {
  .filters {
    overflow: auto;
  }
}

@include tablet {
  .root {
    padding: 2rem;
    width: 100%;
    margin: 0;
    max-width: unset;
  }
}

@include mobile {
  .root {
    max-width: unset;
    width: 100%;
    margin: 0;
  }

  .close {
    display: initial;

    svg {
      fill: var(--font-primary-color);
    }
  }

  .tabs {
    margin: 2rem 0 2rem 2rem;
  }

  .header {
    align-items: center;
  }

  .input {
    &Wrapper {
      width: calc(100% - 1rem) !important;
      justify-self: center;
    }
  }

  .search {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 1rem;
  }

  .buttonDesktop {
    display: none;
  }

  .buttonMobile {
    display: initial;
  }
}

@include mobile {
  .input {
    padding-right: 15rem;

    &:placeholder-shown {
      text-overflow: ellipsis;
    }
  }
}
