@import 'styles/variables';

.root {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding: 1rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 15%;
    height: 100%;
    background: linear-gradient(90deg, rgb(0 0 0 / 0%), var(--paper-background));
  }
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 4rem;
  background: var(--secondary-background);
  width: 100%;
}

.gallery {
  @include skeleton;

  aspect-ratio: 1.75;
  isolation: isolate;
  display: flex;
  border-radius: 4rem;
  overflow: hidden;
}

.actions {
  display: flex;
  flex-direction: row;

  .action {
    @include skeleton;

    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    margin-left: 1rem;
  }
}

.content {
  flex: 1;
  padding: 3rem 3rem 9.5rem;
}

.text {
  @include skeleton;

  height: 1.5rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &:nth-of-type(2) {
    margin-top: 2.5rem;
    width: 93%;
  }

  &:nth-of-type(3) {
    width: 82%;
  }

  &:nth-of-type(4) {
    width: 87%;
  }

  &:nth-of-type(5) {
    width: 71%;
  }

  &:nth-of-type(6) {
    width: 75%;
  }

  &:nth-of-type(7) {
    width: 59%;
  }
}

.metrics {
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  padding: 2rem;

  .metric {
    @include skeleton;

    height: 3rem;
    width: 8.125rem;
  }
}

.item {
  width: 33.3%;
}

@include tablet {
  .item {
    width: 43rem;
  }
}

@include mobile {
  .item {
    width: 36rem;
  }

  .content {
    padding-bottom: 3rem;
  }

  .metrics,
  .divider {
    display: none;
  }
}
