@import 'styles/variables';

.root {
  width: 36rem;
  background: var(--base-5);
  padding: 3rem 3rem 8rem;
  border-radius: 4rem;
}

.avatar {
  margin: 3rem auto 2rem;
}

.title {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  .ellipsis {
    @include text-ellipsis;

    margin-right: 1rem;
  }
}

.link {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem !important;
}

.additional {
  display: flex;
  column-gap: 0.25rem;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.members {
  display: flex;
  column-gap: 0.75rem;
  padding: 0.625rem 1rem;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--font-secondary-color);
  }
}

@include desktop {
  .root {
    &:hover {
      cursor: pointer;
      background: var(--surface);
      box-shadow:
        0 0 0 0 rgb(35 41 59 / 8%),
        0 4px 9px 0 rgb(35 41 59 / 8%),
        0 17px 17px 0 rgb(35 41 59 / 7%),
        0 39px 23px 0 rgb(35 41 59 / 4%),
        0 69px 28px 0 rgb(35 41 59 / 1%),
        0 108px 30px 0 rgb(35 41 59 / 0%);
    }
  }
}
