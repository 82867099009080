@import 'styles/variables';

.root {
  padding: 1rem !important;
}

@include mobile-tablet {
  .root {
    padding: 0 !important;
    background: transparent !important;
  }
}
