@import 'styles/variables';

.modal {
  padding-block: 7.25rem;
}

.root {
  position: relative;
  height: 100%;
  max-width: 63rem;
  margin-inline: auto;
  outline: 0;
}

.paper {
  height: max-content;
}

.close {
  position: absolute;
  top: 0;
  left: calc(100% + 1rem);
}

.info {
  padding: 6rem 6rem 2rem;
}

.description {
  padding-block: 1.5rem 4rem;
}

.reset {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  padding-block: 0.5rem;
  margin-left: 6rem;
}

.controls {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  margin: 0 1rem 1rem;

  :global(.MuiButton-root) {
    flex-basis: 100%;
  }
}

@include mobile {
  .root {
    max-width: unset;
  }

  .info {
    padding: 4rem;
  }

  .title {
    margin-left: 4rem;
  }

  .close {
    right: 2rem;
    bottom: calc(100% + 1rem);
    width: 6rem;
    height: 6rem;
    top: auto;
    left: auto;
  }

  .controls {
    flex-direction: column-reverse;
    row-gap: 1rem;
  }
}
