@import 'styles/variables';

.type {
  grid-area: type;
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  flex-direction: row;

  .icon {
    margin: 4.5rem;
    width: 3rem;
    height: 3rem;
    fill: var(--base-50);
    border-radius: 100%;
    transition: all 0.3s ease-in-out;
  }
}

.root {
  background: var(--surface);
  border-radius: 0;
  display: grid;
  grid-template-columns: 29rem 1fr repeat(2, 19rem) 26rem;
  grid-template-areas: 'type channel views members concurrent';
  transition: all 0.3s ease-in-out;
  border-top: 1px solid var(--base-10);

  &:last-of-type {
    border-bottom-left-radius: 4rem;
    border-bottom-right-radius: 4rem;
  }

  &:hover {
    cursor: pointer;

    .type .icon {
      background: var(--base-5);
      box-shadow: 0 0 0 2.5rem var(--base-5);
    }
  }
}

.divider {
  height: 12rem;
  width: 0;
  border-left: 1px solid var(--surface);
  border-right: 1px solid var(--base-10);

  &Horizontal {
    grid-area: horizontal;
    display: none;
  }
}

.date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-inline: 3rem 2rem;
}

.channel {
  grid-area: channel;
  display: grid;
  grid-template-columns: auto 18rem auto;
  grid-template-rows: repeat(2, 3rem);
  grid-template-areas:
    'avatar title link'
    'avatar category .';
  align-items: center;
  align-self: center;
  justify-content: start;

  .avatar {
    grid-area: avatar;
    margin-right: 2rem;
  }

  .title {
    @include text-ellipsis;

    grid-area: title;
  }

  .link {
    grid-area: link;
    padding: 0.5rem;
    width: 3rem;
    height: 3rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .category {
    grid-area: category;
  }
}

.views,
.members {
  padding-left: 2rem;
  align-self: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 3rem);
}

.views {
  grid-area: views;
}

.members {
  grid-area: members;

  &Metric {
    display: grid;
    grid-template-rows: repeat(2, 3rem);
    grid-template-columns: 1fr;
    align-items: center;
    align-self: center;
    margin-block: auto;
  }
}

.concurrent {
  grid-area: concurrent;
  display: grid;
  align-items: center;
}

.metricTitle {
  display: none;
}

@include tablet {
  .root {
    grid-template-rows: 12rem auto 12rem;
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-template-areas:
      'type channel channel'
      'horizontal horizontal horizontal'
      'views members concurrent';
  }

  .dividerHorizontal {
    display: block;
  }

  .date {
    padding-left: 3rem;
  }

  .metricTitle {
    display: unset;
  }

  .views {
    padding-left: 4rem;
  }

  .channel {
    margin-inline: 2rem 3rem;
    grid-template-columns: repeat(3, auto);
  }

  .members {
    &Metric {
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
    }
  }

  .concurrent {
    display: grid;
    grid-template-rows: repeat(2, 3rem);
    align-self: center;
  }
}

@include mobile {
  .root {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'type type'
      'horizontal horizontal'
      'channel channel'
      'views members'
      'concurrent concurrent';
  }

  .channel {
    margin: 3rem 3rem 0;
    grid-template-columns: repeat(3, auto);
  }

  .type {
    .icon {
      margin: 2.75rem;
    }
  }

  .views {
    margin: 3rem 0 0 3rem;
  }

  .members {
    margin: 3rem 3rem 0 0;

    &Metric {
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
    }
  }

  .views,
  .members {
    padding-left: 0;
  }

  .concurrent {
    padding-left: 0;
    margin: 3rem;
  }

  .metricTitle {
    display: unset;
  }

  .divider {
    height: 100%;

    &Horizontal {
      display: block;
    }
  }
}
