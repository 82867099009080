@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 17rem;
  height: 17rem;
  border-radius: 3rem;
  padding: 2rem 2rem 2.25rem;
}

.avatar {
  @include skeleton;

  width: 6rem;
  height: 6rem;
  border-radius: 100%;
}

.title {
  @include skeleton;

  width: 90%;
  height: 2rem;
  display: block;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.tag {
  @include skeleton;

  width: 50%;
  height: 1.5rem;
}
