@import 'styles/variables';

.root {
  @include max-content-width;

  padding: 3rem;
  padding-top: 5rem;
}

.logo {
  z-index: 2;
  position: absolute;
  top: -5rem;
  left: 50%;
  transform: translateX(-50%);
  min-width: 9.5rem;
  min-height: 9.5rem;
  flex-shrink: 0;
}

.card {
  position: relative;
  width: 100%;
  border-radius: 5rem;
  padding: 4rem;
  background-color: var(--brand);
  height: 56rem;
  display: grid;
  place-content: center;
}

.card::before {
  content: '';
  position: absolute;
  border-radius: 5rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('/assets/apps-action-card-bg.png') no-repeat center center;
  background-size: cover;
  opacity: 0.1;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  max-width: 62.5rem;
  text-align: center;
  position: relative;
  z-index: 2;
}

.title {
  background: linear-gradient(
    to right,
    var(--surface),
    var(--surface),
    var(--surface),
    rgb(231 234 242 / 90%),
    rgb(231 234 242 / 60%)
  ) !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
}

.buttons {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
