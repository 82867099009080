@import 'styles/variables';

.root {
  grid-area: categories;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 4rem 2rem;
}

.headline {
  margin-bottom: 2.5rem;
}

.sizer {
  aspect-ratio: 1;
  position: relative;
}

.info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  z-index: -1;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 1rem;
  justify-content: center;

  .title {
    @include text-ellipsis;

    padding-inline: 25px;
  }

  .value {
    white-space: nowrap;
  }
}

@include tablet {
  .root {
    padding-block: 3.5rem 7rem;
  }
}
