@import 'styles/variables';

.root {
  display: inline-flex;
  flex-direction: column;
}

.grid {
  line-height: 1;
  align-items: center;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 1.5rem;
  grid-template-areas:
    'absolute change'
    'absolute period';
}

.absolute {
  grid-area: absolute;
}

.change {
  grid-area: change;
}

.period {
  grid-area: period;
}

.placeholder {
  @include skeleton;

  height: 6rem;
  width: 30rem;
}
