@import 'styles/variables';

.root {
  @include max-content-width;
  @include content-indents;

  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 3rem;
  justify-content: center;
  margin-block: 13rem;
}

.description {
  max-width: 47rem;
  margin-inline: auto;
}

@include mobile {
  .root {
    margin-block: 8rem 0;
  }
}
