@import 'styles/variables';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 3rem 3rem 4rem;
}

.headline {
  padding: 0;
}

.actions {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
}

.button {
  align-self: center;
}

.customPanel {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.5rem 0 2rem;
}

@include mobile {
  .root {
    padding: 2rem;
  }

  .buttonHideOnMobile {
    display: none;
  }
}
