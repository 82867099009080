@import 'styles/variables';

.records {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.record {
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;

  &:first-of-type {
    padding-top: 4rem;
  }

  &:last-of-type {
    padding-bottom: 4rem;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--paper-inner-border);
  }
}

.header {
  display: flex;
  flex-direction: row;
}

.value {
  margin: 1.5rem 0 1rem;
}

.showMore {
  display: none;
  border-top: 1px solid var(--stats-inner-border);
}

@include tablet {
  .records {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .record {
    padding: 3rem;

    &:nth-child(even) {
      border-left: 1px solid var(--paper-inner-border);
      padding-right: 4rem;
    }

    &:nth-child(odd) {
      padding-left: 4rem;
    }

    &:nth-child(5) {
      border-bottom: none;
    }

    &:nth-child(1),
    &:nth-child(2) {
      padding-top: 4rem;
    }

    &:nth-child(5),
    &:nth-child(6) {
      padding-bottom: 4rem;
    }
  }
}

@include mobile {
  .record {
    padding: 3rem !important;
  }

  .showMore {
    display: flex;
    padding: 1rem 3rem;
    flex-direction: column;
  }
}
