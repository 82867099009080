@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
}

.collapsible {
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 30rem;
}

.isOpen {
  margin-bottom: 1rem;
  border-radius: 4rem;
  box-shadow:
    0 0 0 0 rgb(35 41 59 / 8%),
    0 4px 9px 0 rgb(35 41 59 / 8%),
    0 17px 17px 0 rgb(35 41 59 / 7%),
    0 39px 23px 0 rgb(35 41 59 / 4%),
    0 69px 28px 0 rgb(35 41 59 / 1%),
    0 108px 30px 0 rgb(35 41 59 / 0%);

  .card {
    background: var(--surface);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.pagination {
  margin: 4rem;
}

@include mobile-tablet {
  .isOpen {
    border-radius: 0;
    box-shadow: none;

    .card {
      background: var(--base-5);
      border-radius: 4rem;
    }
  }
}
