@import 'styles/variables';

.item {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}

.icon {
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
}

.placeholder {
  .icon {
    @include skeleton;
  }

  .title {
    @include skeleton;

    width: 10rem;
    height: 2rem;
  }
}

.title {
  white-space: nowrap;
}
