@import 'styles/variables';

.root {
  grid-area: actions;
  display: flex;
  padding: 1rem;
  width: 100%;
  column-gap: 1rem;
  margin-bottom: 1rem;
  height: 9rem;

  svg {
    flex-shrink: 0;
  }
}

.join {
  text-align: center;
  overflow: hidden;
  display: flex;
  flex: 1;
  padding: 0;
}

.open {
  flex-shrink: 0;
}

@include mobile-tablet {
  .root {
    margin-bottom: 3rem;
  }

  .join {
    padding: 0 4rem;
  }
}
