@import 'styles/variables';

.root {
  position: relative;
}

.list {
  position: absolute;
  top: 110%;
  right: 0;
  width: auto;
  background-color: var(--surface);
  border-radius: 2rem;
  padding: 1rem;
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
  backdrop-filter: blur(20px);
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include mobile {
    top: 100%;
    right: 0;
    width: 100%;
  }
}

.item {
  align-items: center !important;
  justify-content: flex-start !important;
  gap: 1rem !important;
  padding-left: 1rem !important;
  text-wrap: nowrap !important;
}
