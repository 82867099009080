@import 'styles/variables';

.root {
  margin-top: 3rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--base-10);
    padding-bottom: 3rem;
  }
}

.locale {
  @include skeleton;

  margin-top: 1rem;
  width: 15rem;
  height: 2rem;
}
