@import 'styles/variables';

// stylelint-disable
.root {
  height: 100%;
  width: 100%;
  position: relative;

  :global {
    .slick-slider {
      position: unset;
      height: 100%;
      width: 100%;
    }

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-slide > div {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .slick-arrow {
      position: absolute;
      bottom: 3rem;
      left: unset;
      top: unset;
      transform: unset;
      aspect-ratio: 1;
      width: 4rem;
      height: 4rem;
      z-index: 2500;
      display: flex !important;

      &::before {
        display: none;
      }

      &:focus {
        background: var(--button-light-background);
      }
    }

    .slick-prev {
      right: 7.5rem;
    }

    .slick-next {
      right: 3rem;
    }

    .slick-dots {
      display: flex !important;
      width: auto;
      flex-direction: row;
      position: absolute;
      bottom: 2.5rem;
      left: 50%;
      transform: translateX(-50%);
      column-gap: 0.25rem;

      li {
        width: 5rem;
        height: 5rem;
        margin: 0;

        &:not(.slick-active) {
          opacity: 0.5;
        }
      }

      :local {
        li {
          &:first-of-type div {
            border-bottom-left-radius: 3rem;
            border-top-left-radius: 3rem;
          }

          &:last-of-type div {
            border-bottom-right-radius: 3rem;
            border-top-right-radius: 3rem;
          }
        }

        .previewImage {
          position: relative;
          width: 5rem;
          height: 5rem;
          overflow: hidden;
          background: var(--white);

          > .border {
            border: 1px solid rgb(255 255 255 / 40%);
            @include absolute-full-size;
          }
        }
      }
    }
  }
}

// stylelint-enable

.item {
  height: 100%;
  width: 100%;
  object-fit: contain !important;
  z-index: 3;

  &BG {
    z-index: 1;
    filter: brightness(0.3);
    object-fit: fill !important;

    &.video {
      z-index: -1;
    }
  }
}

.counter {
  position: absolute;
  background: var(--gallery-counter-background);
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  left: 3rem;
  bottom: 3rem;
}

.videoControls {
  position: absolute;
  height: 4rem;
  width: calc(100% - 6rem);
  bottom: 3rem;
  left: 3rem;
}

.videoReset {
  position: absolute;
  width: 4rem;
  height: 4rem;
  top: 3rem;
  right: 3rem;
}

.error {
  height: max-content;
  background: none;
}
