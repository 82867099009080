@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: repeat(5, auto) 1fr repeat(2, auto);
  grid-column-gap: 1rem;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 4rem;
  margin-bottom: 1rem;
}

.time {
  @include skeleton;

  width: 4.5rem;
  height: 2rem;
}

.divider {
  display: inline-flex;
  align-self: center;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: var(--date-time-divider-color);
}

.button {
  @include skeleton;

  width: 12rem;
  height: 3.05rem;
}

.text {
  @include skeleton;

  width: 7rem;
  height: 2rem;
}

.channel {
  @extend .button;

  width: 15rem;
}

.line {
  height: 0;
  width: 100%;
  border-top: 1px solid var(--base-10);
}

.members,
.trend {
  @extend .text;
}
