@import 'styles/variables';
@import 'styles/fonts';

.card {
  border-radius: 4rem;
  background-color: var(--base-5);
  padding: 2rem;
  display: grid;
  grid-template-rows: repeat(2, auto);
  place-content: stretch;
  gap: 4rem;
  position: relative;
  transition: all 0.3s ease-in-out;

  @include mobile-tablet {
    background-color: var(--surface);
  }

  &:hover {
    cursor: pointer;
    background: var(--base-5);
    box-shadow:
      0 0 0 0 rgb(35 41 59 / 8%),
      0 4px 9px 0 rgb(35 41 59 / 8%),
      0 17px 17px 0 rgb(35 41 59 / 7%),
      0 39px 23px 0 rgb(35 41 59 / 4%),
      0 69px 28px 0 rgb(35 41 59 / 1%),
      0 108px 30px 0 rgb(35 41 59 / 0%);
  }
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  max-width: 60%;
}

.items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: -1rem;
}

.iconBox {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  fill: var(--base-50) !important;

  path {
    stroke: var(--base-50) !important;
    fill: var(--base-50) !important;
  }
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  fill: var(--base) !important;
  path: {
    stroke: var(--base) !important;
    fill: var(--base) !important;
  }
}

.item {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 100%;
  background-color: var(--surface);
  border: 1px solid var(--surface);
  margin-left: -2rem;

  &:first-child {
    margin-left: 0;
  }
}

.logo {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 100%;
  object-fit: cover;
  aspect-ratio: 1;
  object-position: center;
}

.total {
  @include suisse-font;

  width: 6.25rem;
  height: 6.25rem;
  text-align: center;
  font-style: 1.75rem;
  font-weight: 450;
  line-height: 2.5rem;
  letter-spacing: -0.336px;
}
