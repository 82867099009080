@import 'styles/variables';

.root {
  padding: 1rem;
  width: 100%;
  max-width: 85rem;
  margin-inline: auto;
}

.textInput {
  border-radius: 2rem !important;
  padding: 3.5rem 3rem 2rem !important;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
}

.fields {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  margin: 5rem 5rem 6rem;
}

.fieldset {
  border: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
}

@include mobile-tablet {
  .root {
    max-width: unset;
  }
}

@include mobile {
  .fields {
    margin: 3rem 3rem 4rem;
  }
}
