@import 'styles/variables';

.root {
  @include max-content-width;

  max-width: 129rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'title controls' 'list list';
  padding: 0 3.5rem;
}

.title {
  grid-area: title;
  display: flex;
  align-items: flex-start;
  align-self: center;
  column-gap: 1rem;
}

.controls {
  grid-area: controls;
  justify-self: end;
  display: flex;
  column-gap: 0.5rem;
}

.paper {
  grid-area: list;
  max-width: 129rem;
  width: 100%;
  margin: 5rem auto 0;

  &.gradiented {
    @include vertical-gradient;
  }
}

.item {
  margin: 1rem;

  &:first-of-type {
    margin-left: 0;
    padding-left: 1rem;
  }

  &:not(:last-of-type) {
    margin-right: 0;
  }
}

@include desktop {
  .title {
    .ellipsis {
      @include text-ellipsis;
    }
  }
}

@include mobile-tablet {
  .root {
    max-width: 100%;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas: 'title' 'list' 'controls';
  }

  .controls {
    margin-top: 1rem;
    justify-self: start;
    width: 100%;

    .slider {
      display: none;
    }

    :global(.MuiButtonBase-root) {
      width: 100%;
    }
  }
}

@include mobile {
  .root {
    padding-inline: 1.5rem;
  }

  .paper {
    margin-top: 4rem;
  }
}
