.root {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.document {
  display: flex;
  flex-direction: row;
  align-items: center;

  .name {
    margin: 0 1rem 0 1.5rem;
  }

  .icon {
    transition: box-shadow 0.3s ease-in-out;
    background: var(--button-secondary-background);
    box-shadow: 0 0 0 0 var(--button-secondary-background);
    padding: 1rem;
    border-radius: 100%;
    aspect-ratio: 1;
    display: inline-flex;

    svg {
      width: 2rem;
      height: 2rem;
      fill: var(--button-secondary-foreground);
    }
  }

  .tag {
    transition: background 0.3s ease-in-out;
  }

  &:hover {
    .icon {
      box-shadow: 0 0 0 0.5rem var(--button-secondary-background);
    }

    .tag {
      border: 1px solid transparent;
      background: var(--tags-border);
      box-shadow: 0 0 0 1px var(--tags-border);
    }
  }
}
