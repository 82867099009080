@import 'styles/variables';
@import 'styles/fonts';

@mixin selected {
  background-color: var(--base-100);
  color: var(--surface);

  svg {
    fill: var(--surface);
  }
}

/* stylelint-disable */
.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  list-style: none;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;

  li {
    display: flex;

    a {
      @include headline-10bk;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 100%;
      height: 4rem;
      aspect-ratio: 1;
      color: var(--font-secondary-color);

      svg {
        width: 2.5rem;
        height: 2.5rem;
        fill: var(--font-secondary-color);
      }

      &:hover {
        background-color: var(--button-text-background-hover);
        color: var(--button-text-foreground);

        svg {
          fill: var(--button-text-foreground);
        }
      }
    }
  }

  .active {
    a {
      @include selected;

      &:hover {
        @include selected;
      }
    }
  }

  .prev {
    margin-right: 2rem;
  }

  .next {
    margin-left: 2rem;
  }

  .prev,
  .next {
    &:global(.disabled) {
      a {
        cursor: not-allowed;
        color: var(--base-20);
        background-color: unset;

        svg {
          fill: var(--base-20);
        }
      }
    }
  }
}

/* stylelint-enable */

@include mobile {
  .root {
    flex-direction: column;
    justify-content: center;
    row-gap: 3rem;
  }
}
