@import 'styles/variables';

.container {
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.row {
  display: grid;
  position: relative;
  grid-template-columns: 9rem 31rem 1fr;
  padding: 2rem 1.5rem 2rem 1rem;
  background-color: var(--base-5);
  border-radius: 4rem;
  transition: all 0.3s ease-in-out;

  &.withActions {
    grid-template-columns: 9rem 31rem 1fr 8rem;
  }

  &[data-metrics='2'] {
    grid-template-columns: 9rem 31rem repeat(2, 1fr);

    &.withActions {
      grid-template-columns: 9rem 31rem repeat(2, 1fr) 8rem;
    }
  }

  &[data-metrics='3'] {
    grid-template-columns: 9rem 31rem repeat(3, 1fr);

    &.withActions {
      grid-template-columns: 9rem 31rem repeat(3, 1fr) 8rem;
    }
  }

  &[data-metrics='4'] {
    grid-template-columns: 9rem 31rem repeat(4, 1fr);

    &.withActions {
      grid-template-columns: 9rem 31rem repeat(4, 1fr) 8rem;
    }
  }

  &[data-metrics='5'] {
    grid-template-columns: 9rem 31rem repeat(5, 1fr);

    &.withActions {
      grid-template-columns: 9rem 31rem repeat(5, 1fr) 8rem;
    }
  }
}

.actions {
  width: 8rem;
  position: absolute;
  right: 1.5rem;
  top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.metric {
  padding: 1rem 0 1rem 2rem;
  align-self: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 3rem);

  .percentage {
    margin-left: 1rem;
  }

  & > *:nth-child(2) {
    display: flex;
  }

  &.withHover {
    &:hover {
      cursor: pointer;
      border-radius: 1.5rem;
      background: var(--base-5);
    }
  }
}

.channel {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, 3rem);
  grid-template-areas:
    'avatar title link'
    'avatar category .';
  align-items: center;
  align-self: center;
  justify-content: start;

  .avatar {
    grid-area: avatar;
    margin-right: 2rem;
    padding: 0 !important;
  }

  .title {
    @include text-ellipsis;

    grid-area: title;

    :global(.MuiTypography-root) {
      line-height: 0.9;
    }
  }

  .link {
    grid-area: link;
    padding: 0.5rem !important;
    margin-left: 1rem;
    width: 3rem;
    height: 3rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .category {
    grid-area: category;
    display: flex;
    column-gap: 0.25rem;
  }

  .confirmed {
    display: flex;
    border-radius: 100%;
    background-color: var(--brand);
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: white;
    }
  }
}

@include mobile-tablet {
  .channel {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 3rem;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0;
      border-top: 1px solid var(--base-10);
      border-bottom: 1px solid white;
    }
  }

  .row {
    padding-top: 13.5rem;
    grid-template-columns: 9rem 18.5rem;
    overflow-x: auto;

    &[data-metrics='2'] {
      grid-template-columns: 9rem repeat(2, 18.5rem);
    }

    &[data-metrics='3'] {
      grid-template-columns: 9rem repeat(3, 18.5rem);

      .channel {
        width: 67rem;
      }
    }

    &[data-metrics='4'] {
      grid-template-columns: 9rem repeat(4, 18.5rem);

      .channel {
        width: 85.5rem;
      }
    }

    &[data-metrics='5'] {
      grid-template-columns: 9rem repeat(5, 18.5rem);

      .channel {
        width: 104rem;
      }
    }
  }
}

@include desktop {
  .row {
    &:hover {
      cursor: pointer;
      background: var(--surface);
      box-shadow:
        0 0 0 0 rgb(35 41 59 / 8%),
        0 4px 9px 0 rgb(35 41 59 / 8%),
        0 17px 17px 0 rgb(35 41 59 / 7%),
        0 39px 23px 0 rgb(35 41 59 / 4%),
        0 69px 28px 0 rgb(35 41 59 / 1%),
        0 108px 30px 0 rgb(35 41 59 / 0%);
    }
  }
}
