@import 'styles/variables';

.title {
  @include max-content-width;

  margin-inline: auto;
  padding-inline: 3.5rem;
  margin-block: 13rem 4rem;
}

.layout {
  @include max-content-width;
  @include content-indents;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  align-items: start;
}

.country {
  color: var(--font-primary-color);
}

@include mobile-tablet {
  .layout {
    overflow: visible;
    grid-template-columns: 1fr;
  }
}

@include mobile {
  .title {
    margin-block: 8rem 0;
    padding-inline: 1.5rem;
  }

  .divider {
    display: none;
  }
}
