@import 'styles/variables';

.button {
  column-gap: 1rem;

  &.selected {
    padding-block: 0.5rem;
  }
}

.title {
  max-width: 15rem;

  @include text-ellipsis;
}

.counter {
  padding: 0.375rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--base-20);
  color: var(--base-50);
}

.popover {
  width: 41rem;
  padding: 3rem;
  border-radius: 3rem;
  background-color: var(--growth-tooltip-background);
  border: 1px solid var(--growth-tooltip-border);
  box-shadow:
    0 84px 24px 0 rgb(0 0 0 / 0%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 0 0 0 rgb(0 0 0 / 3%);
  backdrop-filter: blur(40px);
}

.input {
  padding-left: 4.5rem !important;

  &Root {
    margin-bottom: 2rem;
  }
}
