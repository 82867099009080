@import 'styles/variables';

.root {
  @include max-content-width;
  @include content-indents;

  margin-top: 3rem;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3rem;
}

.withNavigation {
  display: none;
}

.column {
  flex-basis: 50%;
}

@include mobile-tablet {
  .container {
    display: none;
  }

  .withNavigation {
    display: unset;
  }
}
