.slider {
  width: calc(100% - 2rem);
  padding-block: 0;
  height: 2rem;
}

.track {
  height: 0.25rem;
  background: var(--brand);
  border: none;
}

.thumb {
  background-color: var(--white);
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid var(--brand);
  transition: border 0.1s ease-in-out;

  &::after,
  &::before {
    box-shadow: none;
  }

  &:hover {
    box-shadow: none;
    border-width: 0.375rem;
  }

  &:global(.MuiSlider-thumb.Mui-active) {
    border-width: 0.5rem;
  }

  &:global(.MuiSlider-thumb.Mui-focusVisible) {
    box-shadow: none !important;
  }
}

.rail {
  height: 2px;
  background: var(--base-10);
}

.disabled {
  pointer-events: unset !important;
  cursor: not-allowed !important;

  .track {
    background: var(--base-10);
  }

  .thumb {
    border: 0.25rem solid var(--base-10);
  }
}
