$volume-slider-height: 12rem;

.root {
  display: flex;
  flex-direction: row;
  column-gap: 1.5rem;
}

// stylelint-disable no-descending-specificity
.volume {
  max-height: 4rem;
  position: relative;

  &Slider {
    position: absolute;
    height: #{$volume-slider-height};
    max-height: 0;
    bottom: calc(100% + 2rem);
    overflow: hidden;
    padding: 0.75rem 0;

    .slider {
      display: none;
    }

    .track {
      border: 2px solid var(--white);
    }

    .thumb {
      background-color: var(--white);
      width: 1.5rem;
      height: 1.5rem;

      &:hover {
        box-shadow: none;
      }

      &:global(.Mui-active) {
        box-shadow: none;
      }
    }

    .rail {
      background-color: var(--video-rail-color);
      backdrop-filter: blur(2.5rem);
      opacity: 0.2;
    }

    &.shown {
      max-height: #{$volume-slider-height};

      .slider {
        display: block;
      }
    }
  }
}

.seekSlider {
  display: flex;
  flex: 1;
  padding: 0 0.25rem;

  .track {
    border: none;
    background-color: var(--white);
  }

  .thumb {
    background-color: var(--white);
    width: 0.5rem;
    height: 1.5rem;
    border-radius: 4px;

    &:hover {
      box-shadow: none;
    }

    &::after {
      display: none;
    }

    &:global(.Mui-active) {
      box-shadow: none;
    }
  }

  .rail {
    background-color: var(--video-rail-color);
    backdrop-filter: blur(2.5rem);
    opacity: 0.2;
  }
}
// stylelint-enable
