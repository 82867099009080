.root {
  padding: 4rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 0.25rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}
