@import 'styles/fonts';
@import 'styles/variables';

.modal {
  overflow: auto;
}

.modalWrapper {
  position: relative;
  margin: 10.5rem auto;
  width: 100%;
  max-width: 63rem;
}

.root {
  padding: 1rem;
}

.title {
  padding: 5rem 5rem 0;
}

.update {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2rem;
  margin: 4rem 2rem;
  padding: 3rem;
  border-radius: 4rem;
  border: 1px solid var(--base-10);
}

.fieldset {
  border: none;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-inline: 5rem;

  &:not(:last-of-type) {
    margin-bottom: 4rem;
  }
}

.legend {
  margin-bottom: 1rem;
  padding-block: 0.5rem;
}

.dropdown {
  top: calc(50% - 2.5rem);
}

.arrowDown {
  width: 2.5rem;
  height: 2.5rem;
  fill: var(--base-50);
}

.list {
  backdrop-filter: blur(20px);
  background: var(--growth-tooltip-background);
  border: 1px solid var(--growth-border-color);
  border-radius: 2rem;
  min-width: 28rem;
  padding: 1rem;
  width: 30rem;
}

.label {
  @include headline-10bk;

  color: var(--font-secondary-color);
  transition: all 0.1s ease-in-out;
  margin-left: 1rem;
}

.checkbox {
  width: 100%;
  margin-inline: 0;
  padding: 0.5rem 2rem 0.5rem 1rem;
  border-radius: 1rem;
  transition: all 0.3s ease-in-out;

  &.checked {
    .label {
      color: var(--brand);
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &:hover {
    background-color: var(--base-5);
  }
}

.textInput {
  border-radius: 2rem !important;
  padding: 3.5rem 3rem 2rem !important;
}

.addButton {
  &:global(.MuiButton-root) {
    width: 100%;
    border-radius: 2rem;
    padding: 2.75rem 3rem;
    border: 1px dashed var(--base-20);

    @include headline-9bk;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.controls {
  display: flex;
  margin-top: 6rem;
  column-gap: 1rem;

  :global(.MuiButton-root) {
    flex-basis: 50%;
  }
}

.close {
  position: absolute;
  left: calc(100% + 1rem);
  top: 0;
}

@include tablet {
  .modalWrapper {
    max-width: calc(100% - 33rem);
    margin-top: 20.25rem;
  }
}

@include mobile {
  .modalWrapper {
    max-width: 100%;
  }

  .close {
    left: auto;
    right: 2rem;
    top: auto;
    bottom: calc(100% + 1rem);
  }
}
