@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  aspect-ratio: 1.5;
  cursor: pointer;
}

.resizer {
  display: flex;
  flex: 1;
}

.city {
  &:hover {
    stroke-width: 0.5rem;
  }
}

.region {
  stroke-width: 1px;
  stroke: var(--background);

  &:hover {
    stroke-width: 3px;
  }
}
