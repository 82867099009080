@import 'styles/variables';

.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  @include tablet {
    width: 53rem;
    margin: 0 1.5rem;
  }
}

.image {
  padding: 1rem 0 1rem 1rem;
  line-height: 0;
  align-items: center;
  display: flex;

  &Channel {
    padding: 3rem 0 3rem 3rem;
  }

  &News {
    border-radius: 4rem;
    width: 16rem;
    height: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 3rem 4rem;
}

.maxLines2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.action {
  align-self: start;
}

.footer {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.adIcon {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  bottom: 2.5rem;
  right: 2.5rem;
  fill: var(--ad-icon);
}
