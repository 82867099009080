@import 'styles/variables';

.root {
  @include max-content-width;

  max-width: 129rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 6rem;
  padding-top: 13rem;
}

.map {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-row-gap: 7.5rem;
}

.country {
  color: var(--brand);
}

.title {
  width: 57rem;
}

.divider {
  margin-block: 10rem 12rem;
}

.tooltip {
  transform: translateY(-100%);
  padding: 3rem;
  min-width: unset;
  width: auto;

  .grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 2rem;
  }
}

@include mobile-tablet {
  .root {
    @include content-indents;

    margin-inline: auto;
    grid-template-columns: 1fr;
    grid-row-gap: 8rem;
  }
}

@include tablet {
  .root {
    grid-row-gap: 8rem;
  }

  .map {
    padding-inline: 8rem;
    justify-content: center;
    text-align: center;
  }

  .title {
    justify-self: center;
  }
}

@include mobile {
  .root {
    grid-row-gap: 7.25rem;
    padding-top: 8rem;
  }

  .title {
    width: 40rem;
  }

  .divider {
    opacity: 0;
    margin-block: 3rem 8rem;
  }
}
