@import 'styles/variables';

.root {
  width: 44rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.paper {
  padding: 3rem 4rem 4rem;
}

.title {
  @include skeleton;

  width: 18rem;
  height: 3rem;
  margin-bottom: 1rem;
}

.inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.input {
  @include skeleton;

  flex-basis: 40%;
  height: 4rem;
}

.additional {
  @include skeleton;

  width: 100%;
  height: 2rem;
}

.reset {
  padding: 1rem;
}

.button {
  @include skeleton;

  height: 8rem;
  border-radius: 4rem;
}
