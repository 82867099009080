@import 'styles/variables';

.root {
  aspect-ratio: 1.5;
  display: grid;
  grid-template-columns: 87% 13%;
  grid-template-rows: 87% 13%;
  grid-template-areas:
    'chart axisY'
    'axisX .';
}

.chart {
  grid-area: chart;

  svg {
    width: 100%;
    height: 100%;
  }
}

.axisX {
  @include skeleton;

  align-self: end;
  height: 1.5rem;
  width: 100%;
}

.axisY {
  @include skeleton;

  justify-self: end;
  width: 3rem;
}

.brush {
  @include skeleton;

  height: 6rem;
  width: 100%;
  margin-top: 3rem;
}
