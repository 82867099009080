@import 'styles/variables';

/* stylelint-disable */
.root {
  position: relative;
  width: max-content;
  margin: 0;
  padding: 3rem 0 0;
  background: var(--paper-background);
  border: 1px solid var(--paper-inner-border);
  border-radius: 3rem;

  &:global(.rdp) {
    --rdp-cell-size: 4rem;
    --rdp-background-color: var(--button-secondary-background);

    margin: 0;
  }

  :global {
    .rdp-table {
      margin: 2rem 3rem 3rem;
    }

    .rdp-day {
      border-radius: 1rem;
      isolation: isolate;
    }

    .rdp-day_selected {
      &,
      &:focus-visible,
      &:hover {
        background:
          linear-gradient(var(--button-primary-background), var(--button-primary-background))
            padding-box,
          linear-gradient(180deg, rgb(255 255 255 / 40%), rgb(255 255 255 / 0%)) border-box;
        background-color: var(--button-primary-background);
        border: 1px solid transparent;

        .MuiTypography-root {
          color: var(--button-primary-foreground);
        }
      }
    }

    .rdp-day_range_middle {
      border-radius: 0;
    }
  }
}
/* stylelint-enable */

.caption {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--paper-inner-border);
  padding: 0 3rem 2.5rem;

  .action {
    padding: 0.5rem;
  }
}

.weekend {
  //color: $ultra-red;
}
