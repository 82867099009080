@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 1px;
  background: var(--base-10);
  border-top: 1px solid var(--base-10);
  border-bottom: 1px solid var(--base-10);
  flex-wrap: wrap;
}

.link {
  margin: 1rem;
  width: calc(100% - 2rem);
}

@include tablet {
  .root {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@include mobile {
  .root {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
