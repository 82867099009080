@import 'styles/variables';

.stats {
  &Overview {
    grid-area: stats;
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.grid {
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(3, 1fr);
  grid-gap: 1px;
  background-color: var(--stats-inner-border);

  > * {
    background-color: var(--stats-foreground);
  }
}

.headline {
  @include skeleton;

  margin: 3.5rem 4rem 0.5rem;
  width: 22rem;
  height: 2rem;
}

.absolute {
  @include skeleton;

  margin: 2rem 4rem 0.5rem;
  width: 21rem;
  height: 5rem;
}

.metrics {
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  grid-row-gap: 12px;
}

.metricName {
  @include skeleton;

  width: 12rem;
  height: 1.5rem;
}

.metricValue {
  @include skeleton;

  width: 9rem;
  height: 1.5rem;
}

.metricChange {
  @include skeleton;

  width: 5rem;
  height: 1.5rem;
  margin-left: 1rem;
}

.containerWithGraph {
  position: relative;
}

.chart {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70%;
  opacity: 0.7;
}

.genderWithPosts {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 3rem;
  margin-top: 3rem;
}

.posts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &Chart {
    width: 21.5rem;
    height: 7rem;
  }

  .total {
    padding: 3.5rem 0 3.5rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &Title {
      @include skeleton;

      height: 2rem;
      width: 21rem;
      display: flex;
      line-height: 3rem;
      margin-bottom: 1rem;
    }

    &Value {
      @include skeleton;

      width: 9rem;
      height: 3rem;
    }
  }

  .dynamic {
    padding: 4rem 4rem 4rem 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    &Change {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      row-gap: 0.5rem;
      margin-right: 4rem;

      &Title {
        @include skeleton;

        width: 9rem;
        height: 1.5rem;
      }

      &Value {
        @include skeleton;

        width: 5rem;
        height: 1.5rem;
      }
    }
  }
}

.gender {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3.5rem;

  &Title {
    @include skeleton;

    width: 12rem;
    height: 3rem;
    margin-bottom: 1.5rem;
  }

  &Value {
    @include skeleton;

    width: 9rem;
    height: 4rem;
  }

  .female {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
