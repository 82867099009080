@import 'styles/variables';

.promotion {
  @include max-content-width;

  padding: 0 3.5rem 3rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 445px));
  grid-column-gap: 3rem;
  justify-content: center;
}
