.link {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
  width: 2.5rem !important;
  height: 2.5rem !important;
}
