:root {
  /**
    Colors
   */
  --bg: #f3f4f9;
  --new: #005ffe;
  --surface: #fff;
  --white: #fff;
  --base: #212a3b;
  --white-20: rgb(255 255 255 / 20%);
  --gray-90: rgb(255 255 255 / 90%);
  --gray-80: rgb(255 255 255 / 80%);
  --gray-60: rgb(33 42 59 / 60%);
  --gray-50: rgb(16 41 96 / 50%);
  --gray-40: rgb(255 255 255 / 40%);
  --gray-30: rgb(16 41 96 / 30%);
  --gray-20: rgb(16 41 96 / 20%);
  --gray-15: rgb(16 41 96 / 15%);
  --gray-10: rgb(16 41 96 / 10%);
  --gray-5: rgb(0 35 112 / 5%);
  --white-5: rgb(255 255 255 / 5%);
  --additional-success: #36c576;
  --additional-error: #e2543f;
  --blue: #4b70f6;
  --purple: #ad74df;
  --dark-blue: #111827;
  --max-content-width: 1440px;

  /**
   * NEW APPROACH
   */
  --brand: #005ffe;
  --white-60: rgb(255 255 255 / 60%);
  --white-40: rgb(255 255 255 / 40%);
  --base-100: #212a3b;
  --base-50: rgb(16 41 96 / 50%);
  --base-40: rgb(16 41 96 / 40%);
  --base-30: rgb(16 41 96 / 30%);
  --base-20: rgb(16 41 96 / 20%);
  --base-10: rgb(16 41 96 / 10%);
  --base-5: rgb(0 35 112 / 5%);
  --background: #f3f4f9;
  --success: #36c576;
  --error: #e2543f;

  /**
   * Saturation
   */
  --text-saturation-color: #000;
  --text-saturation: 10%;

  /**
    Buttons
   */
  --button-primary-background: var(--new);
  --button-primary-background-disabled: rgb(0 95 254 / 20%);
  --button-primary-foreground: var(--surface);
  --button-primary-foregraund-disabled: var(--surface);
  --button-secondary-background: var(--gray-5);
  --button-secondary-foreground: var(--gray-50);
  --button-text-foreground: var(--gray-50);
  --button-text-background-hover: var(--gray-5);
  --button-light-background: var(--white);
  --button-light-foreground: var(--base);
  --button-add-bot-background: var(--surface);

  /**
    Typography
   */
  --font-primary-color: var(--base);
  --font-secondary-color: var(--gray-50);
  --font-link-color: var(--new);
  --font-gray-color: var(--gray-40);
  --font-positive-color: var(--additional-success);
  --font-negative-color: var(--additional-error);

  /**
    Backgrounds
   */
  --primary-background: var(--bg);
  --secondary-background: var(--bg);
  --secondary-background-hover: var(--surface);
  --paper-background: var(--surface);
  --paper-background-secondary: var(--gray-5);
  --paper-inner-border: var(--gray-10);
  --backdrop-background: rgb(33 42 59 / 60%);

  /**
    Avatar
   */
  --user-avatar-border: var(--gray-10);
  --user-avatar-color: var(--gray-50);
  --user-avatar-hover-color: var(--base);

  /**
    Inputs
   */
  --input-background: var(--gray-5);
  --input-placeholder: var(--gray-50);
  --input-text: var(--base);
  --input-adornment-background: var(--gray-5);

  /**
    Navigation
   */
  --header-sticky-background: var(--gray-80);
  --navigation-link: var(--gray-50);
  --navigation-link-focus: var(--base);

  /**
    Tags
   */
  --tags-border: var(--gray-10);

  /**
    Stats
   */
  --stats-inner-border: var(--gray-10);
  --stats-foreground: var(--surface);

  /**
    Promotion
   */
  --ad-icon: var(--gray-30);

  /**
    Menu
   */
  --menu-dropdown-border: var(--gray-10);
  --menu-dropdown-background: rgb(255 255 255 / 90%);
  --menu-active-item-color: var(--new);
  --menu-dropdown-item-hover-foreground: var(--gray-50);
  --menu-dropdown-item-hover-background: var(--gray-5);

  /**
    Divider
   */
  --divider-color: var(--gray-10);
  --divider-secondary-color: var(--white);
  --date-time-divider-color: var(--gray-20);

  /**
    Footer
   */
  --footer-background: var(--dark-blue);
  --footer-headline-color: var(--gray-40);

  /**
   * Sticky navigation
   */
  --sticky-nav-border: var(--gray-10);
  --sticky-nav-background: var(--gray-80);
  --sticky-nav-foreground: var(--gray-50);

  /**
   * Skeleton
   */
  --skeleton-chart-color: rgb(16 41 96);
  --skeleton-animated-block-background: var(--gray-5);
  --skeleton-animated-block-foreground: var(--gray-15);

  /**
   * Posts
   */
  --deleted-date-time-background: var(--additional-error);
  --deleted-date-time-foreground: var(--white);

  /**
   * Metrics colors
   */
  /* stylelint-disable */
  --members: #6300ff;
  --citationIndex: #5bc094;
  --postsViewAvg: #eb914c;
  --adsPostsViewAvg: #eac874;
  --er: #4b70f6;
  --err: #81c5d7;
  --posts-background: #102960;
  /* stylelint-enable */

  /**
   * Growth Tooltip
   */
  --growth-tooltip-background: var(--gray-80);
  --growth-tooltip-border: var(--gray-10);

  /**
   * Audio
   */
  --audio-bar-color: var(--gray-20);
  --audio-progress-color: var(--new);

  /**
   * Dropdown
   */
  --dropdown-background: var(--gray-90);
  --dropdown-border: var(--gray-10);

  /**
   * Tooltip
   */
  --tooltip-text-color: var(--white);
  --tooltip-background: var(--gray-50);

  /**
   * Modal
   */
  --modal-backdrop: var(--gray-60);

  /**
   * Gallery
   */
  --gallery-counter-background: var(--gray-20);

  /**
   * Video
   */
  --video-rail-color: var(--gray-20);
}

[data-theme='dark'] {
  /**
    Colors
   */
  --bg: #0c1019;
  --new: #005ffe;
  --surface: #111827;
  --white: #fff;
  --white-40: rgb(255 255 255 / 40%);
  --white-20: rgb(255 255 255 / 20%);
  --white-15: rgb(255 255 255 / 15%);
  --white-10: rgb(255 255 255 / 10%);
  --white-5: rgb(255 255 255 / 5%);
  --white-3: rgb(255 255 255 / 3%);
  --additional-success: #36c576;
  --additional-error: #e2543f;

  /**
   * NEW APPROACH
   */
  --base-100: #fff;
  --base-50: rgb(255 255 255 / 40%);
  --base-40: rgb(255 255 255 / 30%);
  --base-30: rgb(255 255 255 / 20%);
  --base-20: rgb(255 255 255 / 10%);
  --base-10: rgb(255 255 255 / 5%);
  --base-5: rgb(255 255 255 / 3%);
  --background: #0c1019;

  /**
   * Saturation
   */
  --text-saturation-color: #fff;
  --text-saturation: 20%;

  /**
    Buttons
   */
  --button-primary-background: var(--new);
  --button-primary-foreground: var(--white);
  --button-secondary-background: var(--white-3);
  --button-secondary-foreground: var(--white-40);
  --button-text-foreground: var(--white-40);
  --button-add-bot-background: var(--white);

  /**
    Typography
   */
  --font-primary-color: var(--white);
  --font-secondary-color: var(--white-40);
  --font-gray-color: var(--white-40);
  --font-positive-color: var(--additional-success);
  --font-negative-color: var(--additional-error);

  /**
    Backgrounds
   */
  --primary-background: var(--bg);
  --secondary-background: var(--white-3);
  --secondary-background-hover: var(--surface);
  --paper-background: var(--surface);
  --paper-background-secondary: var(--gray-5);
  /*--backdrop-background: rgb(255 255 255 / 60%);*/

  /**
    Avatar
   */
  --user-avatar-border: var(--gray-10);
  --user-avatar-color: var(--white-40);
  --user-avatar-hover-color: var(--white);

  /**
    Inputs
   */
  --input-background: var(--white-3);
  --input-placeholder: var(--white-40);
  --input-text: var(--white);
  --input-adornment-background: var(--white-40);

  /**
    Navigation
   */
  --header-sticky-background: rgb(17 24 39 / 80%);
  --navigation-link: var(--white-40);
  --navigation-link-focus: var(--white);

  /**
    Tags
   */
  --tags-border: var(--white-5);

  /**
    Stats
   */
  --stats-inner-border: var(--white-5);
  --stats-foreground: var(--surface);

  /**
    Promotion
   */
  --ad-icon: var(--white-20);

  /**
    Menu
   */
  --menu-dropdown-border: var(--white-5);
  --menu-dropdown-background: rgb(17 24 39 / 40%);
  --menu-active-item-color: var(--new);
  --menu-dropdown-item-hover-foreground: var(--white-40);
  --menu-dropdown-item-hover-background: var(--white-3);

  /**
    Divider
   */
  --divider-color: var(--white-5);
  --divider-secondary-color: var(--surface);
  --date-time-divider-color: var(--white-10);

  /**
   * Sticky navigation
   */
  --sticky-nav-border: var(--white-5);
  --sticky-nav-background: rgb(17 24 39 / 40%);
  --sticky-nav-foreground: var(--gray-40);

  /**
   * Skeleton
   */
  --skeleton-chart-color: var(--white);
  --skeleton-animated-block-background: var(--white-3);
  --skeleton-animated-block-foreground: var(--white-15);

  /**
   * Metrics colors
   */
  /* stylelint-disable */
  --members: #b5a0ff;
  --citationIndex: #7fdb90;
  --postsViewAvg: #eb914c;
  --adsPostsViewAvg: #fabd07;
  --er: #2bb9c6;
  --err: #80c1ff;
  --posts-background: #ffffff;
  /* stylelint-enable */

  /**
   * Growth Tooltip
   */
  --growth-tooltip-background: rgb(17 24 39 / 40%);
  --growth-tooltip-border: var(--white-5);

  /**
   * Video
   */
  --video-rail-color: var(--white-20);
}

[data-theme='dark'] #logo-svg {
  fill: var(--white);
}
/* stylelint-disable */
[data-color='members'] {
  color: var(--members) !important;
}
[data-color='citationIndex'] {
  color: var(--citationIndex) !important;
}
[data-color='postsViewAvg'] {
  color: var(--postsViewAvg) !important;
}
[data-color='adsPostsViewAvg'] {
  color: var(--adsPostsViewAvg) !important;
}
[data-color='er'] {
  color: var(--er) !important;
}
[data-color='err'] {
  color: var(--err) !important;
}
[data-background-color='members'] {
  background-color: var(--members) !important;
}
[data-background-color='citationIndex'] {
  background-color: var(--citationIndex) !important;
}
[data-background-color='postsViewAvg'] {
  background-color: var(--postsViewAvg) !important;
}
[data-background-color='adsPostsViewAvg'] {
  background-color: var(--adsPostsViewAvg) !important;
}
[data-background-color='er'] {
  background-color: var(--er) !important;
}
[data-background-color='err'] {
  background-color: var(--err) !important;
}

/* stylelint-enable */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  background-color: var(--primary-background);
  max-width: 100vw;
  font-family: var(--font-suisse-regular), system-ui;
  height: 100%;
  font-size: 8px;
  scroll-behavior: smooth;
  position: relative;
}

html {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (min-width: 1681px) {
  html,
  body {
    font-size: 8px;
  }
}

@media (min-width: 1501px) and (max-width: 1680px) {
  html,
  body {
    font-size: 7.2px;
  }
}

@media (min-width: 1401px) and (max-width: 1500px) {
  html,
  body {
    font-size: 7px;
  }
}

@media (min-width: 1301px) and (max-width: 1400px) {
  html,
  body {
    font-size: 6.8px;
  }
}

@media (min-width: 1024px) and (max-width: 1300px) {
  html,
  body {
    font-size: 6.4px;
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 1681px) {
  html,
  body {
    font-size: 8px;
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 1501px) and (max-width: 1680px) {
  html,
  body {
    font-size: 7.2px;
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 1401px) and (max-width: 1500px) {
  html,
  body {
    font-size: 7px;
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 1301px) and (max-width: 1400px) {
  html,
  body {
    font-size: 6.8px;
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 1024px) and (max-width: 1300px) {
  html,
  body {
    font-size: 6.4px;
  }
}

[data-placeholder='chart'] defs stop:nth-child(1),
[data-placeholder='chart'] defs stop:nth-child(2) {
  stop-color: var(--skeleton-chart-color);
}
[data-placeholder='chart'] .chart-area {
  stroke: var(--skeleton-chart-color);
}

@supports not selector(::-webkit-scrollbar) {
  .non-mac-os * {
    scrollbar-width: thin;
    scrollbar-color: var(--base-5) var(--base-20);
  }
}

@supports selector(::-webkit-scrollbar) {
  .non-mac-os ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  .non-mac-os ::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: var(--base-5);
  }

  .non-mac-os ::-webkit-scrollbar-track:hover {
    background-color: var(--base-5);
  }

  .non-mac-os ::-webkit-scrollbar-track:active {
    background-color: var(--base-5);
  }

  .non-mac-os ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--base-20);
  }

  .non-mac-os ::-webkit-scrollbar-thumb:hover {
    background-color: var(--base-20);
  }

  .non-mac-os ::-webkit-scrollbar-thumb:active {
    background-color: var(--base-20);
  }
}
