@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-block: 5rem;
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30rem;
}

.resizer {
  position: relative;
  margin-block: 4rem 7rem;
  margin-inline: 7rem;
  aspect-ratio: 1;
}

.info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  margin-inline: auto;
}

.chart {
  margin-inline: auto;
}

.language {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 4rem;
  border: none;
  background: none;
  padding-block: 0.75rem;

  &.active {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 0 0.75rem 0.75rem 0;
      height: 2rem;
      width: 0.75rem;
      background: var(--brand);
    }

    :global(.MuiTypography-root) {
      color: var(--brand) !important;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
}

@include mobile {
  .root {
    flex-direction: row;
    padding: 3rem 0 3rem 3rem;
  }

  .empty {
    min-height: 30rem;
  }

  .resizer {
    width: 16.25rem;
    height: 16.25rem;
    margin: 0 3rem 0 0;
    align-self: center;
  }

  .list {
    flex: 1;
    align-self: center;
  }

  .language {
    &.active {
      &::before {
        left: auto;
        right: 0;
        border-radius: 0.75rem 0 0 0.75rem;
      }
    }
  }
}
