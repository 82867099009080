.primary {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  &.loaded {
    position: inherit;
    width: inherit;
    height: inherit;
    opacity: inherit;
  }
}

.blurred {
  &.loaded {
    display: none;
  }
}
