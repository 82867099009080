@import 'styles/variables';

.root {
  @include max-content-width;

  margin-inline: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'post-title post-controls' 'post-list post-list';
}

.title {
  grid-area: post-title;
  display: flex;
  align-items: flex-start;
  align-self: center;
  column-gap: 1rem;
}

.controls {
  grid-area: post-controls;
  justify-self: end;
  display: flex;
  column-gap: 0.5rem;
}

.paper {
  width: 100%;
}

.scroller {
  grid-area: post-list;
  padding: 1rem 0;
  margin-top: 6rem;
}

.item {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 43.75rem;
  height: fit-content;

  &:not(:last-of-type) {
    margin-right: 1rem;
  }
}

@include desktop {
  .title {
    width: 64.5rem;
    margin-left: auto;
  }

  .controls {
    width: 64.5rem;
    margin-right: auto;
    justify-content: end;
  }
}

@include mobile-tablet {
  .root {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas: 'post-title' 'post-list' 'post-controls';
  }

  .controls {
    margin-top: 1rem;
    justify-self: start;
    width: 100%;

    .slider {
      display: none;
    }

    :global(.MuiButtonBase-root) {
      width: 100%;
    }
  }
}

@include tablet {
  .scroller {
    height: 68.5rem;
  }

  .title,
  .controls {
    margin-inline: 3.5rem;
  }

  .controls {
    width: calc(100% - 7rem);
  }

  .item {
    flex-basis: calc(100% - 7rem);

    &:not(:last-of-type) {
      margin-right: 2rem;
    }
  }
}

@include mobile {
  .title,
  .controls {
    margin-inline: 1.5rem;
  }

  .controls {
    width: calc(100% - 3rem);
  }

  .scroller {
    margin-left: 1.5rem;
  }

  .item {
    flex-basis: calc(100% - 4rem);

    &:not(:last-of-type) {
      margin-right: 2rem;
    }
  }
}
