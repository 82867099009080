.button {
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
}

.image {
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
