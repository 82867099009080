@import 'styles/variables';

.root {
  @include max-content-width;

  padding-inline: 3.5rem;
  margin: 3rem auto 0;
}

.empty {
  height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem;
}
