@import 'styles/fonts';
@import 'styles/variables';

.profile {
  padding: 0;
  outline: 0;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  background: none;

  svg {
    transition: fill 0.3s ease-in-out;
    fill: var(--user-avatar-color);
  }

  &:hover {
    background: unset;

    svg {
      fill: var(--user-avatar-hover-color);
    }
  }
}

.divider {
  margin: 1rem -1rem;
  width: calc(100% + 2rem);
  border-bottom: 1px solid var(--divider-color);
}

.profileModal {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  height: 100%;
  width: 50%;
  padding: 2rem 2rem 2rem 0;
  margin: 0;

  .main {
    border-top: 1px solid var(--menu-dropdown-border);
    padding-top: 1rem;
  }

  .item {
    padding: 1rem 3rem;
  }

  .actions {
    padding: 1rem 1rem 2rem;

    .action {
      width: 100%;
    }
  }

  .buttonBack {
    border-top: 1px solid var(--menu-dropdown-border);
    border-bottom: 1px solid var(--menu-dropdown-border);
    padding: 1rem 0;
    margin-bottom: 1rem;

    :global(.MuiButton-root) {
      @include headline-9bk;

      width: 100%;
      justify-content: flex-start;
      padding: 1rem 3rem;
    }
  }

  .paper {
    min-width: 35rem;
  }

  .relative {
    position: relative;
  }

  .divider {
    display: none;
  }

  .submenu {
    padding: 0;
  }

  .buttonWithStartIcon {
    justify-content: flex-start !important;

    svg {
      width: 2.5rem;
      height: 2.5rem;
      margin: 0 1rem 0 0;
    }
  }

  .signIn {
    display: none !important;
  }
}

.menu {
  margin-top: 8px;

  .item {
    padding: 1rem 1rem 1rem 2rem;
    border-radius: 1rem;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &Active {
      color: var(--menu-active-item-color);

      svg {
        fill: var(--menu-active-item-color);
      }
    }

    svg {
      margin-left: 0.5rem;
    }

    .value {
      display: flex;
    }
  }

  .arrowIcon {
    transform: rotate(90deg);
  }

  .backIcon {
    transform: rotate(-90deg);
  }

  .list {
    position: relative;
    padding: 1rem;

    .buttonWithStartIcon {
      padding: 1rem 2rem 1rem 1rem;
      width: 100%;
      justify-content: flex-start;
      border-radius: 1rem;

      svg {
        width: 2rem;
        height: 2rem;
        margin-right: 1.5rem;
      }
    }
  }

  .main {
    transform: translateX(0);
    transition: all 0.3s cubic-bezier(0.22, 1, 0.36, 1);

    &.hidden {
      transform: translateX(calc(-100% - 2rem));
    }
  }

  .submenu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    transition: all 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }

  .active {
    transform: translateX(0%);
  }
}

.backdrop {
  background: var(--backdrop-background);
}

.modal {
  display: flex;
  justify-content: flex-end;

  .logo {
    display: none;
  }
}

@include desktop {
  .menu .paper {
    min-width: 30rem;
    border-radius: 2rem;
    border: 1px solid var(--menu-dropdown-border);
    background: var(--menu-dropdown-background);
    box-shadow:
      0 0 0 0 rgb(0 0 0 / 3%),
      0 3px 7px 0 rgb(0 0 0 / 3%),
      0 13px 13px 0 rgb(0 0 0 / 3%),
      0 30px 18px 0 rgb(0 0 0 / 2%),
      0 54px 22px 0 rgb(0 0 0 / 0%),
      0 84px 24px 0 rgb(0 0 0 / 0%);
    backdrop-filter: blur(20px);
  }
}

@include desktop {
  .menu {
    .item {
      :global(.MuiTypography-root) {
        @include headline-10bk;
      }
    }
  }
}

@include mobile {
  .menu {
    margin-top: 0;

    .item {
      padding: 1rem 3rem;
    }
  }

  .modal {
    .logo {
      display: block;
      position: absolute;
      top: 3rem;
      left: 50%;
      transform: translateX(-50%);
      height: 4rem;
      width: 10.4rem;
    }
  }

  .backdrop {
    background: var(--primary-background);
  }

  .profileModal {
    width: 100%;
    grid-template-columns: unset;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'close'
      'menu';
    padding: 2rem 1rem 1rem;

    .close {
      padding: 2rem !important;
      grid-area: close;
      justify-self: flex-end;
      margin: 0 1rem 2rem 0;
      background: var(--button-secondary-background) !important;

      svg {
        width: 2rem !important;
        height: 2rem !important;
      }
    }

    .main {
      border: none;
      padding-top: 1.5rem;
    }

    .actions {
      display: none;
    }

    .buttonBack {
      padding: 1.5rem 0 0;
      border: 0;
    }

    .divider {
      display: flex;
      margin: 1rem 0;
    }

    .signIn {
      @include headline-9bk;

      display: flex !important;
    }
  }
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 2.5rem;

  .name {
    margin-block: 1.5rem 0.5rem;
  }
}
