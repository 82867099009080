@import 'styles/variables';
@import 'styles/fonts';

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'meta' 'content';
  grid-column-gap: 3rem;
  grid-row-gap: 1rem;
  background: var(--base-5);
  border-radius: 4rem;
  padding: 1rem;
  z-index: 0;

  &.hasGallery {
    grid-template-columns: 1fr 13rem;
    grid-template-areas: 'meta gallery' 'content gallery';
  }

  > *:empty {
    display: none;
  }
}

.content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0 3rem 2rem 1.5rem;
}

.meta {
  grid-area: meta;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'channelInfo date';
  grid-gap: 1rem;
  align-items: center;
  padding: 1.5rem 3rem 0 1.5rem;

  &.withRepost {
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 'channelInfo date' 'repost repost';
  }
}

.channelInfo {
  @include text-ellipsis;

  grid-area: channelInfo;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem !important;

  :global(.MuiTypography-root) {
    @include headline-12bk;
  }
}

.deleteInfo {
  grid-area: date;
  padding: 0 !important;
  display: inline-grid !important;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'delete-date delete-info';
  grid-column-gap: 1rem;
  align-items: center;

  &Info {
    grid-area: delete-info;
  }

  &Date {
    grid-area: delete-date;
  }
}

.dateTime {
  grid-area: date;
}

.repost {
  grid-area: repost;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;

  &Info {
    padding: 0 !important;
  }

  &Button {
    padding: 0.5rem 1.5rem 0.5rem 0.5rem !important;
  }

  &Icon {
    width: 2rem;
    height: 2rem;
    fill: var(--base-50);
  }
}

.audio {
  padding-block: 1rem;
}

.documents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 1rem;
}

.gallery {
  grid-area: gallery;

  &Container {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-template-rows: repeat(2, 1fr) !important;
    border-radius: 3rem !important;
    aspect-ratio: 1 !important;
    text-align: center !important;
  }
}

@include desktop {
  .root {
    &:hover {
      cursor: pointer;
      position: relative;
      background: var(--secondary-background-hover);
      box-shadow:
        0 0 0 0 rgb(35 41 59 / 8%),
        0 4px 9px 0 rgb(35 41 59 / 8%),
        0 17px 17px 0 rgb(35 41 59 / 7%),
        0 39px 23px 0 rgb(35 41 59 / 4%),
        0 69px 28px 0 rgb(35 41 59 / 1%),
        0 108px 30px 0 rgb(35 41 59 / 0%);
    }
  }
}

@include mobile {
  .root {
    padding: 2.5rem;
    grid-row-gap: 1rem;

    &.hasGallery {
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr;
      grid-template-areas:
        'meta'
        'gallery'
        'content';
      grid-row-gap: 1rem;
    }
  }

  .gallery {
    &Container {
      aspect-ratio: unset !important;
      border-radius: 0 !important;
      display: flex;
      flex-direction: row;
      column-gap: 0.25rem;
    }

    .media {
      max-width: 4rem;
      flex-basis: 4rem;
      aspect-ratio: 1;
      border-radius: 1rem;
      isolation: isolate;
      overflow: hidden;
    }
  }

  .content {
    padding: 0;
  }
}
