@import 'styles/variables';

.header {
  @include max-content-width;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: 13rem auto 2rem;
  padding-inline: 3.5rem;
}

.avatar {
  width: 16rem !important;
  height: 16rem !important;
  font-size: 7rem !important;
  border-radius: 100%;
}

.link {
  padding: 3.75rem 1rem !important;

  &:hover,
  &:active {
    background: none !important;
  }
}

.nav {
  @include max-content-width;

  margin-inline: auto;
  padding-inline: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
