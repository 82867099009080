@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'pie headline' 'pie total';
  align-items: center;
  background: var(--surface);
  flex: 1;
  padding: 4rem;
}

.container {
  grid-area: pie;
  position: relative;
  width: min-content;
  margin-right: 4rem;
}

.avatar {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s $animation-function;

  &.shown {
    opacity: 1;
  }
}

.headline {
  grid-area: headline;
  align-self: end;
  display: grid;
  grid-template-columns: 1fr auto;

  .metric {
    @include text-ellipsis;
  }
}

.path {
  fill: var(--base-5);

  &:hover {
    fill: var(--brand);
  }
}

.total {
  grid-area: total;
  align-self: start;

  @include text-ellipsis;
}
