@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(2, 3rem);
  align-items: center;
  padding: 1rem 0 1rem 2rem;
  width: 19rem;
  border-radius: 1.5rem;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--base-5);
  }

  .row {
    display: flex;
  }
}

.icon {
  width: 2rem;
  height: 2rem;
  fill: var(--success);

  &.negative {
    fill: var(--error);
  }
}

.reposts,
.mentions {
  margin-inline: 0.5rem 1rem;
}

.popoverRoot {
  z-index: 3000;
}

.popover {
  background: none;
  box-shadow: none;
  border-radius: 0;
  padding-right: 1rem;
  overflow: visible;
}

.concurrentPopover {
  @include tooltip-styles;

  border-radius: 3rem;
  padding: 3rem;
  margin-right: 1rem;
  display: grid;
  box-sizing: border-box;
  overflow: auto;
  max-width: 41rem;

  &.hasContent {
    max-width: min-content;
  }
}

.concurrentTitle {
  margin-top: 3rem;
}

.group {
  margin-top: 1.5rem;
}

.button {
  padding: 0.5rem;
  margin-top: 1rem;

  &:first-of-type {
    margin-top: 1.5rem;
  }

  .title {
    @include text-ellipsis;

    margin-inline: 1rem;
    width: 100%;
    text-align: left;
  }

  .metrics {
    display: flex;
    flex-direction: row;
    column-gap: 0.75rem;
    padding: 0.675rem 1rem;

    &:first-of-type {
      margin-right: 0.25rem;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.noData {
  margin-top: 1rem;
}

@include mobile-tablet {
  .root {
    grid-template-rows: 3rem;
    grid-template-columns: auto 1fr;
    width: auto;
    padding: 0;

    &:hover {
      background-color: unset;
    }
  }
}

.skeletonButton {
  @include skeleton;

  width: 100%;
  height: 4.5rem;
  border-radius: 1rem;
  margin-top: 1rem;

  &:first-of-type {
    margin-top: 1.5rem;
  }
}

.skeletonText {
  @include skeleton;

  height: 1.2rem;
  width: 90%;
  margin-bottom: 1rem;

  &:nth-child(2) {
    width: 85%;
  }
}
