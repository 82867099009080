@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  position: relative;
}

@include mobile {
  .root {
    align-items: flex-start;
  }

  .tabs {
    margin-inline: 4rem;
  }
}
