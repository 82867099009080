@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  height: 68.5rem;
  margin-bottom: 1rem;
}

.header {
  padding: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .range {
    @include skeleton;

    width: 32.5rem;
    height: 3rem;
  }

  .period {
    @include skeleton;

    width: 13.5rem;
    height: 3rem;
  }
}

.chart {
  position: relative;
  display: grid;
  width: 100%;
  flex: 1;
  grid-template-columns: 1fr 8rem;
  grid-template-rows: 1fr 5rem;
  grid-template-areas:
    'svg yAxis'
    'xAxis .';

  .svg {
    width: 100%;
    height: 48rem;
    grid-area: svg;
  }

  .yAxis {
    grid-area: yAxis;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;


    &Item {
      @include skeleton;

      width: 3.5rem;
      height: 2rem;
    }
  }

  .xAxis {
    grid-area: xAxis;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &Item {
      @include skeleton;

      width: 8rem;
      height: 2rem;
    }
  }
}

.brushContainer {
  padding: 2rem 4rem;
}

.brush {
  @include skeleton;

  height: 48px;
}
