@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-block: 5rem;
}

.resizer {
  position: relative;
  margin-block: 4rem 7rem;
  margin-inline: 7rem;
  aspect-ratio: 1;
}

.info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 45px);
  margin-inline: auto;

  .key {
    @include skeleton;

    width: 8rem;
    height: 2rem;
  }

  .value {
    @include skeleton;

    width: 14rem;
    height: 4rem;
    margin-top: 0.25rem;
  }
}

.chart {
  position: relative;
  margin-inline: auto;
  border-radius: 100%;
  background: var(--base-5);
  width: 100%;
  aspect-ratio: 1;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    background: var(--surface);
  }
}

.language {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 4rem;
  border: none;
  background: none;
  padding-block: 0.75rem;

  .key {
    @include skeleton;

    width: 10rem;
    height: 2rem;
  }

  .value {
    @include skeleton;

    width: 8rem;
    height: 2rem;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
}

@include mobile {
  .root {
    flex-direction: row;
    padding: 3rem 0 3rem 3rem;
  }

  .resizer {
    width: 16.25rem;
    height: 16.25rem;
    margin: 0 3rem 0 0;
    align-self: center;
  }

  .list {
    flex: 1;
    align-self: center;
  }
}
