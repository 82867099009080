.root {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr repeat(3, auto) 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image {
  object-fit: cover;
  z-index: -1;
}

.title {
  color: #212A3B !important;
}

.description {
  color: rgb(16 41 96 / 50%) !important;
  margin-block: 1rem 3rem;
}
