@import 'styles/variables';

.root {
  padding: 0.5rem 2rem 0.5rem 0.5rem;

  :global(.MuiTypography-root) {
    @include text-ellipsis;

    margin-left: 1rem;
  }
}

.dark {
  background-color: var(--base-20);

  :global(.MuiTypography-root) {
    color: var(--white);
  }
}
