@import 'styles/fonts';

.root {
  width: 100%;

  :global {
    .MuiInputBase-root {
      padding: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiInputAdornment-root {
      position: absolute;

      svg,
      img {
        width: 2rem;
        height: 2rem;
      }
    }

    .MuiInputAdornment-position {
      &Start {
        left: 3rem;
      }

      &End {
        right: 3rem;
      }
    }

    .MuiInputBase-inputAdorned {
      &Start {
        padding-left: 6.5rem;
      }

      &End {
        padding-right: 6.625rem;
      }
    }
  }
}

.input {
  @include paragraph-10;

  box-sizing: content-box;
  padding: 2.75rem 3rem 2.75rem 4rem;
  background-color: var(--input-background);
  color: var(--input-text);
  border-radius: 40px;

  &::placeholder {
    color: var(--input-placeholder);
  }
}
