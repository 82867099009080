@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  border-radius: 3rem 3rem 3rem 0;
  border: 1px solid var(--growth-tooltip-border);
  background: var(--growth-tooltip-background);
  padding: 2rem 3rem 3rem;
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
  backdrop-filter: blur(2.5rem);
}

.hours {
  margin-bottom: 2rem;
}

.divider {
  border-top: 1px solid var(--dropdown-border);
  width: calc(100% + 6rem);
  margin-left: -3rem;
}

.data {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  align-items: center;
}

.value {
  margin-left: 10.5rem;
  justify-self: end;
}

.trend {
  justify-self: end;
  margin-left: 1rem;
}

@include mobile {
  .root {
    border-radius: 3rem;
  }
}
