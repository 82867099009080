@import 'styles/variables';

.root {
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  height: 100%;
}

.title {
  padding-block: 0.5rem;
  margin: 3rem 4rem 1.5rem;
}

.list {
  display: block;
  overflow: auto;
  margin: 2rem 4rem 0;
  max-height: 70rem;
}

.icon {
  padding: 0.5rem;

  svg {
    width: 2rem;
    height: 2rem;
    fill: var(--font-secondary-color);
  }
}

.city {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  border-radius: 1rem;
  padding: 0.75rem 2rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  .channels {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    .channels {
      opacity: 1;
    }
  }
}

.channels {
  border-radius: 3rem;
  padding: 0.375rem 1rem;
  background: var(--brand);
}

.content {
  padding: 3rem 4rem 0;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: calc(100% - 6rem);
}

.search {
  margin-inline: 4rem;
  width: auto !important;

  &Input {
    padding-left: 4.5rem !important;
  }

  &Icon {
    width: 2rem;
    height: 2rem;
    fill: var(--font-secondary-color);
  }
}

.button {
  margin: 1rem 3rem;
  column-gap: 0.5rem;
}

@include desktop {
  .city {
    &:hover {
      background: var(--base-5);
    }
  }
}

@include mobile-tablet {
  .list {
    max-height: 40rem;
  }
}

@include tablet {
  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
  }
}
