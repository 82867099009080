@import 'styles/fonts';
@import 'styles/variables';

/* stylelint-disable */
header[data-blurred='true'] {
  .button,
  .prompt {
    backdrop-filter: blur(20px);
    background: var(--white-20);
    color: var(--white-60);
  }

  .button {
    svg {
      fill: var(--white-60);
    }
  }
}

.root {
  flex: 1;
}

.button {
  display: none;

  @include mobile-tablet {
    display: flex;
  }

  @include mobile {
    padding: 2rem !important;

    svg {
      width: 2rem !important;
      height: 2rem !important;
    }
  }
}

.form {
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: 44rem;
  width: 100%;

  @media (width >= $tablet) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @include mobile-tablet {
    display: none;
  }
}

.prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--input-adornment-background);

  &::after {
    content: '/';

    @include label-12;
  }
}

.search {
  margin-top: 2rem;
  margin-inline: auto;
}

@include mobile {
  .search {
    margin-top: 0;
  }
}
