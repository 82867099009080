@import 'styles/variables';

.paper {
  padding: 1rem;
  width: 100%;
}

.container {
  width: 100%;
  border-radius: 4rem;

  &:hover {
    position: relative;
    background: var(--secondary-background-hover);
    box-shadow:
      0 0 0 0 rgb(35 41 59 / 8%),
      0 4px 9px 0 rgb(35 41 59 / 8%),
      0 17px 17px 0 rgb(35 41 59 / 7%),
      0 39px 23px 0 rgb(35 41 59 / 4%),
      0 69px 28px 0 rgb(35 41 59 / 1%),
      0 108px 30px 0 rgb(35 41 59 / 0%);
  }
}

.post {
  outline: none;
  border: none;
  cursor: pointer;
  min-height: 0;
  min-width: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4rem;
  overflow: hidden;
  isolation: isolate;
  position: relative;
  background: var(--secondary-background);

  &.restricted {
    height: 60rem;
  }

  .channelInfo {
    margin: 2rem 2rem 0;
    align-self: start;
    max-width: calc(100% - 4rem);
  }

  .content {
    display: flex;
    padding: 2rem 3rem 3rem;
    flex: 1;
    flex-direction: column;

    &Wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  .centered {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    width: 100%;
  }

  .circleVideo {
    align-self: center;
    max-width: 250px;
    max-height: 250px;
  }

  .metrics {
    padding: 2rem;

    &:global(.MuiButtonBase-root) {
      padding: 0.625rem 1rem;
    }
  }

  .documents {
    margin-top: 3rem;
  }

  .textContainer {
    margin-top: 12px;
  }

  .channelInfoInGallery {
    position: absolute;
    z-index: 100;
    top: 2rem;
    left: 2rem;
    max-width: calc(100% - 4rem);
  }

  .dateTimeInGallery {
    z-index: 100;
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    padding: 0;

    :global(.MuiTypography-root) {
      border-radius: 120px;
      padding: 0.5rem 1rem;
      background-color: var(--gray-60);
      color: var(--white);

      span {
        background: rgb(255 255 255 / 20%);
      }
    }

    :global(.MuiButton-root) {
      margin-left: 0.25rem;
      background-color: var(--gray-60);
      color: var(--white);

      svg {
        fill: var(--white);
      }
    }
  }

  &:not(.blurred) {
    .channelInfoInGallery {
      background-color: var(--gray-60);
    }
  }

  &.blurred {
    .channelInfoInGallery {
      backdrop-filter: blur(20px);
    }

    .dateTimeInGallery {
      :global(.MuiTypography-root),
      :global(.MuiButton-root) {
        backdrop-filter: blur(20px);
        background-color: var(--gray-20);
      }
    }
  }
}

.deleted {
  padding: 2rem 2.5rem;
}

.expand {
  display: flex;
  flex: 1;
}

.sticker:not(:empty) {
  width: 22rem;
  height: 22rem;
  margin-inline: auto;
}

@include mobile {
  .metrics {
    display: flex;
    justify-content: space-around;
  }

  .gallery {
    aspect-ratio: 1.94;
  }
}
