@import 'styles/variables';

.root {
  @include max-content-width;

  margin-inline: auto;
  padding: 13rem 3.5rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 3rem;
  row-gap: 6rem;
  justify-content: center;
}

.item {
  width: 41rem;
}

.title {
  margin: 0 0 3rem 4rem;
}

.metric {
  padding: 3.5rem 4rem;
}

.action {
  margin-top: 1rem;
  padding: 1rem;
}

.link {
  width: 100%;
}

.placeholder {
  @include skeleton;

  height: 3.75rem;
}

@include tablet {
  .item {
    width: unset;
    flex-basis: calc(50% - 1.5rem);
  }
}

@include mobile {
  .root {
    row-gap: 5.5rem;
    padding: 8rem 1.5rem;
  }

  .item {
    flex-basis: 100%;
  }
}
