/* stylelint-disable no-descending-specificity */
@import 'styles/fonts';
@import 'styles/variables';

.searchInput,
.root {
  transition: all 0.2s ease-in-out;

  &[data-blurred='true']:not(.isSticky) {
    .searchInput {
      background: var(--white-20);
      backdrop-filter: blur(20px);

      &::placeholder {
        color: var(--white-60);
      }
    }

    .logo {
      fill: white;
    }

    .button {
      svg {
        fill: white;
      }
    }

    .signInTelegram {
      background: var(--white);
      color: var(--font-primary-color);
    }

    .avatar {
      border: 1px solid var(--white-20);

      svg {
        fill: var(--white);
      }
    }

    .navigationButton {
      background: var(--white-20);
      backdrop-filter: blur(20px);

      svg {
        fill: var(--white-60);
      }
    }
  }
}

.logo {
  width: 104px;
  height: 40px;

  &Container {
    align-self: center;
    display: grid;
    align-content: center;
    grid-area: logo;

    @include mobile-tablet {
      position: absolute;
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include mobile {
    width: 10.4rem;
    height: 4rem;
  }
}

.avatar,
.logo {
  transition:
    width 0.3ms ease-in-out,
    height 0.3s ease-in-out;
}

.wrapper {
  @include max-content-width;

  padding: 0 3.5rem;
  justify-content: space-between;
  display: grid;
  grid-template-areas: 'logo navigation search actions profile';
  grid-template-columns: repeat(2, auto) 1fr repeat(2, auto);
  grid-template-rows: auto;
  height: 8rem;

  @include tablet {
    grid-template-areas: 'navigation search logo actions profile';
    padding: 0 2rem;
  }

  @include mobile {
    grid-template-areas: 'navigation search logo . profile';
    padding: 0 2rem;
    height: auto;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.search {
  grid-area: search;
}

.actions {
  grid-area: actions;
  column-gap: 0.5rem;

  @include mobile {
    display: none !important;
  }
}

.root {
  background: transparent;
  position: relative;
  z-index: 2;

  @include desktop {
    &.isSticky {
      position: sticky;
      top: -1px;
      z-index: 150;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--header-sticky-background);
        backdrop-filter: blur(20px);
        z-index: -1;
      }

      box-shadow:
        0 0 0 0 rgb(0 0 0 / 3%),
        0 3px 7px 0 rgb(0 0 0 / 3%),
        0 13px 13px 0 rgb(0 0 0 / 3%),
        0 30px 18px 0 rgb(0 0 0 / 2%),
        0 54px 22px 0 rgb(0 0 0 / 0%),
        0 84px 24px 0 rgb(0 0 0 / 0%);

      .wrapper {
        padding: 1rem 3.5rem;
      }

      .searchInput {
        padding: 1.5rem 3rem 1.5rem 4rem;
      }

      .logo {
        width: 94px;
        height: 36px;
      }

      .avatar {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}

.navigation {
  grid-area: navigation;
  margin: 0 2rem;
  justify-content: center;
  align-items: center;
  display: flex;

  @include tablet {
    margin: 0 1rem 0 0;
  }

  @include mobile {
    margin: 0 0.5rem 0 0;
  }

  &Button {
    display: none;

    @include mobile-tablet {
      display: flex;
    }

    @include mobile {
      padding: 2rem !important;

      svg {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
  }

  .menu {
    @include mobile-tablet {
      display: none;
    }
  }
}

.leftSide {
  flex: 1;
}

.profile {
  grid-area: profile;
  display: flex;
  flex-direction: row;
  column-gap: 2rem;

  @include tablet {
    margin-left: 2rem;
  }

  .button {
    padding: 0.75rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.signInTelegram {
  @include mobile-tablet {
    display: none;
  }
}

@include mobile-tablet {
  .root {
    &[data-blurred='true'] {
      .searchInput {
        background: var(--white-20);
        backdrop-filter: blur(20px);

        &::placeholder {
          color: var(--white-60);
        }
      }

      .logo {
        fill: white;
      }

      .button {
        svg {
          fill: white;
        }
      }

      .signInTelegram {
        background: var(--white);
        color: var(--font-primary-color);
      }

      .avatar {
        border: 1px solid var(--white-20);

        svg {
          fill: var(--white);
        }
      }

      .navigationButton {
        background: var(--white-20);
        backdrop-filter: blur(20px);

        svg {
          fill: var(--white-60);
        }
      }
    }
  }

  .wrapper {
    overflow: visible;
  }
}
/* stylelint-enable */
