.root {
  margin-top: 3rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--base-10);
    padding-bottom: 3rem;
  }
}

.date {
  margin-bottom: 2rem;
}

.latest {
  padding-block: 0.375rem;
  background: var(--brand);
  border-color: var(--brand) !important;
}
