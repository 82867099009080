@import 'styles/variables';

.root {
  grid-area: rating;
  display: flex;
  flex-direction: column;
  padding: 3.5rem 4rem 4rem 3rem;
}

.headline {
  margin-bottom: 3.5rem;
}

.resizer {
  position: static;
  top: 0;
  left: 2.075rem;
  bottom: 0;
  width: 0.5rem;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: auto;
  padding-top: 1.25rem;
  position: relative;
  max-height: 32rem;
  row-gap: 2rem;

  .group {
    transform: translateY(2rem);
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex: 1;

  &::before {
    content: '';
    position: absolute;
    top: 2rem;
    left: 2.082rem;
    bottom: 3rem;
    border-left: 1px dashed var(--base-10);
    border-right: 1px dashed var(--base-10);
    width: 0;
  }
}

.item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'title title' 'value change';
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  padding-left: 6.05rem;
  position: relative;

  .title {
    @include text-ellipsis;

    grid-area: title;
  }

  .value {
    grid-area: value;
  }

  .change {
    grid-area: change;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0.175rem;
    left: 1.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background: var(--surface);
    border: 0.25rem solid var(--brand);
    box-shadow: 0 0 0 1.25rem rgb(0 95 254 / 10%);
  }
}

@include tablet {
  .container {
    max-height: unset;
  }
}
