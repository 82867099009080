@import 'styles/variables';

.root {
  padding-top: 3rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(5, auto);
  justify-items: center;
  align-self: start;
  grid-template-areas:
    'avatar avatar'
    'overview overview'
    'actions actions'
    'info info'
    'download download'
    'chat chat';

  @include mobile-tablet {
    top: 0 !important;
    position: relative;
  }

  @include tablet {
    grid-template-columns: auto repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-template-areas:
      'avatar avatar avatar'
      'overview overview overview'
      'actions actions actions'
      'info info info'
      'download download download';
  }

  .avatar {
    grid-area: avatar;
    margin-bottom: 4rem;
    width: 30rem;

    @include mobile {
      width: auto;
    }
  }

  .overview {
    grid-area: overview;
  }

  .tags {
    margin: 2rem 0 4rem;
    padding: 0.25rem 0;
    display: flex;
    flex-direction: row;
    column-gap: 0.25rem;
    justify-content: center;
  }
}
