@import "styles/variables";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include max-content-width;

  padding: 2rem 3.5rem 0;
  margin-top: 2rem;
}

.maxWidth {
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code {
  display: block;
  text-align: center;
  font-size: 55rem;
  line-height: 0.945;
  font-variant-numeric: slashed-zero;
  background: linear-gradient(180deg, rgb(0 35 112 / 5%) 0%, rgb(0 35 112 / 0%) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title {
  margin-top: -12rem;
  text-align: center;
}

.description {
  display: block;
  margin: 3rem 0 4rem;
  text-align: center;
}
