.root {
  padding: 3.5rem 4rem;
}

.total {
  margin-block: 1rem 4.5rem;

  &.noReactions {
    margin-bottom: 0;
  }
}
