@import 'styles/variables';

.root {
  position: relative;
}

.viewFront {
  position: relative;
}

.headline {
  padding: 0;
}

.current {
  &Value {
    padding: 1rem 4rem 0;
  }
}

.detailed {
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  grid-row-gap: 12px;
  grid-template-areas:
    'title change value'
    'title change value'
    'title change value';
}

.value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: end;
}

.changeNotEmpty {
  margin-left: 8px;
}

.stat {
  .title {
    grid-area: title;
  }

  .change {
    grid-area: change;
  }

  .value {
    grid-area: value;
  }
}

@include mobile {
  .currentValue {
    padding: 0 3rem;
  }

  .detailed {
    padding: 3rem;
  }
}
