@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 17rem;
  height: 17rem;
  border-radius: 3rem;
  padding: 2rem 2rem 2.25rem;
  transition: background-color 0.3s ease-in-out;
}

.title {
  @include text-ellipsis;

  display: block;
  max-width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.tag {
  @include text-ellipsis;

  display: block;
  max-width: 100%;
}

@include desktop {
  .root {
    &:hover {
      background-color: var(--base-5);
    }
  }
}
