@import 'styles/variables';
@import 'styles/fonts';

.card,
.content,
.optionalBox {
  display: flex;
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 17.5rem;
  padding: 2rem;
  border-radius: 4rem;
  background-color: var(--base-5);
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
  gap: 2rem;

  @include mobile-tablet {
    background-color: var(--surface);
    height: 100%;
  }

  @include tablet {
    max-height: 12.5rem;
  }

  @include mobile {
    height: 100%;
    max-height: 10.75rem;
  }
}

.channelCard {
  background-color: var(--surface);
}

.index {
  @include suisse-font;

  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.75rem;
  font-weight: 450;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile-tablet {
    display: none;
  }
}

@include desktop {
  .card {
    &:hover {
      cursor: pointer;
      background: var(--base-5);
      box-shadow:
        0 0 0 0 rgb(35 41 59 / 8%),
        0 4px 9px 0 rgb(35 41 59 / 8%),
        0 17px 17px 0 rgb(35 41 59 / 7%),
        0 39px 23px 0 rgb(35 41 59 / 4%),
        0 69px 28px 0 rgb(35 41 59 / 1%),
        0 108px 30px 0 rgb(35 41 59 / 0%);
    }
  }

  .channelCard {
    &:hover {
      background: var(--surface);
    }
  }
}

.logoBox {
  height: 100%;
  width: 100%;
  max-width: 13.5rem;
  max-height: 13.5rem;
  aspect-ratio: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--surface);
  border-radius: 100%;

  @include mobile-tablet {
    max-width: 7rem;
    max-height: 7rem;
    height: 100%;
    width: 100%;
    background-color: var(--base-5);
  }
}

.verifiedIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 20;
  background-color: var(--base-5);
  border-radius: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--surface);
    border-radius: 100%;
    z-index: 10;
  }

  @include mobile-tablet {
    top: 0.25rem;
    left: 0.25rem;
  }
}

.verifiedIcon {
  z-index: 30;
  width: 2.75rem;
  height: 2.75rem;
  fill: var(--brand);

  @include mobile-tablet {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.logo {
  position: absolute;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
}

.optionalBox,
.content {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.content {
  width: 80%;
  flex-grow: 1;
  position: relative;

  @include mobile-tablet {
    width: 90%;
    justify-content: space-between;
  }
}

.button {
  align-self: flex-start;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

@include mobile-tablet {
  .button::before {
    content: attr(data-mobile-text);
  }
}

.button,
.title {
  text-wrap: nowrap !important;
}

.title {
  margin-bottom: auto;
  font-weight: 450;
  line-height: 1;
}

.description {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @include mobile-tablet {
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

.optionalBox {
  @include mobile-tablet {
    margin-top: 0;
    margin-bottom: auto;
    height: 100%;
    flex-grow: 1;
    align-self: flex-end;
    flex-direction: row;
    align-items: center;
  }
}

.icon {
  position: absolute;
  right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  bottom: 2rem;
  height: 4rem;
  width: 4rem;
  align-self: 1;
  border-radius: 100%;
  background-color: var(--base-5, rgb(0 0 0 / 10%));

  @include mobile-tablet {
    padding: 0.5rem;
    top: 1.5rem;
    right: 2rem;
    height: 2.5rem;
    width: 2.5rem;
  }
}

.svg {
  height: 2.5rem;
  width: 2.5rem;
  fill: var(--base-50) !important;

  path {
    fill: var(--base-50) !important;
  }
}

.platforms {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  gap: 0.5rem;

  @include mobile-tablet {
    margin: 0;
    margin-top: 0.5rem;
  }
}

.platform {
  display: grid;
  place-content: center;
  padding: 0.5rem;
  border-radius: 100%;
  background-color: var(--base-5);

  &:hover {
    background-color: var(--base-20);
  }
}

.platformIcon {
  width: 2.5rem !important;
  height: 2.5rem !important;
}
