@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 1rem;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: var(--button-secondary-background);
  border: 1px solid transparent;
  border-radius: 4rem;
}

.avatar {
  @include skeleton;

  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.title {
  @include skeleton;

  width: 15rem;
  height: 1.75rem;
}
