@import 'styles/variables';

.root {
  @include max-content-width;

  padding-top: 10rem;
}

.block {
  padding: 3rem 3.5rem 3.5rem;
  margin-bottom: 6rem;
}

.images {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  scroll-behavior: smooth;
  overflow-x: auto;
  margin-bottom: 6rem;

  @include mobile-tablet {
    margin-bottom: 6rem;
    padding-right: 0;
    justify-content: flex-start;
  }
}

.images::-webkit-scrollbar {
  display: none;
}

.image {
  background-color: var(--base-5);
  flex-shrink: 0;
  min-width: 42.25rem;
  min-height: 73rem;
  object-fit: cover;
  object-position: center;
  border-radius: 2.5rem;

  @include mobile-tablet {
    max-width: 28.25rem;
    max-height: 48.75rem;

    &:last-child {
      margin-right: 3.5rem;
    }
  }
}
