@import 'styles/variables';

.root {
  padding: 2rem;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  gap: 2rem;
  width: 50%;

  @include mobile-tablet {
    width: 100%;
    bottom: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
