@import 'styles/variables';

.close {
  position: absolute;
  left: calc(100% + 1rem);
  top: 0;
}

@include mobile {
  .close {
    left: auto;
    right: 2rem;
    top: auto;
    bottom: calc(100% + 1rem);
  }
}
