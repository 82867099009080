@import 'styles/variables';

.grid {
  @include max-content-width;
  @include content-indents;

  margin-inline: auto;
  display: grid;
  grid-template-columns: auto 1fr;
}

@include mobile-tablet {
  .grid {
    grid-template-columns: 1fr;
  }

  .panel {
    overflow: auto;
  }
}
