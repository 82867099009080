.root {
  border: 1px solid var(--menu-dropdown-border);
  background: var(--menu-dropdown-background);
  border-radius: 2rem;
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 1rem;
}

.item {
  justify-content: flex-start;
  border-radius: 1rem;

  &.selected {
    :global(.MuiTypography-root) {
      color: var(--menu-active-item-color);
    }

    color: var(--menu-active-item-color);
  }
}
