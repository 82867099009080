.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.screen {
  flex: 1;
  width: 100%;
  height: 100%;
}
