@import 'styles/variables';

.root {
  padding: 1rem;
  display: flex;
  column-gap: 1rem;
}

.chart {
  @include skeleton;

  flex-basis: 50%;
  height: 20rem;
  border-radius: 4rem;
  isolation: isolate;
  overflow: hidden;
}

@include mobile {
  .root {
    border-radius: 4rem;
  }

  .chart {
    height: 9rem;
    border-radius: 3rem;

    .text {
      padding: 1rem 3rem;
    }

    .value {
      border-radius: 3rem;
    }
  }
}
