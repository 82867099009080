.root {
  min-height: 52.6rem;
  z-index: 1;
  position: relative;
  padding: 3rem;
  border-radius: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  isolation: isolate;
}

.background {
  object-fit: cover;
  z-index: -1;
  border-radius: 5rem;
}

.title {
  text-align: center;
}

.botInfo {
  color: var(--white-60) !important;
  margin-block: 2rem 4rem;
}

.connect {
  color: #5c64df !important;
}
