@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  &.loading {
    display: block;
    column-count: 4;
    column-gap: 1rem;

    &.filtersOpen {
      column-count: 3;
    }

    & > div {
      margin-bottom: 1rem;
    }
  }

  &.empty {
    display: grid;
    justify-content: unset;
  }
}

@include tablet {
  .root {
    &.loading {
      column-count: 2;
    }
  }
}

@include mobile {
  .root {
    &.loading {
      column-count: 1;
    }
  }
}
