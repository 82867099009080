@import 'styles/variables';

.root {
  padding: 4rem 4rem 3rem;
}

.row {
  display: flex;
  flex-direction: row;
}

.cell {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.title {
  @include skeleton;

  width: 18rem;
  height: 4.75rem;
}

.total {
  @include skeleton;

  margin-top: 1rem;
  width: 21rem;
  height: 5rem;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-inline: -3rem;
  margin-block: 4rem 3rem;
}

.button {
  @include skeleton;

  height: 12rem;
}

.views {
  @include skeleton;

  height: 2rem;
}
