@import 'styles/variables';

.root {
  z-index: 100;
  margin-left: 50%;
  width: min-content;
  position: sticky;
  bottom: 3rem;
  overflow: visible;
  border-radius: 5rem;
  backdrop-filter: blur(20px);
  border: 1px solid var(--sticky-nav-border);
  background: var(--sticky-nav-background);
  animation: fade-slide-out 0.5s forwards cubic-bezier(0.22, 1, 0.36, 1);
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);

  &.show {
    animation: fade-slide-in 0.5s forwards cubic-bezier(0.22, 1, 0.36, 1);
  }
}

.wrapper {
  overflow: scroll;
}

.menu {
  border-radius: 5rem;
  padding: 0 8rem;
}

.list {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2rem;
  opacity: 1;
  overflow-x: auto;
  overflow-y: hidden;
}

.item {
  position: relative;

  span {
    color: var(--sticky-nav-foreground);
    transition: color 0.3s ease-in-out;
  }

  &.active {
    position: relative;

    &::after {
      content: '';
      background: var(--new);
      position: absolute;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
      width: 3rem;
      height: 0.75rem;
      border-radius: 0.75rem 0.75rem 0 0;
      transition: left 0.3s ease-in-out;
    }

    span {
      color: var(--new);
    }
  }

  &:hover span {
    color: var(--new);
  }
}

.indicator {
  background: var(--new);
  position: absolute;
  bottom: -1px;
  width: 3rem;
  height: 0.75rem;
  border-radius: 0.75rem 0.75rem 0 0;
  transition: left 0.3s ease-in-out;
}

.link {
  padding: 4rem 1rem;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0;

  &:hover {
    background: none;
  }
}

@keyframes fade-slide-in {
  from {
    opacity: 0;
    transform: translate3d(-50%, 6rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes fade-slide-out {
  from {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-50%, 6rem, 0);
  }
}

@include tablet {
  .root {
    max-width: calc(100% - 10.5rem);
  }
}

@include mobile {
  .root {
    max-width: calc(100% - 4rem);
  }

  .menu {
    padding: 0 4rem;
  }

  .link {
    padding: 2rem 0;
  }

  .list {
    column-gap: 3rem;
  }
}
