@import 'styles/variables';

.modal {
  overflow: auto;
}

.root {
  max-width: 63rem;
  position: relative;
  margin: 6rem auto;

  &:focus-visible {
    outline: 0;
  }
}

.backdrop {
  background: var(--modal-backdrop);
}

.paper {
  padding: 6rem;
  min-height: 80rem;
}

.close {
  position: absolute;
  left: calc(100% + 1rem);
  top: 0;
}

.tabs {
  justify-content: flex-start;
  margin-block: 4rem 1rem;
}

@include tablet {
  .root {
    margin-top: 20.25rem;
    width: calc(100% - 32rem);
    max-width: 64rem;
  }
}

@include mobile {
  .root {
    margin-top: 9rem;
    width: 100%;
    max-width: unset;
  }

  .close {
    top: unset;
    bottom: calc(100% + 1rem);
    right: 2rem;
    left: auto;
  }
}
