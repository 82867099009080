.root {
  padding: 4rem;
}

.content {
  margin-block: 4rem;
}

.divider {
  margin-inline: -4rem;
  width: calc(100% + 8rem) !important;
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.cell {
  display: flex;
  flex-direction: column;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
