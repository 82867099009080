.root {
  display: flex;
  justify-content: space-between;
  position: relative;

  .metric {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 1rem;
    column-gap: 0.75rem;

    &:not(:last-of-type) {
      margin-right: 0.25rem;
    }

    &Icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--font-secondary-color);
    }

    :global(.MuiTypography-root) {
      line-height: 1;
    }
  }

  .adIcon {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    bottom: 2.5rem;
    right: 2.5rem;
    fill: var(--font-secondary-color);
  }
}
