@mixin suisse-font {
  font-family: var(--font-suisse), system-ui;
  font-style: normal;
}

@mixin ibm-mono {
  font-family: var(--font-ibm-plex-mono), monospace;
  font-weight: 400;
  font-style: normal;
}

@mixin headline-1 {
  @include suisse-font;

  font-weight: 400;
  font-size: 8rem;
  line-height: 1;
  letter-spacing: -2.048px;
}

@mixin headline-1bk {
  @include suisse-font;

  font-weight: 500;
  font-size: 8rem;
  line-height: 1;
  letter-spacing: -2.048px;
}

@mixin headline-2 {
  @include suisse-font;

  font-weight: 400;
  font-size: 6rem;
  line-height: 1;
  letter-spacing: -1.536px;
}

@mixin headline-2sb {
  @include suisse-font;

  font-weight: 600;
  font-size: 6rem;
  line-height: 1;
  letter-spacing: -1.536px;
}

@mixin headline-2bk {
  @include suisse-font;

  font-weight: 500;
  font-size: 6rem;
  line-height: 1;
  letter-spacing: -1.536px;
}

@mixin headline-3 {
  @include suisse-font;

  font-weight: 400;
  font-size: 5rem;
  line-height: 1;
  letter-spacing: -1.28px;
}

@mixin headline-4 {
  @include suisse-font;

  font-weight: 400;
  font-size: 4rem;
  line-height: 1;
  letter-spacing: -0.896px;
}

@mixin headline-4sb {
  @include suisse-font;

  font-weight: 600;
  font-size: 4rem;
  line-height: 1;
  letter-spacing: -0.896px;
}

@mixin headline-4bk {
  @include suisse-font;

  font-weight: 500;
  font-size: 4rem;
  line-height: 1;
  letter-spacing: -0.896px;
}

@mixin headline-5 {
  @include suisse-font;

  font-weight: 400;
  font-size: 3.5rem;
  line-height: 1.142;
  letter-spacing: -0.784px;
}

@mixin headline-6 {
  @include suisse-font;

  font-weight: 400;
  font-size: 3rem;
  line-height: 1.166;
  letter-spacing: -0.672px;
}

@mixin headline-6sb {
  @include suisse-font;

  font-weight: 600;
  font-size: 3rem;
  line-height: 1.166;
  letter-spacing: -0.672px;
}

@mixin headline-7 {
  @include suisse-font;

  font-weight: 400;
  font-size: 2.5rem;
  line-height: 1.2;
  letter-spacing: -0.48px;
}

@mixin headline-7sb {
  @include headline-7;

  font-weight: 600;
}

@mixin headline-8 {
  @include suisse-font;

  font-weight: 400;
  font-size: 2.25rem;
  line-height: 1.111;
  letter-spacing: -0.432px;
}

@mixin headline-8sb {
  @include suisse-font;

  font-weight: 600;
  font-size: 2.25rem;
  line-height: 1.111;
  letter-spacing: -0.432px;
}

@mixin headline-8bk {
  @include suisse-font;

  font-weight: 500;
  font-size: 2.25rem;
  line-height: 1.111;
  letter-spacing: -0.432px;
}

@mixin headline-9 {
  @include suisse-font;

  font-weight: 400;
  font-size: 2rem;
  line-height: 1.125;
  letter-spacing: -0.384px;
}

@mixin headline-9bk {
  @include suisse-font;

  font-weight: 500;
  font-size: 2rem;
  line-height: 1.125;
  letter-spacing: -0.384px;
}

@mixin headline-10 {
  @include suisse-font;

  font-weight: 400;
  font-size: 1.75rem;
  line-height: 1.142;
  letter-spacing: -0.168px;
}

@mixin headline-10bk {
  @include suisse-font;

  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.142;
  letter-spacing: -0.168px;
}

@mixin headline-10sb {
  @include suisse-font;

  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.142;
  letter-spacing: -0.168px;
}

@mixin headline-11 {
  @include suisse-font;

  font-weight: 400;
  font-size: 1.625rem;
  line-height: 1.076;
  letter-spacing: -0.156px;
}

@mixin headline-11bk {
  @include suisse-font;

  font-weight: 500;
  font-size: 1.625rem;
  line-height: 1.076;
  letter-spacing: -0.156px;
}

@mixin headline-12 {
  @include suisse-font;

  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.166;
  letter-spacing: -0.144px;
}

@mixin headline-12bk {
  @include suisse-font;

  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.166;
  letter-spacing: -0.144px;
}

@mixin headline-12sb {
  @include suisse-font;

  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.166;
  letter-spacing: -0.144px;
}

@mixin label-8 {
  @include suisse-font;

  font-weight: 600;
  font-size: 2.25rem;
  line-height: 1.111;
  letter-spacing: -0.432px;
}

@mixin label-9 {
  @include suisse-font;

  font-weight: 600;
  font-size: 2rem;
  line-height: 1.125;
  letter-spacing: -0.384px;
}

@mixin label-10 {
  @include suisse-font;

  font-weight: 600;
  font-size: 1.75rem;
  line-height: 1.142;
}

@mixin label-11 {
  @include suisse-font;

  font-weight: 600;
  font-size: 1.625rem;
  line-height: 1.076;
  letter-spacing: -0.156px;
}

@mixin label-12 {
  @include suisse-font;

  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.166;
  letter-spacing: -0.144px;
}

@mixin paragraph-7 {
  @include suisse-font;

  font-weight: 400;
  font-size: 2.5rem;
  line-height: 1.2;
  letter-spacing: -0.48px;
}

@mixin paragraph-8 {
  @include suisse-font;

  font-weight: 400;
  font-size: 2.25rem;
  line-height: 1.333;
  letter-spacing: -0.432px;
}

@mixin paragraph-9 {
  @include suisse-font;

  font-weight: 400;
  font-size: 2rem;
  line-height: 1.375;
  letter-spacing: -0.384px;
}

@mixin paragraph-10 {
  @include suisse-font;

  font-weight: 400;
  font-size: 1.75rem;
  line-height: 1.428;
  letter-spacing: -0.168px;
}

@mixin paragraph-10bk {
  @include suisse-font;

  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.428;
  letter-spacing: -0.168px;
}

@mixin paragraph-12 {
  @include suisse-font;

  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: -0.144px;
}

@mixin mono-1 {
  @include ibm-mono;

  font-size: 1.5rem;
  line-height: 1.333;
  text-transform: uppercase;
}

@mixin mono-2 {
  @include ibm-mono;

  font-size: 1.25rem;
  line-height: 1.2;
  text-transform: uppercase;
}
