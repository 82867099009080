@import 'styles/variables';

.tabs {
  @include max-content-width;

  margin: 12rem auto 3rem;
  padding-inline: 3.5rem;
}

@include mobile {
  .tabs {
    margin: 8rem auto 2rem;
    padding-inline: 1.5rem;
  }
}
