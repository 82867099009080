@import 'styles/variables';

.breadcrumbs {
  @include max-content-width;

  padding: 4rem 0 0 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;

  @include mobile {
    padding: 2rem 0 0 2rem;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--base-50) !important;

  &:hover {
    color: var(--base-100) !important;
  }
}
