.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 3.25rem;
}

.details {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 2rem 2.25rem 1.5rem;
  background: var(--button-secondary-background);
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
}

.emoji {
  font-size: 4rem;
}
