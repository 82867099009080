@import 'styles/variables';

.container {
  @include max-content-width;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 3.5rem;
  position: relative;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.titleBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
  margin-bottom: 2rem;

  @include mobile-tablet {
    justify-content: space-between;
  }
}

.cards {
  width: 100%;

  :global {
    .slick-slider {
      overflow: hidden;
    }

    .slick-list {
      margin: 0 -1rem;

      .slick-slide {
        display: flex;
        width: 100%;
        height: auto;

        > div {
          margin: 0 1rem;
          width: 100%;
          display: flex;
        }
      }
    }

    .slick-track {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: center;
    }
  }
}

.card {
  flex-shrink: 0;
  margin-bottom: 10rem;
}
