@import 'styles/variables';

.table {
  grid-area: content;
  padding: 1rem;
}

.sortingPanel {
  padding: 3rem;
}

@include mobile-tablet {
  .sortingPanel {
    display: none;
  }
}
