.root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  z-index: 1;

  :global(.StaticLabelPlaceholder) {
    filter: grayscale(1) opacity(0.55);
    transition: filter 0.5s cubic-bezier(0.22, 1, 0.36, 1);
    width: 100%;
    height: 100%;
  }
}

.chart {
  width: 100%;
  height: 140%;
}
