.root {
  padding: 1rem;
}

.button {
  padding: 3rem;
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'avatar title icon publishTime'
    'avatar categories . publushDate';
}

.avatar {
  grid-area: avatar;
  margin-right: 2rem;
}

.title {
  grid-area: title;
  margin-right: 1rem;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  grid-area: icon;
  margin-right: 1rem;
}

.categories {
  grid-area: categories;
}

.publishTime {
  grid-area: publishTime;
  align-self: end;
}

.publishDate {
  grid-area: publushDate;
  margin-left: 3rem;
  align-self: start;
}

.publishDate,
.publishTime {
  justify-self: end;
}
