@import 'styles/variables';
@import 'styles/fonts';

.item {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}

.placeholder {
  .icon {
    @include skeleton;

    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
  }

  .checkbox {
    @include skeleton;

    width: 2.75rem;
    height: 2.75rem;
    border-radius: 0.5rem;
  }

  .title {
    @include skeleton;

    width: 10rem;
    height: 2rem;
  }

  &.list {
    display: flex;
    flex-direction: column;

    .title {
      width: 70%;
    }
  }
}

.list {
  backdrop-filter: blur(20px);
  background: var(--growth-tooltip-background);
  border: 1px solid var(--growth-border-color);
  border-radius: 2rem;
  min-width: 28rem;
  padding: 1rem;
  max-width: 30rem;
}

.checkbox {
  width: 100%;
  margin-inline: 0;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  transition: all 0.3s ease-in-out;

  &.checked {
    .label {
      color: var(--brand);
    }

    .icon {
      opacity: 0;
    }

    .input {
      opacity: 1;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &:hover {
    background-color: var(--base-5);
  }
}

.label {
  @include headline-10bk;

  color: var(--font-secondary-color);
  transition: all 0.1s ease-in-out;
  margin-left: 1rem;
  width: 100%;
}

.search {
  &Input {
    background-color: transparent !important;
    padding: 0.75rem 1.5rem 0.75rem 4.5rem !important;
  }

  &Icon {
    width: 2rem;
    height: 2rem;
    fill: var(--base-50);
  }
}

.divider {
  width: calc(100% + 2rem);
  margin: 1rem -1rem;
}

.notFound {
  padding: 0.5rem 2rem 0.5rem 1rem;
}

.count {
  @include paragraph-12;

  background: var(--brand);
  color: var(--white);
  border-radius: 2rem;
  padding: 0.15rem 1rem;
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.iconWithInput {
  position: relative;
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  .icon {
    width: 3rem;
    height: 3rem;
    opacity: 1;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
  }
}

@include desktop {
  .checkbox {
    &:hover {
      .label {
        color: var(--font-primary-color);
      }

      .icon {
        opacity: 0;
      }

      .input {
        opacity: 1;
      }
    }
  }
}
