.root {
  outline: none;
  border: none;
  cursor: pointer;
  background: var(--surface);
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(5, auto) 1fr repeat(4, auto);
  align-items: center;
  border-radius: 5rem;
  padding-inline: 4rem 1.75rem;
  height: 6rem;

  &.withoutArrow {
    padding-right: 8.15rem;
  }
}

.absolute {
  margin-right: 1rem;
}

.divider {
  width: 1px;
  height: 6rem;
  border-left: 1px solid var(--base-10);
  margin-inline: 2rem 1.75rem;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  fill: var(--base-50);
  transition: transform 0.3s;
}

.iconCounter {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--base-50);
}

.dividerDot {
  display: inline-flex;
  align-self: center;
  width: 3px;
  height: 3px;
  margin: 0 1rem;
  border-radius: 50%;
  background-color: var(--date-time-divider-color);
}

.metric {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  margin-right: 1rem;
}

/* stylelint-disable */
:global(.Collapsible__trigger.is-open) {
  .icon {
    transform: rotateX(180deg);
  }
}
/* stylelint-enable */
