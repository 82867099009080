@import 'styles/variables';

.root {
  display: flex;
  justify-content: flex-end;
  column-gap: 0.5rem;
  margin-block: 3rem;
}

@include mobile-tablet {
  .root {
    justify-content: space-between;
  }
}
