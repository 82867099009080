.icon {
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
  fill: var(--font-secondary-color);
}

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  background: none;
  padding: 1rem 1.5rem;
  border: 1px solid var(--paper-inner-border);
  border-radius: 1rem;
  cursor: pointer;

  &.borderless {
    border: none;
  }

  &.open {
    :global(.MuiTypography-root) {
      color: var(--font-primary-color);
    }

    .icon {
      transform: rotate3d(1, 0, 0, 180deg);
      fill: var(--font-primary-color);
    }
  }

  &:hover {
    :global(.MuiTypography-root) {
      color: var(--font-primary-color);
    }

    .icon {
      fill: var(--font-primary-color);
    }
  }
}

.paper {
  background: none;
  border: none;
}
