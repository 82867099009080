.root {
  padding: 4rem 4rem 3rem;
}

.row {
  display: flex;
  flex-direction: row;
}

.cell {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.total {
  margin-top: 1rem;
}

.views {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'title space value';
  align-items: center;
  column-gap: 2rem;

  &::before {
    content: '';
    grid-area: space;
    display: block;
    height: 1px;
    background-color: var(--paper-inner-border);
  }
}

.label {
  grid-area: title;
}

.value {
  grid-area: value;
}
