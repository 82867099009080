@import 'styles/variables';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;

  @include mobile-tablet {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1rem;
  }
}

.right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10rem;

  @include mobile-tablet {
    width: 100%;
    gap: 6rem;
  }
}

.description {
  background-color: var(--surface);
  border-radius: 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include mobile-tablet {
    margin-top: 6rem;
    background-color: transparent;
  }
}

.descrTop {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  padding: 3rem;

  @include mobile-tablet {
    padding: 0;
  }
}

.descriptionText {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.3s ease;
  position: relative;

  &.expanded {
    line-clamp: unset;
    -webkit-line-clamp: unset;
    display: block;
  }
}

.descrBottom {
  border-top: 1px solid var(--base-10);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  padding: 1rem;

  @include mobile-tablet {
    border-top: none;
    justify-content: center;
    padding: 0;
  }
}

.left {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 30%;

  @include mobile-tablet {
    width: 100%;
  }
}

.title {
  padding: 2rem 3rem;
  width: 100%;
  border-radius: 5rem;
  background-color: var(--surface);
  margin-bottom: 1rem;
}

.details {
  background-color: var(--surface);
  border-radius: 5rem;
  padding: 3rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.detail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2rem 0;
  border-bottom: 1px solid var(--base-10);
}

.detail:first-child {
  padding: 0 0 2rem;
}

.detail:last-child {
  padding: 2rem 0 0;
  border-bottom: none;
}

.icon {
  width: 4rem;
  height: 4rem;
  background-color: var(--base-5);
  padding: 1rem;
  border-radius: 100%;
  fill: var(--base-5) !important;
  color: var(--base-5) !important;
}

.text {
  display: flex;
  margin-left: 1.5rem;
  margin-top: 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
}

.showMore {
  padding: 0.5rem 1rem !important;
  color: var(--base-40) !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;

  @include mobile-tablet {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.arrowDown {
  margin-left: 0.5rem;
  fill: var(--base-40);
  transition: transform 0.3s ease;

  @include mobile-tablet {
    margin-left: 0.25rem;
    width: 1rem;
    height: 1rem;
  }
}

.arrowUp {
  transform: rotate(180deg);
}
