@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3rem;
}

.row {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'graph graph graph' 'title . value';

  &:not(:last-of-type) {
    margin-bottom: 4rem;
  }

  .graph {
    @include skeleton;

    grid-area: graph;
    height: 2rem;
    border-radius: 1.5rem;
    margin-bottom: 2rem;
  }

  .key {
    @include skeleton;

    grid-area: title;
    height: 2rem;
    width: 20rem;
  }

  .value {
    @include skeleton;

    grid-area: value;
    height: 2rem;
    width: 6rem;
  }
}
