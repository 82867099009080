.root {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  align-items: center;
}

.icon {
  width: 3rem;
  height: 3rem;
  fill: var(--font-secondary-color);
}
