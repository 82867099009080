@import 'styles/variables';
@import 'styles/fonts';

.root {
  position: relative;
  aspect-ratio: 1;

  &.defaultContext {
    :global(#bg) {
      stroke: var(--primary-background);
    }
  }

  &.paperContext {
    :global(#bg) {
      stroke: var(--paper-background);
    }
  }

  &.transparentContext {
    :global(#bg) {
      stroke: transparent;
    }
  }
}

.verify {
  position: absolute;
  top: 0;
  left: 0;

  :global(#bg) {
    stroke: #fff;
    stroke-width: 0.5rem;
  }
}

.confirmed {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  background: var(--brand);
  color: #fff;
  border-radius: 3rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 0.5rem solid var(--bg);

  .confirmedIcon {
    fill: #fff;
  }
}

.avatar {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;

  &.placeholder {
    @include suisse-font;

    background: linear-gradient(
      135deg,
      color-mix(in srgb, var(--avatar-color), #fff 20%) 0%,
      color-mix(in srgb, var(--avatar-color), #000 10%) 100%
    );
    color: white;
    display: grid;
    justify-content: center;
    align-items: center;
  }
}

.size240 {
  width: 30rem;
  height: 30rem;

  img {
    width: 30rem;
    height: 30rem;
  }

  .verify {
    top: 1.5rem;
    left: 1.625rem;
    width: 5.75rem;
    height: 5.875rem;
  }

  .placeholder {
    font-size: 10rem;
  }
}

.size128 {
  width: 16rem;
  height: 16rem;

  img {
    width: 16rem;
    height: 16rem;
  }

  .verify {
    width: 4.25rem;
    height: 4.25rem;
    top: 0.375rem;
    left: 0.25rem;
  }

  .placeholder {
    font-size: 6rem;
  }
}

.size96 {
  width: 12rem;
  height: 12rem;

  img {
    width: 12rem;
    height: 12rem;
  }

  .verify {
    width: 4rem;
    height: 4rem;
    top: 0;
    left: 0;
  }

  .placeholder {
    font-size: 5rem;
  }
}

.size64 {
  width: 8rem;
  height: 8rem;

  .verify {
    width: 2.5rem;
    height: 2.5rem;
    top: -0.25rem;
    left: -0.25rem;
  }

  .placeholder {
    font-size: 3rem;
  }
}

.size48 {
  width: 6rem;
  height: 6rem;

  .verify {
    width: 2rem;
    height: 2rem;
    top: -0.25rem;
    left: -0.25rem;
  }

  .placeholder {
    font-size: 3rem;
  }
}

.size32 {
  width: 4rem;
  height: 4rem;

  .verify {
    width: 1.5rem;
    height: 1.5rem;
    top: -0.15rem;
    left: -0.15rem;
  }

  .placeholder {
    font-size: 2rem;
  }
}

.size24 {
  width: 3rem;
  height: 3rem;

  .verify {
    width: 1.5rem;
    height: 1.5rem;
    top: -0.25rem;
    left: -0.25rem;
  }

  .placeholder {
    font-size: 1.5rem;
  }
}

.size18 {
  width: 2.25rem;
  height: 2.25rem;

  .verify {
    width: 1.5rem;
    height: 1.5rem;
    top: -0.25rem;
    left: -0.25rem;
  }

  .placeholder {
    font-size: 1.5rem;
  }
}

@include mobile {
  .size240 {
    width: 19rem;
    height: 19rem;

    img {
      width: 19rem;
      height: 19rem;
    }

    .verify {
      top: 0.5rem;
      left: 0.625rem;
      width: 4.75rem;
      height: 4.875rem;
    }
  }
}
