@import 'styles/variables';
@import 'styles/fonts';

.root {
  @include max-content-width;

  margin-inline: auto;
  padding-inline: 3.5rem;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 3rem;
}

.paper {
  padding: 1rem;

  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
}

.title {
  margin: 2.5rem 3rem 3.5rem;
}

.channelsCount {
  padding: 0.5rem 1rem;
  border-radius: 4rem;
  line-height: 1 !important;
  background: var(--brand);
}

.search {
  &:global(.MuiInputBase-input) {
    @include paragraph-10;

    line-height: 2.5rem;
    height: 4rem;
    border-radius: 1rem;
    padding: 0.75rem 1.5rem 0.75rem 4.5rem;
  }

  &Container {
    max-width: 33rem;

    :global(.MuiInputAdornment-root) {
      left: 1.5rem;

      svg {
        fill: var(--font-secondary-color);
      }
    }
  }
}

.grid {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}

.topic {
  position: relative;
  border-radius: 4rem;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 1rem;
  padding: 14.24rem 3rem 3rem;
  height: 100%;

  .background {
    object-fit: cover;
    object-position: center;
    border-radius: 4rem;
    z-index: -1;
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    opacity: 0.6;
  }

  .pin {
    position: absolute;
    top: 2rem;
    right: 2rem;

    &.pinned {
      background: var(--surface) !important;

      svg {
        fill: var(--font-primary-color) !important;
      }
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4rem;
  padding: 6rem;
}

.filters {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.placeholder {
  @include skeleton;

  border-radius: 4rem;
  height: 28rem;
}

@include tablet {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include mobile {
  .root {
    padding-inline: 1.5rem;
  }

  .controls {
    flex-direction: column;
    row-gap: 2.5rem;
  }

  .search {
    padding: 1.75rem 3rem 1.75rem 5.5rem !important;
    border-radius: 5rem !important;

    &Container {
      max-width: unset !important;

      :global(.MuiInputAdornment-root) {
        left: 2rem;
      }
    }
  }

  .title {
    margin: 1.5rem 2rem 2.5rem;
  }

  .grid {
    grid-template-columns: 1fr;
  }

  .dropdown {
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(2, auto);
  }
}
