@import 'styles/variables';

@include mobile-tablet {
  .root:not(.modal) {
    display: none;
  }

  .placeholder {
    display: none;
  }
}
