@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
}

.contentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 3rem 3rem 4rem;
}

.title {
  @include skeleton;

  width: 11.25rem;
  height: 3rem;
}

.action {
  @include skeleton;

  width: 14.375rem;
  height: 4rem;
}

.record {
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;

  &:first-of-type {
    padding-top: 4rem;
  }

  &:last-of-type {
    padding-bottom: 4rem;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--paper-inner-border);
  }
}

.header {
  display: flex;
  flex-direction: row;
}

.value {
  @include skeleton;

  width: 15rem;
  height: 4rem;
  margin: 1.5rem 0 1rem;
}

.name {
  @include skeleton;

  width: 10rem;
  height: 2rem;
}

.more {
  display: none;
}

@include tablet {
  .root {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .record {
    padding: 3rem;

    &:nth-child(even) {
      border-left: 1px solid var(--paper-inner-border);
      padding-right: 4rem;
    }

    &:nth-child(odd) {
      padding-left: 4rem;
    }

    &:nth-child(5) {
      border-bottom: none;
    }

    &:nth-child(1),
    &:nth-child(2) {
      padding-top: 4rem;
    }

    &:nth-child(5),
    &:nth-child(6) {
      padding-bottom: 4rem;
    }
  }
}

@include mobile {
  .more {
    display: flex;
    flex-direction: column;
    padding: 1rem 3rem;

    &Button {
      @include skeleton;

      height: 4rem;
    }
  }

  .record {
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      display: none;
    }
  }
}
