@import 'styles/variables';

.root {
  max-width: 100rem;
  transform: translateY(-6.25rem);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
}

.logo {
  width: 25rem;
  height: 25rem;
}

.title {
  margin: 3rem 0;
}

@include mobile {
  .root {
    transform: unset;
  }
}
