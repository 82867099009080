.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: var(--gray-20);
  cursor: pointer;

  svg {
    width: 4rem;
    height: 4rem;
    fill: var(--white);
  }
}

.root {
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  isolation: isolate;

  &.playing:not(:hover) {
    .overlay {
      display: none;
    }
  }
}

.thumbnail {
  width: 100%;
  height: 100%;
}

.player {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
}
