@import 'styles/fonts';
@import 'styles/variables';

.form {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-grow: 1;
  max-width: 52rem;
  width: 100%;
}

.prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  background-color: var(--input-adornment-background);

  &::after {
    content: '/';

    @include label-12;
  }
}

.search {
  margin-top: 2rem;
  margin-inline: auto;
}
