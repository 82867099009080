@import 'styles/variables';

.root {
  background: var(--base-5);
  border-radius: 4rem;
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'type type'
    'horizontal horizontal'
    'views members'
    'concurrent concurrent';
  transition: all 0.3s ease-in-out;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.type {
  grid-area: type;
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr repeat(2, auto);
  flex-direction: row;

  .icon {
    margin: 2.25rem;
    width: 3rem;
    height: 3rem;
    fill: var(--base-50);
  }
}

.divider {
  height: 100%;
  width: 0;
  border-left: 1px solid var(--surface);
  border-right: 1px solid var(--base-10);

  &Horizontal {
    grid-area: horizontal;
  }
}

.date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-inline: 3rem 2rem;
}

.channel {
  grid-area: channel;
}

.views,
.members {
  padding-left: 2rem;
  align-self: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 3rem);
}

.views {
  padding: 0;
  margin: 2rem 0 0 2rem;
  grid-area: views;
}

.members {
  grid-area: members;
  padding: 0;
  margin: 2rem 2rem 0 0;

  &Metric {
    display: flex;
    align-items: center;
    align-self: center;
    margin-block: auto;
    flex-direction: row;
    column-gap: 0.5rem;
  }
}

.concurrent {
  grid-area: concurrent;
  display: grid;
  grid-template-rows: repeat(2, 3rem);
  align-self: center;
  align-items: center;
  margin: 2rem;
}

.open {
  align-self: center;
}

@include mobile {
  .root {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'type type'
      'horizontal horizontal'
      'channel channel'
      'views members'
      'concurrent concurrent';
  }

  .channel {
    margin: 3rem 3rem 0;
    grid-template-columns: repeat(3, auto);
  }

  .type {
    .icon {
      margin: 2.75rem;
    }
  }

  .views {
    margin: 3rem 0 0 3rem;
  }

  .members {
    margin: 3rem 3rem 0 0;

    &Metric {
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
    }
  }

  .views,
  .members {
    padding-left: 0;
  }

  .concurrent {
    padding-left: 0;
    margin: 3rem;
  }

  .metricTitle {
    display: unset;
  }

  .divider {
    height: 100%;

    &Horizontal {
      display: block;
    }
  }
}
