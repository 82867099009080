.dateTime {
  display: flex;
  align-items: center;

  &Divider {
    display: inline-flex;
    align-self: center;
    width: 3px;
    height: 3px;
    margin: 0 1rem;
    border-radius: 50%;
    background-color: var(--date-time-divider-color);
  }
}
