@import 'styles/variables';

.root {
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  height: 100%;
}

.title {
  padding-block: 0.5rem;
  margin: 3rem 4rem 1.5rem;
}

.list {
  display: block;
  overflow: auto;
  margin: 2rem 4rem 0;
}

.icon {
  padding: 0.5rem;

  svg {
    width: 2rem;
    height: 2rem;
  }

  .fallback {
    fill: var(--font-secondary-color);
  }

  .placeholder {
    @include skeleton;

    width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;
  }
}

.category {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  border-radius: 1rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.content {
  padding: 3rem 4rem 0;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: calc(100% - 6rem);
}

.search {
  margin-inline: 4rem;
  width: auto !important;

  &Input {
    padding-left: 4.5rem !important;
  }

  &Icon {
    width: 2rem;
    height: 2rem;
    fill: var(--font-secondary-color);
  }
}

.button {
  margin: 1rem 3rem;
  column-gap: 0.5rem;
}

@include desktop {
  .category {
    &:hover {
      background: var(--base-5);
    }
  }

  .all {
    display: none;
  }
}

@include mobile-tablet {
  .root {
    display: none;
  }

  .all {
    display: block;
    text-align: center;
  }
}

@include tablet {
  .all {
    margin: 1rem;
  }
}
