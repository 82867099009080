@import 'styles/variables';

.metricList {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 2.5rem;
  padding: 4.75rem 7.5rem 20rem;
}

.metricName {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;

  &Dot {
    @include skeleton;

    width: 1.5rem;
    height: 1.5rem;
    aspect-ratio: 1;
  }

  &Text {
    @include skeleton;

    height: 1.5rem;
    width: 100%;
  }
}

.chart {
  width: 100%;
  height: auto;
  aspect-ratio: 2.3;
}

.axis {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
  padding: 2.5rem 4rem 4rem 10.5rem;

  &Tick {
    @include skeleton;

    height: 1.5rem;
    width: 100%;
  }
}

.brush {
  margin-top: 1rem;
  padding: 2rem 4rem;

  &Svg {
    @include skeleton;

    width: 100%;
    height: 48px;
  }
}
