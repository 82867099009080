@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  grid-column-gap: 1rem;
  align-items: center;
  border-radius: 15rem;
  padding: 0.5rem;
}

.avatar {
  @include skeleton;

  width: 4rem;
  height: 4rem;
  border-radius: 100%;
}

.title {
  @include skeleton;

  width: 30rem;
  height: 2rem;
  display: block;
}

.tag {
  @include skeleton;

  width: 10rem;
  height: 1.5rem;
}
