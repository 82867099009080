.dnd {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 3rem;
  border: 1px solid var(--base-10);
  border-radius: 4rem;
  margin: 0 3rem 4rem;
}
