.root {
  padding: 3rem;
  height: 56rem;
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder {
  width: 100%;
  height: auto;
  aspect-ratio: 2.33;
  margin-top: 14rem;
}
