@import 'styles/variables';

.container {
  @include max-content-width;

  padding-inline: 3.5rem;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6rem;
}

.period {
  text-wrap: nowrap;
  white-space: nowrap;
}

.root {
  display: flex;
  column-gap: 3rem;
}

.top {
  flex-basis: 33.33%;
  flex-grow: 1;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
}

.button {
  display: flex;
  margin: 1rem 3rem;
}

.trigger {
  margin-left: 1rem;
  width: 4rem;
  height: 4rem;
  transition: all 0.3s ease-in-out;

  svg {
    transition: all 0.3s ease-in-out;
  }

  &[data-is-open='true'] {
    background-color: var(--base);

    svg {
      fill: var(--surface);
      transform: rotateX(180deg);
    }
  }
}

.empty {
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@include mobile-tablet {
  .root {
    flex-direction: column;
  }

  .top {
    &:not(:last-of-type) {
      margin-bottom: 3rem;
    }
  }
}

@include mobile {
  .container {
    padding-inline: 1.5rem;
  }

  .top {
    &:not(:last-of-type) {
      margin-bottom: 2.5rem;
    }
  }

  .title {
    margin-bottom: 4rem;
  }
}
