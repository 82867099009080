@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  margin-bottom: 12rem;
  gap: 5rem;

  @include mobile {
    margin-bottom: 7rem;
    gap: 3rem;
  }
}

.categories {
  display: flex;
  flex-direction: column;
  gap: 10rem;

  @include mobile {
    gap: 6rem;
  }
}

.title {
  @include max-content-width;

  padding: 3.5rem 3.5rem 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-height: max-content;
  width: 100%;

  @include mobile {
    padding: 3.5rem 1.5rem 1rem;
  }
}
