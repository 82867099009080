@import 'styles/variables';

.root {
  flex: 1;
  display: grid;
  grid-template-columns: minmax(auto, 64rem);
  grid-template-rows: 1fr auto;
  grid-gap: 1rem;
  grid-template-areas:
    'content'
    'metrics';
  position: relative;

  &.restricted {
    height: 60rem;
    z-index: 1;
    width: 70rem;
    border-radius: 5rem;
    overflow: hidden;
    isolation: isolate;
  }

  &.hasGallery {
    grid-template-columns: repeat(2, minmax(auto, 64rem));
    grid-template-areas:
      'gallery content'
      'gallery metrics';

    &.noContent {
      grid-template-columns: minmax(auto, 64rem);
      grid-template-areas:
        'gallery'
        'metrics';
    }
  }
}

.gallery {
  grid-area: gallery;
  background: var(--primary-background);
}

.content {
  position: relative;
  grid-area: content;
  padding: 6rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--primary-background);

  &.withAdditionalContent {
    padding-top: 0;

    .contentWrapper {
      margin-top: 6rem;
      flex: 1;
    }
  }
}

.contentWrapper {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  justify-items: center;
  overflow: auto;
  padding: 0 3rem 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.date {
  margin-bottom: 2rem;
}

.metrics {
  grid-area: metrics;
  padding: 1rem;

  :global(.MuiButton-root) {
    padding: 2.75rem 3rem !important;
  }

  &List {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    justify-content: center;
  }

  &Icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  &Button {
    column-gap: 1rem;
  }
}

.documents {
  align-items: center;
}

.audio {
  width: 100%;

  &Wave {
    display: flex;
    width: 100%;
    height: 6rem;
  }

  &Button {
    position: absolute;
    bottom: 6rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.sticker {
  width: 30rem;
  height: 30rem;
  margin-inline: auto;
}

.repost {
  padding-inline: 4rem;

  :global(.MuiButton-root) {
    justify-self: end;
  }
}

.outsize {
  margin-inline: -3rem;
  width: calc(100% + 6rem);
  max-width: unset;
}

@include mobile-tablet {
  .root {
    grid-template-columns: 1fr;

    &.hasGallery {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr auto;
      grid-template-areas: 'gallery' 'content' 'metrics';
    }
  }

  .gallery {
    aspect-ratio: 1;
  }
}
