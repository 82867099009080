@import 'styles/variables';

.root {
  background: var(--base-5);
  border-radius: 4rem;
  display: grid;
  grid-template-columns: 29rem 1fr repeat(2, 19rem) 25rem;
  grid-template-areas: 'type channel views members concurrent';
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.type {
  grid-area: type;
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  align-items: center;

  .icon {
    @include skeleton;

    margin: 4rem;
    width: 4rem;
    height: 4rem;
  }

  .date {
    @include skeleton;

    width: 12rem;
    height: 4rem;
    margin-left: 2rem;
  }

  .divider {
    height: 12rem;
    width: 0;
    border-left: 1px solid var(--surface);
    border-right: 1px solid var(--base-10);
  }
}

.channel {
  grid-area: channel;
  display: grid;
  grid-template-columns: 8rem 1fr;
  grid-template-rows: repeat(2, 3rem);
  grid-template-areas:
    'avatar title'
    'avatar category';
  align-items: center;

  .avatar {
    @include skeleton;

    grid-area: avatar;
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    margin-right: 2rem;
  }

  .title {
    @include skeleton;

    grid-area: title;
    height: 2rem;
    width: 80%;
  }

  .category {
    @include skeleton;

    grid-area: category;
    height: 2rem;
    width: 15rem;
  }
}

.views {
  grid-area: views;
}

.members {
  grid-area: members;
}

.concurrent {
  grid-area: concurrent;
}

.views,
.members,
.concurrent {
  @include skeleton;

  width: 17rem;
  height: 8rem;
}

.dividerHorizontal {
  grid-area: horizontal;
  display: none;
}

@include tablet {
  .root {
    grid-template-rows: 12rem auto 12rem;
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-template-areas:
      'type channel channel'
      'horizontal horizontal horizontal'
      'views members concurrent';
  }

  .channel {
    .title {
      width: 50%;
    }
  }

  .dividerHorizontal {
    display: block;
  }

  .views {
    margin-left: 4rem;
  }
}

@include mobile {
  .root {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'type type'
      'horizontal horizontal'
      'channel channel'
      'views members'
      'concurrent concurrent';
  }

  .type {
    .icon {
      width: 3rem;
      height: 3rem;
      margin: 2.75rem;
    }

    .divider {
      height: 100%;
    }

    .date {
      margin-left: 3rem;
    }
  }

  .dividerHorizontal {
    display: block;
  }

  .channel {
    margin: 3rem 3rem 0;
  }

  .views {
    margin: 3rem 0 0 3rem;
  }

  .members {
    margin: 3rem 3rem 0 0;
  }

  .concurrent {
    margin: 3rem;
  }
}
