@import 'styles/variables';

.root {
  @include max-content-width;
  @include content-indents;

  grid-area: filters-panel;
  display: flex;
  column-gap: 0.5rem;
  margin-block: 3rem;
  padding-inline: 0;
}

.search {
  grid-area: search;
}

.filters {
  grid-area: filters;
}

.channels {
  grid-area: channels;
  justify-self: end;
}

@include mobile-tablet {
  .root {
    padding-inline: 0;
    margin-block: 3rem 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 1rem;
    grid-template-areas:
      'search search'
      'filters channels';
  }
}
