@import 'styles/variables';

.title {
  @include max-content-width;

  margin-inline: auto;
  padding-inline: 3.5rem;
  margin-block: 13rem 4rem;
}

@include mobile {
  .title {
    margin-block: 8rem 4rem;
    padding-inline: 1.5rem;
    letter-spacing: normal;
  }
}
