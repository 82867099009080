@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
}

.paper {
  padding: 3rem;
}

.metric {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);
  grid-template-areas: 'title title' 'value change' 'value percentageChange';
  grid-column-gap: 2rem;

  .title {
    grid-area: title;
    margin-bottom: 1rem;
  }

  .value {
    @include skeleton;

    grid-area: value;
    align-self: center;
    height: 5rem;
    width: 15rem;
  }

  .change {
    @include skeleton;

    grid-area: change;
    align-self: center;
    height: 2rem;
    width: 7rem;
  }

  .percentageChange {
    @include skeleton;

    grid-area: percentageChange;
    align-self: center;
    height: 2rem;
    width: 5rem;
  }
}
