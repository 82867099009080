@import 'styles/variables';

.root {
  @include tooltip-styles;

  border-radius: 3rem;
  border-bottom-left-radius: 0;
}

.divider {
  height: 1px;
  border-top: 1px solid var(--growth-tooltip-border);
  margin-inline: -3rem;
  margin-top: 2rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: 1rem;
  align-items: center;
  margin-block: 3rem;
}

.info {
  display: flex;
  align-items: center;
  line-height: 1;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  fill: var(--font-secondary-color);
}

@include mobile-tablet {
  .info {
    display: none;
  }
}

@include mobile {
  .root {
    width: 100%;
    border-bottom-left-radius: 3rem;
  }
}
