@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
}

.pagination {
  margin: 4rem;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 30rem;
}
