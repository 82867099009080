@import 'styles/variables';

.sticky {
  height: max-content;
}

@include mobile-tablet {
  .sticky {
    display: none;
  }
}
