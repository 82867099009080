@import 'styles/variables';

@mixin clickable {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 15rem;
  padding: 0.5rem;

  @include desktop {
    &:hover {
      background-color: var(--base-5);
    }
  }
}

.root {
  padding-inline: 1.5rem;
}

.title {
  margin-block: 4rem 2rem;
  padding-inline: 0.5rem;
}

.keyword {
  @include clickable;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 1rem;
  margin-top: 1rem;

  .rounded {
    border-radius: 100%;
    background-color: var(--base-5);
    padding: 1rem;
    aspect-ratio: 1;
    width: 4rem;
    height: 4rem;
  }

  svg {
    fill: var(--base-50);
    width: 2rem;
    height: 2rem;
  }
}

.channel {
  @include clickable;

  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  grid-column-gap: 1rem;
  align-items: center;
}

.list {
  max-height: 25rem;

  .item {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

.addChannel {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 2rem;
  align-items: center;
  padding: 2rem 3rem;
  border-radius: 3rem;
  background-color: var(--base-5);
  margin: 4rem -1.5rem;
}
