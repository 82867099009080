@import 'styles/variables';

.root {
  overflow: auto;
}

.backdrop {
  background: var(--modal-backdrop);
}

.close {
  position: sticky;
  top: 1rem;
}

.layout {
  position: relative;
  display: grid;
  padding: 20rem 10.75rem 5rem 16rem;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(2, auto);
  grid-gap: 1rem;
  grid-template-areas: 'channel actions' 'content actions';
  outline: none;
}

.channel {
  grid-area: channel;
}

.content {
  grid-area: content;
  padding: 1rem;
}

.actions {
  grid-area: actions;
}

@include mobile {
  .layout {
    padding: 1.5rem 0 2rem;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas: 'actions' 'channel' 'content';
  }

  .actions {
    justify-self: end;
    padding-right: 2rem;
  }
}
