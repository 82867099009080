@import 'styles/variables';

.chart {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  filter: grayscale(1) opacity(0.15);
  transition: filter 0.5s cubic-bezier(0.22, 1, 0.36, 1);
  display: flex;
  flex: 1;
  pointer-events: none;

  &.revealed {
    pointer-events: initial;
  }

  &Header {
    visibility: hidden;
  }
}

.metrics {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.animated {
    animation: fade-slide-in 0.5s forwards cubic-bezier(0.22, 1, 0.36, 1);
  }
}

.headline {
  padding: 3.5rem 4rem 0 !important;
}

.content {
  display: flex;
  flex: 1;
  max-height: 100%;
  position: relative;
}

.grid {
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(3, 1fr);
  grid-gap: 1px;
  background-color: var(--stats-inner-border);

  > * {
    background-color: var(--stats-foreground);
  }
}

.stats {
  &Overview {
    grid-area: stats;

    &.revealed {
      .metrics {
        animation: fade-slide-out 0.5s forwards cubic-bezier(0.22, 1, 0.36, 1);
      }

      :global(.StaticLabelPlaceholder) {
        filter: inherit;
      }

      .chart {
        filter: inherit;

        &Header {
          visibility: visible;
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.genderAndPosts {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 3rem;
  margin-top: 3rem;
}

.bot {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  background:
    linear-gradient(90deg, #4b70f6 0.09%, #ad74df 99.92%),
    url('/assets/male.png') no-repeat left bottom / 50%,
    url('/assets/female.png') no-repeat right top / 50%;
  background-blend-mode: color, normal, normal;
  padding: 3rem 3rem 3rem 4rem;

  .content {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;

    div:nth-child(2) {
      align-self: flex-end;
    }
  }

  .button {
    background-color: var(--button-add-bot-background, #fff);
  }
}

.genderData {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3.5rem 3.5rem 1rem;

    svg {
      width: 2rem;
      height: 2rem;
    }

    span {
      display: flex;
      align-items: center;
    }

    span:nth-child(1) {
      color: var(--blue);

      svg {
        fill: var(--blue);
        margin-right: 1rem;
      }
    }

    span:nth-child(2) {
      color: var(--purple);

      svg {
        fill: var(--purple);
        margin-left: 1rem;
      }
    }
  }

  .values {
    padding: 0 4rem 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.posts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &Chart {
    width: 21.5rem;
    height: 7rem;
  }

  .absolute {
    padding: 3.5rem 0 3.5rem 4rem;

    .title {
      display: flex;
      line-height: 3rem;
      margin-bottom: 1rem;
    }
  }

  .change {
    padding: 4rem 4rem 4rem 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    &Info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      row-gap: 0.5rem;
      margin-right: 4rem;
    }

    &Value {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 1rem;
    }
  }
}

.showMore {
  display: none;
  border-top: 1px solid var(--stats-inner-border);
}

@keyframes fade-slide-in {
  from {
    opacity: 0;
    transform: translate3d(0, 6rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-slide-out {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 6rem, 0);
  }
}

@include tablet {
  .grid {
    grid-template: repeat(3, 1fr) / repeat(2, 1fr);
  }
}

@include mobile {
  .gender,
  .posts {
    border-radius: 4rem;
  }

  .grid {
    grid-template-rows: repeat(6, auto);
    grid-template-columns: unset;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .bot {
    padding: 2.5rem;

    //height: 11rem;
  }

  .headline {
    padding: 2.5rem 3rem 1rem;
  }

  .showMore {
    display: flex;
    padding: 1rem 3rem;
    flex-direction: column;
  }

  .genderAndPosts {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 1rem;
  }

  .posts {
    .absolute {
      padding: 2rem 0 2rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .change {
      padding: 2rem 3rem 2rem 0;
      align-items: center;

      &Info {
        order: 2;
        margin-right: 0;
      }
    }
  }

  .genderData {
    padding: 2.5rem;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    height: 100%;

    .values {
      align-items: center;
      padding: 0 0.5rem;
    }

    .header {
      padding: 0;

      span {
        column-gap: 0.5rem;

        svg {
          margin: 0.5rem !important;
        }
      }
    }
  }
}
