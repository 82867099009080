@import 'styles/variables';

.root {
  display: grid;
  grid-template-columns: min-content auto;
  grid-column-gap: 1rem;
  height: 100%;
  width: 50%;
  padding: 2rem 0 2rem 2rem;
}

.backdrop {
  background: var(--backdrop-background);
}

.paper {
  min-width: 35rem;
  display: flex;
  flex-direction: column;
}

.actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem 1rem 2rem;
  grid-column-gap: 1rem;
}

.navigation {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;

  ul,
  li {
    list-style: none;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  row-gap: 1rem;

  .link {
    text-decoration: underline;
  }
}

.submenu {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: calc(100% - 3rem);
    top: 1.5rem;
    left: 3rem;
    border-left: 1px solid var(--menu-dropdown-border);
  }

  .submenuItem {
    margin-top: 0.5rem;

    &:last-of-type {
      margin-bottom: 0.5rem;
    }

    .link {
      padding: 1rem 6rem;
      width: 100%;
      height: 100%;
      display: flex;
    }
  }
}

.selector {
  display: none;

  & + .label + .submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }

  &:checked + .label + .submenu {
    max-height: 100%;
    margin-bottom: 2rem;
  }
}

.item {
  border-top: 1px solid var(--menu-dropdown-border);
  padding: 1rem 0;

  &:first-of-type {
    border-top: none;
  }

  &:last-of-type {
    border-bottom: 1px solid var(--menu-dropdown-border);
  }

  .label {
    display: flex;
    padding: 1rem 3rem;
    cursor: pointer;
    margin: 0.5rem 0;

    .icon {
      margin-right: 1.5rem;
    }
  }
}

.logo {
  display: none;
}

.iconed {
  display: flex;
  align-items: center;
}

.icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  fill: var(--font-secondary-color);
}

@include mobile-tablet {
  .navigation {
    overflow-y: auto;
  }
}

@include mobile {
  .root {
    width: 100%;
    grid-template-columns: unset;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'close'
      'menu';
    padding: 2rem 1rem 1rem;
  }

  .logo {
    display: block;
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    height: 4rem;
    width: 10.4rem;
  }

  .close {
    padding: 2rem !important;
    grid-area: close;
    justify-self: flex-start;
    margin: 0 0 2rem 1rem;
    background: var(--button-secondary-background) !important;

    svg {
      fill: var(--button-secondary-foreground) !important;
    }
  }

  .paper {
    grid-area: menu;
  }

  .backdrop {
    background: var(--primary-background);
  }
}
