@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.bigCard {
  width: 100%;
  height: 100%;
  min-height: 37.5rem;
  background-color: var(--surface);
  display: flex;
  padding: 1rem;
  flex-shrink: 0;
  border-radius: 5rem;
  position: relative;

  @include mobile-tablet {
    flex-direction: column-reverse;
    gap: 1rem;
  }
}

.background {
  flex: 1;
  position: relative;
  border-radius: 5rem;

  @include mobile-tablet {
    min-height: 20rem;
  }
}

.image {
  position: absolute;
  border-radius: 5rem;
  top: 0;
  left: 0;
  object-fit: cover;
  min-height: 20rem;
}

.cardContent {
  flex: 1;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include mobile-tablet {
    flex-direction: row-reverse;
  }
}

.icon {
  width: 4rem;
  height: 4rem;
  fill: var(--base-40);
}

.textBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  max-width: 60%;

  @include mobile-tablet {
    max-width: 100%;
  }
}

.buttons {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include mobile-tablet {
    display: none;
  }
}
