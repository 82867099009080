@import 'styles/variables';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  height: 59rem;
  overflow: auto;
}

.container {
  position: relative;
  flex-basis: 25%;
  height: max-content;
}

.link {
  padding: 3rem;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'avatar title' 'avatar category';
  background: var(--base-5);
  border-radius: 4rem;
}

.avatar {
  grid-area: avatar;
  margin-right: 2rem;
}

.title {
  @include text-ellipsis;

  grid-area: title;
}

.category {
  grid-area: category;
}

.more {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.dropdown {
  border: 1px solid var(--menu-dropdown-border);
  background: var(--menu-dropdown-background);
  border-radius: 2rem;
  box-shadow:
    0 0 0 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 84px 24px 0 rgb(0 0 0 / 0%);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  text-align: start;
  padding: 1rem;

  .item {
    justify-content: flex-start;
    border-radius: 1rem;

    &.selected {
      :global(.MuiTypography-root) {
        color: var(--menu-active-item-color);
      }

      color: var(--menu-active-item-color);
    }
  }
}

@include tablet {
  .container {
    flex-basis: calc(50% - 0.5rem);
  }
}

@include mobile {
  .root {
    flex-direction: column;
    flex-wrap: unset;
  }

  .container {
    flex-basis: unset;
  }
}
