@import 'styles/variables';

.root {
  width: 36rem;
  background: var(--base-5);
  padding: 3rem 3rem 8rem;
  border-radius: 4rem;
}

.rating {
  @include skeleton;

  width: 3rem;
  height: 2rem;
}

.avatar {
  @include skeleton;

  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  margin: 3rem auto 2rem;
}

.title {
  @include skeleton;

  width: 80%;
  height: 2.5rem;
  margin: 0 auto;
}

.additional {
  display: flex;
  column-gap: 0.25rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.members {
  @include skeleton;

  width: 30%;
  height: 2.5rem;
}

.category {
  @include skeleton;

  width: 25%;
  height: 2.5rem;
}
