@import 'styles/variables';

@mixin rect-hover {
  transform: translate(calc(var(--size) * 0.14), calc(var(--size) * 0.14));
  width: calc(var(--size) * 0.72);
  height: calc(var(--size) * 0.72);
}

.grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'tabs controls'
    'chart chart';
}

.scrollable {
  grid-area: chart;
}

.root {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: max-content auto;
  grid-template-areas:
    'chart axisY'
    'axisX .';
}

.rect {
  cursor: pointer;
  transition:
    width 0.3s $animation-function,
    height 0.3s $animation-function,
    transform 0.3s $animation-function;

  &:hover {
    @include rect-hover;
  }
}

.rectAddition {
  cursor: pointer;

  &:hover {
    + .rect {
      @include rect-hover;
    }
  }
}

.chart {
  grid-area: chart;
}

.axisX {
  grid-area: axisX;
  padding-top: 3rem;
  position: relative;
}

.axisY {
  padding-left: 2rem;
  grid-area: axisY;
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  align-items: center;
  height: calc(100% - 0.275rem);
}

.month {
  position: absolute;

  --cell-size: calc(100% / var(--weeks-count));

  display: inline-flex;
  left: calc(var(--length) * var(--cell-size) + 0.275rem);
}

.tabs {
  grid-area: tabs;
  justify-content: flex-start !important;
  margin-bottom: 4rem;
}

.controls {
  grid-area: controls;
  display: none;
  margin-bottom: 3rem;

  button:first-of-type {
    margin-right: 0.5rem;
  }
}

@include mobile-tablet {
  .root {
    grid-template-columns: max-content auto;
    grid-template-areas:
      'axisY chart'
      '. axisX';
  }

  .scrollable {
    overflow-y: auto;
    padding-bottom: 2rem;
  }

  .axisY {
    text-align: right;
    padding-inline: 0 2rem;
  }

  .controls {
    display: block;
  }
}

@include mobile {
  .grid {
    grid-template-columns: unset;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'controls'
      'tabs'
      'chart';
  }
}
