.starsContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0;
}

.rating {
  line-height: 0.9 !important;
}

.stars {
  display: flex;
  align-items: center;
  gap: 3px;
}

.star {
  width: 16px;
  height: 16px;
}

.filledStar {
  color: #fff34d;
}

.emptyStar {
  color: rgb(16 41 96 / 20%);
}
