@import 'styles/variables';
@import 'styles/fonts';

// REVIEWS ITEM

.text {
  flex-shrink: 1;
  margin-bottom: 4rem;
}

.titleBox {
  max-width: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2rem;

  @include mobile-tablet {
    margin-bottom: 1rem;
  }
}

.badge {
  @include suisse-font;

  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 450;
  padding: 0.25rem 1rem;
  color: var(--base-50);
  border: 1px solid var(--base-50);
  border-radius: 3rem;
}

.reviews {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 5rem;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2rem;
  gap: 1rem;
}

.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--base-5);
  padding: 0.5rem;
  border-radius: 100%;
}

.alertIcon {
  width: 2.5rem;
  height: 2.5rem;
  fill: var(--base-10);
}

.link {
  color: var(--base-50) !important;
  text-decoration: underline;
}

// REVIEW ITEM
.card {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5rem;
  background-color: var(--surface);
}

.top {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding: 3rem;
  border-bottom: 1px solid var(--base-10);
}

.avatar {
  flex-shrink: 1;
  position: relative;
  display: grid;
  place-content: center;
  min-width: 7rem;
  min-height: 7rem;
  max-width: 7rem;
  max-height: 7rem;
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1;
  background-color: var(--base-5);
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  object-position: center;
  max-width: 7rem;
  max-height: 7rem;
}

.icon {
  width: 3rem;
  height: 3rem;
  fill: var(--base-50);
}

.heading {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.user {
  display: flex;
  flex-direction: column;
}

.bottom {
  flex: 1;
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

// PAGINATION

.paginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  padding: 0 16px;

  @include mobile {
    flex-direction: column;
    gap: 3rem;
  }
}

.pagination {
  @include suisse-font;

  display: flex;
  align-items: center;
  gap: 1rem;
}

.paginationButton,
.paginationArrow {
  padding: 1rem;
  aspect-ratio: 1;
  min-width: 5rem;
  max-width: 5rem;
  min-height: 5rem;
  max-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--base-50);
  font-size: 1.75rem;
  border-radius: 100%;

  &:hover:not(:disabled) {
    background: var(--base-5);
  }

  &:disabled {
    cursor: default;
  }
}

.paginationArrow {
  color: var(--base-50);

  &:disabled {
    opacity: 0.5;
  }
}

.activePage {
  background: var(--base);
  color: var(--surface);
  border-radius: 100%;

  &:hover {
    background: var(--base-50) !important;
  }
}

.dots {
  cursor: default;

  &:hover {
    background: var(--base-5) !important;
  }
}

.paginationInfo {
  @include suisse-font;

  color: var(--base-50);
}

.arrows {
  width: 2.5rem;
  height: 2.5rem;
}
