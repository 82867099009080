@import 'styles/variables';

.item {
  background: var(--surface);
  border-radius: 5rem;
  display: grid;
  grid-template-columns: auto 1fr repeat(4, auto);
  align-items: center;
  padding-inline: 4rem 1.75rem;
  margin-bottom: 1rem;
}

.date {
  @include skeleton;

  height: 2rem;
  width: 12rem;
}

.absolute {
  @include skeleton;

  height: 2rem;
  width: 11rem;
  margin-right: 1rem;
}

.change {
  @include skeleton;

  height: 2rem;
  width: 8rem;
}

.divider {
  width: 1px;
  height: 6rem;
  border-left: 1px solid var(--base-10);
  margin-inline: 2rem 1.75rem;
}

.button {
  @include skeleton;

  border-radius: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}
