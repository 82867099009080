@import 'styles/variables';

.root {
  width: 100%;
  overflow: hidden;

  &:not(.initialized) {
    @include desktop {
      .scrollabel {
        flex-direction: column;
        overflow-y: auto;
      }
    }
  }
}

.posts {
  height: 87rem;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;

  &:not(.full-scrolled) {
    @include horizontal-gradient;
  }
}

.scrollabel {
  margin: 0 -1rem;
  padding: 0 1rem;
}

.item {
  margin-bottom: 1rem;
}

.sliderControl {
  margin-left: 0.5rem;
}

@include mobile-tablet {
  .posts {
    height: auto;

    &:not(.full-scrolled) {
      @include vertical-gradient;
    }
  }

  .item {
    display: flex;
    flex-shrink: 0;
    margin: 0 1rem 0 0;
  }

  .scrollabel {
    margin: -1rem 0;
    padding: 1rem 0;
  }
}

@include tablet {
  .posts {
    padding: 0 0 0 1rem;
  }

  .scrollabel {
    padding: 1rem 0;
    margin: 0;
  }

  .item {
    width: 50rem;
    flex-basis: 50rem;
  }
}

@include mobile {
  .item {
    width: 36rem;
    flex-basis: 36rem;
  }
}
