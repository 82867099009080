@import 'styles/fonts';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6rem;
  border: 1px solid var(--user-avatar-border);
  width: 6rem;
  height: 6rem;
  object-fit: cover;
}

.avatar {
  width: 6rem;
  height: 6rem;
  overflow: hidden;
  border-radius: 50%;

  &.placeholder {
    @include suisse-font;

    background: linear-gradient(
      135deg,
      color-mix(in srgb, var(--avatar-color), #fff 20%) 0%,
      color-mix(in srgb, var(--avatar-color), #000 10%) 100%
    );
    color: white;
    display: grid;
    justify-content: center;
    align-items: center;
  }
}
