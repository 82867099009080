@import 'styles/variables';

.logoBox {
  height: 100%;
  width: 100%;
  aspect-ratio: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--surface);
  border-radius: 100%;

  @include mobile-tablet {
    height: 100%;
    width: 100%;
    background-color: var(--base-5);
  }
}

.verifiedIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  z-index: 20;
  background-color: var(--base-5);
  border-radius: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f3f4f9;
    border-radius: 100%;
    z-index: 10;
  }

  @include tablet {
    top: 0.25rem;
    left: 0.25rem;
  }

  @include mobile {
    top: 5%;
    left: 5%;

    /* transform: translate(-50%, -50%); */
  }
}

.verifiedIcon {
  z-index: 30;
  width: 2.75rem;
  height: 2.75rem;
  fill: var(--brand);

  @include mobile-tablet {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.logo {
  position: absolute;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
}
