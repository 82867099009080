@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
}

@include mobile-tablet {
  .root {
    width: auto;
    overflow-x: auto;
  }
}

@include tablet {
  .root {
    margin-top: 3rem;
  }
}
