@import 'styles/variables';

.root {
  padding-left: 1rem;
  position: relative;

  &:not(.full-scrolled) {
    @include vertical-gradient;
  }

  :global(.slick-slide) {
    display: flex;
    width: 100%;
    height: auto;

    > div {
      margin: 0 0.5rem;
      width: 100%;
      display: flex;
    }
  }

  :global(.slick-list) {
    margin: 0 -0.5rem;
    overflow: visible;
  }

  :global(.slick-track) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;
  }
}

.post {
  display: flex;
}

.sliderControl {
  margin-left: 0.5rem;
}

.scroller {
  padding: 1rem 0;
}

.item {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(100% / 3 - 1rem);
  margin-right: 1rem;
}

@include tablet {
  .item {
    flex-basis: 43rem;
  }
}

@include mobile {
  .item {
    flex-basis: 36rem;
  }
}
