@import 'styles/variables';

.mobile {
  display: none;

  .avatar {
    margin-bottom: 3rem;
  }

  .name {
    margin-bottom: 2rem;
  }

  .categories {
    margin-bottom: 4.25rem;
  }
}

.grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'avatar name actions'
    'avatar categories actions';
}

.paper {
  padding: 4rem;
}

.avatar {
  grid-area: avatar;
  margin-right: 2rem;
}

.name {
  grid-area: name;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions {
  grid-area: actions;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.categories {
  grid-area: categories;
  display: flex;
  flex-direction: row;
  column-gap: 0.275rem;
  align-self: center;
}

@include mobile {
  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'actions';
    justify-items: center;

    .avatar,
    .name,
    .categories {
      display: none;
    }
  }

  .paper {
    padding: 1rem;
    width: max-content;
    margin-inline: auto;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
