@import 'styles/variables';

/* stylelint-disable */
.outer {
  overflow: hidden;
  width: 0;
  transition: width 0.3s $animation-function;

  &.open {
    width: 44rem;
  }
}

.root {
  width: 44rem;
  padding-right: 3rem;
  grid-area: filters;
}

.paper {
  padding: 3rem 4rem 4rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &.reset {
    padding: 1rem;
  }
}

.headline {
  margin-bottom: 1rem;
  padding: 0 !important;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
}

.button {
  position: relative;
  width: 100%;

  &:disabled {
    background: var(--surface);
    border: 1px solid var(--base-5);
    color: var(--base-20);

    svg {
      fill: var(--base-20);
    }

    .counter {
      display: none;
    }
  }
}

.counter {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  aspect-ratio: 1;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--base-5);
}

.modal {
  overflow: auto;
  display: none;
}

.backdrop {
  background: var(--backdrop-background);
}

.grid {
  padding: 2rem 0 2rem 2rem;
  display: grid;
  grid-template-columns: 1fr 8rem;
  grid-gap: 1rem;
  grid-template-areas: 'filters close';
  width: 50%;
}

.close {
  grid-area: close;
}

.containered {
}

@include mobile-tablet {
  .root {
    width: 100%;
    padding-right: 0;
  }

  .desktop .paper:not(.containered) {
    display: none;
  }

  //.containered {
  //  position: absolute;
  //  top: 2rem;
  //  width: calc(100% - 4rem);
  //}

  .modal {
    display: initial;
  }
}

@include tablet {
  //.containered {
  //  top: 3rem;
  //  width: calc(100% - 7rem);
  //}
}

@include mobile {
  //.containered {
  //  top: 2rem;
  //  width: calc(100% - 4rem);
  //}

  .paper {
    padding: 4rem 3.5rem 0;
    border-radius: 0 !important;

    &:first-of-type {
      border-top-left-radius: 5rem !important;
      border-top-right-radius: 5rem !important;
    }

    &:not(:last-of-type) {
      margin-bottom: 0;
    }

    &:last-of-type {
      border-bottom-left-radius: 5rem !important;
      border-bottom-right-radius: 5rem !important;
    }

    &.reset {
      padding: 2rem 1.5rem 1.5rem;
    }
  }

  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: 6rem auto;
    grid-template-areas: 'close' 'filters';
    width: 100%;
    padding-block: 2rem;
    padding-inline: 0;
  }

  .close {
    height: 100%;
    justify-self: end;
    margin-right: 2rem;
  }
}
