.root {
  min-width: 30rem;

  &:not(:first-of-type) {
    margin-top: 1.5rem;
  }
}

.alert {
  border-radius: 3rem;
  background: var(--menu-dropdown-background);
  box-shadow:
    0 84px 24px 0 rgb(0 0 0 / 0%),
    0 54px 22px 0 rgb(0 0 0 / 0%),
    0 30px 18px 0 rgb(0 0 0 / 2%),
    0 13px 13px 0 rgb(0 0 0 / 3%),
    0 3px 7px 0 rgb(0 0 0 / 3%),
    0 0 0 0 rgb(0 0 0 / 3%);
  backdrop-filter: blur(20px);
  padding: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  .slot {
    &Icon {
      padding: 0;
      margin: 0 0 1.5rem;
    }

    &Message {
      padding: 0;
    }

    &Action {
      padding: 0;
    }
  }
}

.icon {
  width: 4rem;
  height: 4rem;
}

.close {
  &Icon {
    fill: var(--base-50);
    width: 2rem;
    height: 2rem;
  }

  &Button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
}
