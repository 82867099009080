.root {
  display: inline-flex;
}

.input {
  display: none;
}

.label {
  position: relative;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: inset 0 0 0 0.25rem var(--base-20);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: var(--base-5);
  }

  &:focus-visible,
  &:active {
    outline: 0;
    box-shadow: inset 0 0 0 0.5rem var(--brand);
  }
}

.icon {
  opacity: 0;
  width: 2rem;
  height: 2rem;
  fill: white;
  transition: opacity 0.1s ease-in-out;
}

.input:disabled + .label {
  cursor: not-allowed;
  box-shadow: inset 0 0 0 0.25rem var(--base-10);

  &:hover {
    background-color: white;
  }

  &:focus-visible,
  &:active {
    box-shadow: inset 0 0 0 0.25rem var(--base-10);
  }
}

.input:checked {
  + .label {
    box-shadow: inset 0 0 0 1.25rem var(--brand);
    background-color: white;

    .icon {
      opacity: 1;
    }

    &:hover {
      box-shadow: inset 0 0 0 1.25rem #0056e5;
    }

    &:focus-visible,
    &:active {
      box-shadow: inset 0 0 0 0.875rem var(--brand);
    }
  }

  &:disabled + .label {
    box-shadow: inset 0 0 0 1.25rem var(--base-5);
  }
}
