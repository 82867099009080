@import 'styles/variables';

.root {
  flex-grow: 1;
  border-radius: 4rem;
  background: var(--secondary-background);
}

.content {
  display: grid;
  grid-template-columns: 5fr 2fr;
  grid-template-areas:
    'header gallery'
    'message gallery';
  padding: 3rem 4rem 4rem;
}

.header {
  grid-area: header;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
}

.message {
  grid-area: message;
}

.gallery {
  display: grid;
  grid-area: gallery;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 3fr 1fr;
  grid-template-areas:
    'first first first'
    'second third more';
  grid-gap: 0.25rem;
  border-radius: 2rem;
  overflow: hidden;
}

.media {
  @include skeleton;

  border-radius: 0;

  &:nth-child(1) {
    grid-area: first;
  }

  &:nth-child(2) {
    grid-area: second;
  }

  &:nth-child(3) {
    grid-area: third;
  }

  &:nth-child(4) {
    grid-area: more;
  }
}

.date {
  @include skeleton;

  width: 9rem;
  height: 2rem;
}

.dateTimeDivider {
  @include skeleton;

  display: inline-flex;
  align-self: center;
  width: 0.375rem;
  height: 0.375rem;
  margin: 0 1rem;
  aspect-ratio: 1;
}

.time {
  @include skeleton;

  width: 4.5rem;
  height: 2rem;
  margin-right: 1rem;
}

.action {
  @include skeleton;

  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  aspect-ratio: 1;
}

.row {
  @include skeleton;

  height: 2rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &1 {
    width: 75%;
  }

  &2 {
    width: 92%;
  }

  &3 {
    width: 80%;
  }

  &4 {
    width: 89%;
  }

  &5 {
    width: 84%;
  }
}

.statistic {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 3rem;
  column-gap: 0.25rem;
}

.statisticItem {
  @include skeleton;

  width: 8.125rem;
  height: 3rem;
}

@include mobile-tablet {
  .content {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'header'
      'message'
      'gallery';
    padding: 2rem 2rem 3rem;
  }

  .gallery {
    aspect-ratio: 1.95;
    grid-template-areas:
      'first second'
      'first third'
      'first more';
    grid-template-columns: 3fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    margin-top: 3rem;
  }
}

@include mobile {
  .statistic,
  .divider {
    display: none;
  }
}
