.paper {
  display: flex;
  flex-direction: column;
  height: 68.5rem;
  margin-bottom: 1rem;
}

.filters {
  padding: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chart {
  width: 100%;
  height: 100%;
  position: relative;
}

.overview {
  position: absolute;
  left: 4rem;
}

.absolute {
  grid-area: absolute;
}

.change {
  grid-area: change;
}

.period {
  grid-area: period;
}

.brush {
  padding: 2rem 4rem;
}
