.chart {
  height: auto;
  min-height: 50rem;
}

.metricList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 4rem;
  column-gap: 2.5rem;
}

.metricName {
  display: flex;
  flex-direction: row;
  column-gap: 0.725rem;
  align-items: center;

  &Dot {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    aspect-ratio: 1;
  }
}

.brush {
  margin-top: 1rem;
  padding: 2rem 4rem;
}
