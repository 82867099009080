.root {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6rem;
  border: 1px solid var(--user-avatar-border);
  width: 6rem;
  height: 6rem;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
}
