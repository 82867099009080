.root {
  padding: 2rem 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .icon {
    fill: var(--font-secondary-color);
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
}

.date {
  width: max-content;
  background: var(--error);
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;

  &Divider {
    background: var(--white-20);
  }
}
