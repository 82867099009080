.root {
  display: flex;
  flex-direction: row;
  align-items: center;

  .action {
    padding: 0.75rem;

    &:first-of-type {
      margin-left: 1rem;
    }

    &Icon {
      width: 2rem;
      height: 2rem;
      fill: var(--font-secondary-color);
    }
  }
}
