@import 'styles/variables';

.backdrop {
  background: var(--modal-backdrop);
}

.layout {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 13rem 1fr 13rem;
  grid-template-areas:
    '. tabs .'
    '. content actions'
    '. freespace .';
  max-width: max-content;
  margin: 0 auto;
  outline: none;
}

.root {
  display: flex;

  &.scrollable {
    overflow: auto;

    .layout {
      grid-template-rows: 13rem max-content 13rem;
    }
  }
}

.navigation {
  grid-area: tabs;
  align-self: center;
  justify-self: center;
}

.actions {
  grid-area: actions;
  margin-left: 1rem;
}

.content {
  display: flex;
  position: relative;
  grid-area: content;
  overflow-y: auto;
}

@include mobile-tablet {
  .root {
    overflow: auto;
  }

  .content {
    display: grid;
    overflow: unset;
  }
}

@include tablet {
  .layout {
    max-width: 64rem;
    grid-template-rows: 13rem min-content 13rem;
  }
}

@include mobile {
  .layout {
    width: 100%;
    max-width: unset;
    grid-template-columns: 1fr auto;
    grid-template-rows: max-content min-content !important;
    grid-template-areas: 'tabs actions' 'content content';
  }

  .actions {
    justify-self: end;
    margin: 2rem 2rem 1rem 0;

    :global(.MuiButton-root) {
      padding: 2rem !important;

      svg {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
  }

  .navigation {
    justify-self: start;
    margin-left: 2rem;
  }
}
