@import 'styles/variables';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

@include mobile-tablet {
  .root {
    min-height: 20rem;
  }
}
